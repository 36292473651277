export default {
  "citizentokenservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier avec un code de sécurité via token"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparez votre carte papier (token) personnelle."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le code de sécurité <0>numéro var:tokenRequested</0> de votre carte."])}
      },
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code de sécurité"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur <strong>Continuer</strong>"])}
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour en savoir plus"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention ! Vous ne pouvez plus activer de nouveau token. Les citoyens disposant d'un token actif peuvent continuer à l'utiliser pour se connecter aux services en ligne."])}
    }
  },
  "commercialnoidservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier avec un certificat commercial"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur <strong>S'identifier</strong>."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsqu'on vous le demandera, sélectionnez le certificat commercial que vous souhaitez utiliser."])}
      },
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier"])}
    },
    "error": {
      "verification": {
        "step": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermez toutes les fenêtres ouvertes dans votre navigateur."])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrez votre navigateur et réessayez de vous identifier."])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez si vous avez sélectionné le bon certificat."])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre certificat commercial n'a pas pu être vérifié."])},
        "isabel": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Si vous souhaitez vous identifier avec un certificat Isabel, vous devez disposer de la version la plus récente du « middleware » d’Isabel. Pour ce faire, allez sur le <0>site web d'Isabel</0>, et choisissez « Composants de sécurité Isabel » et téléchargez la dernière version."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.isabel.eu/fr/forbusiness/support6/isabel6/installation/download.html"])}
        },
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez des questions sur l'identification au moyen d'un certificat commercial, vous trouverez rapidement une réponse dans les <0>pages d'aide</0>."])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["http://5244.stg.fedict.be/fr/faq/160"])}
      }
    }
  },
  "commercialservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier avec un certificat commercial"])}
  },
  "common": {
    "page": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier"])}
    },
    "login": {
      "username": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit de l’identifiant et du mot de passe de Mes clés numériques."])}
      },
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "choose_other_digital_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une autre clé numérique."])},
      "button": {
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
        "test_eid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tester l'eID"])},
        "test_result": {
          "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'eID!"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID OK!"])}
        }
      }
    },
    "modal": {
      "warning": {
        "firefox": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installer l’add-on eID pour Firefox"])},
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous voulez utiliser votre eID via Firefox ? Vous avez alors besoin de l’add-on ' eID Belgique ' officielle. Si vous ne l’avez pas encore installé, cliquez en dessous sur ' Installer l’add-on eID pour Firefox '."])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà installé l’add-on ? Cliquez en dessous sur ' S’identifier avec l’eID '."])},
          "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention !"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pour vous identifier avec l’eID, vous avez besoin du logiciel eID sur votre ordinateur. Vous ne l’avez pas encore installé ? Vous pouvez le faire par le biais du "])},
          "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["site web du logiciel eID"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.eid.base)/fr"])},
          "button": {
            "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S’identifier avec l’eID"])},
            "addon": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installer l’add-on eID pour Firefox"])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.firefox_addons.base)/fr/firefox/addon/belgium-eid/?src=search"])}
            }
          }
        }
      }
    },
    "sidebar": {
      "no_eid": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas d'eID ?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pour savoir comment utiliser les services publics en ligne sans eID."])},
        "link": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be/fr/identification-sans-eid"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici"])}
        }
      },
      "activate_other_key": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous souhaitez activer une autre clé ?"])},
        "link": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous <0>ici</0> pour créer ou modifier vos clés numériques."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=fr"])}
        }
      }
    },
    "help": {
      "need_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide ?"])},
      "unable_to_find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas trouvé ce que vous cherchez ?"])},
      "read-our": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Consultez notre"])},
      "quickly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et trouvez rapidement une réponse à votre question."])},
      "link": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq-list"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez notre <1>page d'aide</1> et trouvez rapidement une réponse à votre question."])},
        "help_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["page d'aide"])}
      }
    },
    "cookie": {
      "consent": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous utilisez nos applications, des cookies sont placés conformément à notre politique en matière de vie privée."])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte les cookies"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour en savoir plus"])},
        "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquez les informations"])},
        "more_info": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Pour l'application « Mes clés numériques», Transformation Digitale - SPF Stratégie et Appui utilise des cookies pour améliorer les performances du site web, des cookies fonctionnels pour la convivialité d'utilisation et des cookies de session temporaire nécessaires à l'authentification pendant la session. Vous pouvez refuser les cookies mais, dans ce cas, certaines parties de nos sites web ne fonctionneront pas ou pas de façon optimale.</p><br/><p><strong>Cookies nécessaires</strong></p><p>Ces cookies sont indispensables pour contrôler votre identité de manière sûre et, sur cette base, vous donner accès aux applications auxquelles vous souhaitez accéder.</p><br/><p><strong>Cookies fonctionnels</strong></p><p>Les cookies fonctionnels permettent d'améliorer le fonctionnement des sites web et la convivialité d'utilisation. Fedict utilise des cookies pour retenir votre langue de prédilection.</p><br/><p><strong>Cookies pour performances du site web</strong></p><p>SPF Stratégie et Appui - DG Transformation Digitale utilise des « load balancing cookies ». Ils sont utilisés sur des sites web qui sont beaucoup visités et servent à partager les charges des demandes entre plusieurs réseaux et serveurs distincts.</p><br/><p>Vous pouvez refuser des cookies par le biais des paramètres de votre navigateur. Le site web suivant vous explique comment supprimer des cookies sur les navigateurs les plus courants : <a href=\"http://www.aboutcookies.org/\">http://www.aboutcookies.org/</a>. Vous pouvez à tout moment supprimer les cookies de votre ordinateur.</p>"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["http://www.aboutcookies.org/"])}
        }
      }
    },
    "alt_text": {
      "images": {
        "emailOtpStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code de sécurité envoyé par e-mail"])},
        "mobileAppStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code de sécurité via une application mobile"])},
        "certificateStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certificat commercial"])},
        "certificateStepNoId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certificat commercial"])},
        "usernamePasswordStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identifiant et un mot de passe"])},
        "smsStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code de sécurité via SMS"])},
        "tokenStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code de sécurité via token"])}
      }
    },
    "greetings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincères salutations,<br/>le Service Desk Transformation Digitale"])},
    "faq": {
      "what_do_i_need_eid": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/123"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De quoi ai-je besoin ?"])}
      },
      "101": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/101"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que dois-je faire si je soupçonne que quelqu’un abuse de mes clés numériques ?"])}
      },
      "105": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/105"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai oublié mon code PIN"])}
      },
      "106": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/106"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n'ai pas de lecteur de carte"])}
      },
      "107": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/107"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n'ai pas de carte d'identité électronique (eID)"])}
      },
      "109": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/109"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois toujours le message que mon code n'est pas valide"])}
      },
      "117": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/117"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte a été bloqué. Que dois-je faire ?"])}
      },
      "118": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/118"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai oublié mon nom d'utilisateur et/ou mon mot de passe. Que dois-je faire ?"])}
      },
      "119": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/119"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je souhaite m'identifier depuis un smartphone ou une tablette"])}
      },
      "142": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/142"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De quoi ai-je besoin?"])}
      },
      "143": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/143#"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je souhaite remplacer mon smartphone ou ma tablette. Qu'en est-il de l'application mobile?"])}
      },
      "145": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/145"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle application mobile puis-je utiliser pour m'identifier avec \"un code de sécurité via une application mobile\"?"])}
      },
      "153": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/153"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De quoi ai-je besoin ?"])}
      },
      "156": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/156"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De quoi ai-je besoin ?"])}
      },
      "158": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/158"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si j'ai déjà un nom d'utilisateur et mot de passe pour l'identification via token, SMS ou application mobile, est-il nécessaire d'activer ces nom d'utilisateur et mot de passe comme clé numérique distincte?"])}
      },
      "170": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/170"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’administration a-t-elle accès à mes données si j'utilise mes clés numériques ?"])}
      },
      "172": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/172"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle est la sécurité des différentes clés numériques ?"])}
      },
      "174": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/174"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment puis-je me déconnecter en toute sécurité des services en ligne des pouvoirs publics ?"])}
      },
      "7258": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/fr/eidas#7258"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qu'est-ce qu'eIDAS ?"])}
      },
      "7262": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/fr/eidas#7262"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi dois-je m'identifier auprès d'eIDAS ?"])}
      },
      "7347": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/fr/eidas#7347"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je ne connais pas mon numéro d'identification belge."])}
      },
      "7269": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/fr/eidas#7269"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes données sont-elles conservées de manière sécurisée ?"])}
      },
      "7334": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/fr/eidas#7334"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De quoi ai-je besoin (la première fois) pour m'identifier via eIDAS ?"])}
      },
      "7338": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/fr/eidas#7338"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À l'aide de quels moyens d'identification européens puis-je m'identifier ?"])}
      },
      "7277": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/fr/eidas#7277"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un message d'erreur m'indique que mon identification ne peut être traitée automatiquement."])}
      },
      "list": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq-list"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les questions"])}
      },
      "what_do_i_need_sms": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/148#6396"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De quoi ai-je besoin ?"])}
      },
      "cost_sms": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/152#6395"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est-ce que cela me coûte quelque chose pour recevoir un code de sécurité par SMS ?"])}
      },
      "new_phone_sms": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/150#6753"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai un nouveau numéro de téléphone portable. Qu'en est-il des codes de sécurité envoyés par SMS ?"])}
      },
      "sms_help1": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puis-je utiliser mon GSM pour recevoir un code de sécurité via SMS pour quelqu'un d'autre?"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/151#6394"])}
      },
      "lost_token": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/114#6831"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai perdu mon token. Que dois-je faire?"])}
      },
      "commercial_certif": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un certificat commercial enregistré"])}
      },
      "156_login": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/156#6464"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De quoi ai-je besoin pour m'identifier?"])}
      },
      "118_username_password": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/118#6443"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur et mot de passe oubliés?"])}
      },
      "158_app_sms_token": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/158#6465"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjà paramétré un nom d'utilisateur et un mot de passe via l'appli mobile, sms ou token?"])}
      },
      "otc": {
        "activation_code_lost": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/jai-perdu-mon-code-dactivation-que-dois-je-faire#7129"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai perdu mon code d'activation. Que dois-je faire?"])}
        },
        "what_is_activation_code": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/quel-est-mon-code-dactivation#7165"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel est mon code d'activation?"])}
        },
        "wrong_code_entered": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/jai-complete-trois-fois-le-mauvais-code-dactivation-que-dois-je-faire#7169"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai complété trois fois le mauvais code d'activation. Que dois-je faire?<"])}
        }
      },
      "why_login_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code-de-securite-par-e-mail#7308"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi se connecter avec l'e-mail?"])}
      },
      "new_email_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code-de-securite-par-e-mail#7311"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai une nouvelle adresse e-mail. Qu'en est-il du code de sécurité envoyé par e-mail?"])}
      },
      "get_started_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code-de-securite-par-e-mail#7317"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je veux me lancer! De quoi ai-je besoin?"])}
      },
      "x_attempts_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code-de-securite-par-e-mail#7320"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un message me dit qu'il ne me reste que X tentatives."])}
      },
      "lapsed_code_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code-de-securite-par-e-mail#7323"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un message me dit que mon code de sécurité a expiré. Que dois-je faire?"])}
      },
      "7185": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/fr/faq/comment-enregistrer-un-nom-dutilisateur-et-un-mot-de-passe#7185"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer soi-même un nom d'utilisateur et un mot de passe?"])}
      },
      "7189": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/fr/faq/je-desire-enregistrer-un-nom-dutilisateur-et-un-mot-de-passe-mais-mon-adresse-e-mail-est-deja#7189"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que faire si mon adresse e-mail est déjà connue?"])}
      },
      "7193": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/fr/faq/je-desire-enregistrer-un-nom-dutilisateur-et-un-mot-de-passe-mais-mon-nom-dutilisateur-est-deja#7193"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que faire si mon nom d'utilisateur est déjà connu?"])}
      },
      "wp_confirmation_help_text_1": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas reçu d'e-mail d'activation ?"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/fr/faq/je-nai-pas-recu-mon-lien-dactivation-que-dois-je-faire#7133"])}
      },
      "wp_confirmation_help_text_2": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi activer le nom d'utilisateur et le mot de passe ?"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/fr/faq/pourquoi-dois-je-activer-mon-nom-dutilisateur-et-mon-mot-de-passe#7197"])}
      },
      "wp_confirmation_help_text_3": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet e-mail peut-il encore être utilisé après 5 jours ?"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/fr/faq/le-lien-dactivation-pour-activer-mon-nom-dutilisateur-et-mon-mot-de-passe-est-valable-5-jours-le#7181"])}
      }
    }
  },
  "eidasregistration": {
    "registration": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue!"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comme c'est la première fois que vous vous identifiez avec eIDAS, nous vous demandons d'abord de fournir quelques informations supplémentaires. Si nous ne pouvons pas vous identifier automatiquement dans nos systèmes, ces informations peuvent nous aider à vous trouver et à vous contacter si nécessaire."])}
    },
    "reregistration": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer de s’identifier"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue à nouveau ! La dernière fois, vous n’avez pas pu vous identifier, car vous n’avez pas renseigné de numéro d’identification belge ou renseigné un numéro invalide. Nous vous demandons donc de contrôler vos données et de les corriger au besoin. Cliquez sur « Continuer » lorsque vous êtes prêt."])},
      "attempts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tentative ", _interpolate(_list(0)), " sur ", _interpolate(_list(1))])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
    },
    "form": {
      "nrn": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d’identification belge (numéro de registre national ou BIS)"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro d’identification belge est votre numéro d’identification unique auprès de l’Administration belge. Si vous êtes inscrit au registre national, il s’agit de votre numéro de registre national. Si vous n’êtes pas inscrit au registre national, votre numéro d’identification est votre numéro BIS. Pour plus d'informations, vous pouvez consulter la section d'aide au bas de cette page."])}
      },
      "nonrn": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n’ai pas de numéro national d’identification belge."])},
        "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Attention!</strong> Si vous ne saisissez pas de numéro d'identification, nous ne pourrons pas traiter vos données automatiquement. Le traitement sera plus long, vous ne pourrez donc pas vous identifier immédiatement."])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
      "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez votre adresse e-mail"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
      "phonePrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préfixe de numéro de téléphone"])},
      "gender": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculin"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Féminin"])}
      },
      "birthcountry": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays de naissance"])},
        "defaultOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un pays"])}
      },
      "birthplace": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
        "defaultOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduire votre lieu de naissance"])}
      },
      "eula": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis d’accord avec <0>les conditions générales</0> et <1>la politique de respect de la vie privée</1>."])},
        "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis d’accord avec "])},
        "generalterms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les conditions générales"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" et "])},
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la politique de respect de la vie privée"])},
        "terms": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.public.base)/eula/sma/fr/License%20agreement%20FEDICT%20IAM.pdf"])}
        },
        "privacy": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.bosa.base)/sites/default/files/content/FAS/privacyverklaring_csam_1.0_fr.pdf"])}
        }
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])}
    }
  },
  "eidasservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S’identifier avec une identification électronique reconnue au plan Européen"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le pays dans lequel la clé numérique avec laquelle vous souhaitez vous connecter a été émise."])},
    "countries": {
      "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autriche"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgique"])},
      "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarie"])},
      "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chypre"])},
      "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République Tchèque"])},
      "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemagne"])},
      "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danemark"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagne"])},
      "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonie"])},
      "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlande"])},
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
      "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grèce"])},
      "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatie"])},
      "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongrie"])},
      "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irlande"])},
      "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italie"])},
      "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
      "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithuanie"])},
      "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grand-Duché de Luxembourg"])},
      "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettonie"])},
      "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
      "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays-Bas"])},
      "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pologne"])},
      "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
      "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roumanie"])},
      "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovaquie"])},
      "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovénie"])},
      "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suède"])},
      "UK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Royaume-Uni"])},
      "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eIDAS-Node Validator"])},
      "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norvège"])}
    }
  },
  "eidpasswordresetservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le nom d'utilisateur et le mot de passe"])},
    "reset_password": {
      "step1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre clé"])},
        "eid": {
          "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez votre lecteur de cartes eID à votre ordinateur."])},
          "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérez votre carte d'identité électronique (eID), carte pour étrangers ou kids-ID dans le lecteur de cartes."])},
          "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur <strong>'Continuer'</strong>, sélectionnez votre certificat et saisissez le code PIN de votre carte d'identité lorsqu'on vous le demandera."])}
        }
      },
      "step2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier"])}
      },
      "step3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])}
      }
    }
  },
  "eidservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier avec un lecteur de cartes eID"])},
    "alt-text": {
      "connected-eID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lecteur de cartes eID"])}
    },
    "login": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez votre lecteur de cartes eID à votre ordinateur"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérez votre carte d'identité électronique (eID), carte pour étrangers ou kids-ID dans le lecteur de cartes"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur <strong>S'identifier</strong>, sélectionnez votre certificat et saisissez le code PIN de votre carte d'identité lorsqu'on vous le demandera"])},
      "button": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier"])}
      },
      "choose_other_digital_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une autre clé numérique"])}
    },
    "video": {
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://idp.iamfas.belgium.be/fasui/images/videos/Aanmelden-eID_kaartlezer_FR.png"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/tIuPPXmZxKg?autoplay=1"])}
    },
    "error": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'identification"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n’êtes pas identifié. Vérifiez les causes les plus courantes :"])},
      "image_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triangle de signalisation"])},
      "cardreader": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID correctement insérée dans le lecteur de cartes ?"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez si vous avez correctement inséré votre eID dans votre lecteur de cartes."])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermez toutes les fenêtres de votre navigateur et votre navigateur complètement."])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez si votre lecteur de cartes est correctement connecté."])},
        "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérez votre eID dans le lecteur de cartes."])},
        "step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancez votre navigateur et réessayez de vous connecter."])}
      },
      "software": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous venez d’installer le logiciel eID ?"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous venez de (ré)installer le logiciel eID ?"])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redémarrez entièrement votre ordinateur."])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayez de vous identifier."])},
        "nosoftware": {
          "preface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n’avez pas encore installé le logiciel eID ou vous avez une ancienne version ?"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.eid.base)/fr"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez le logiciel eID"])}
        }
      },
      "help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À l’aide, cette page s’affiche sans arrêt !"])},
        "eid_help_pages": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://eid.belgium.be/fr/questions-et-reponses"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez notre page d'aide eID."])}
        },
        "contact_form_link": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be/fr/contact"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])}
        }
      }
    }
  },
  "error": {
    "general": {
      "read_faq": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq-list"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si cette erreur persiste ou en cas de questions, consultez les <0>pages d'aide</0>."])}
      },
      "contact_us": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/contact"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous ne trouvez pas de réponse à votre question, veuillez nous contacter via notre <0>formulaire de contact</0>."])}
      }
    },
    "service_unavailable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos services rencontrent malheureusement des problèmes."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos services rencontrent actuellement des problèmes. Vous ne pouvez donc pas vous identifier pour l’instant.<br/>Nous travaillons à la résolution de ce problème dans les plus brefs délais. Réessayez de vous identifier plus tard."])}
    },
    "request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["request-id: "])},
    "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timestamp: "])},
    "server_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["server-id: "])},
    "correlation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correlation-id: "])},
    "error": {
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose a mal tourné"])},
      "unexpected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur inattendue"])}
    },
    "page_not_found": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page non trouvée"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La page que vous cherchez n'existe pas."])}
    },
    "validation": {
      "fas": {
        "error": {
          "key": {
            "failed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["L''essai ", _interpolate(_list(0)), " est infructueux. Vous pouvez essayer encore ", _interpolate(_list(1)), " fois avant que cette clé numérique ne soit bloquée."])},
            "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une de vos clés numériques a été bloquée. Veuillez réessayer dans une heure."])},
            "user": {
              "not_found": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne disposez pas de cette clé numérique (nom d'utilisateur et mot de passe) ou la clé a peut-être expiré. Identifiez-vous avec une autre clé ou activez cette clé numérique dans "])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAM - Mes clés numériques"])}
              }
            }
          },
          "mailotp": {
            "nocredential": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom d'utilisateur et mot de passe sont correct mais vous ne disposez pas de cette clé numérique (code de sécurité via mail). Identifiez-vous d'une autre façon ou activez cette clé numérique dans <0>CSAM - Mes clés numériques</0>."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=fr"])}
            },
            "gateway_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le service E-mail est temporairement indisponible. Veuillez réessayer ultérieurement."])}
          },
          "otp": {
            "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe à usage unique a expiré. Demandez un nouveau mot de passe à usage unique et réessayez."])},
            "missing": {
              "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez un mot de passe unique."])}
            },
            "no_phone": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom d'utilisateur et mot de passe sont correct mais vous ne disposez pas de cette clé numérique (code de sécurité via SMS). Identifiez-vous d'une autre façon ou activez cette clé numérique dans <0>CSAM - Mes clés numériques</0>."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=fr"])}
            }
          },
          "nrn": {
            "nonrn": {
              "nrntext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de votre identification sur un service en ligne de l'administration, nous n'avons pas reçu votre numéro de registre national. \nIl est probable que votre numéro de registre national n'est pas associé à votre profil itsme®."])},
              "moreinfo": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir <0>https://www.itsme.be/fr</0> pour plus d'information sur itsme®."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.itsme.be/fr"])}
              },
              "errortoken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error token:"])},
              "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timestamp:"])},
              "serverid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["serverid:"])}
            }
          },
          "login": {
            "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'identification a échoué."])}
          },
          "identity": {
            "deceased": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce profil n'est plus actif. Veuillez contacter le <0>Service Desk</0> pour plus d'informations."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be/fr/formulaire-de-contact"])}
            }
          },
          "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez été inactif pendant trop longtemps, essayez de vous reconnecter."])},
          "page": {
            "not": {
              "found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Page non trouvée</strong><br>La page que vous cherchez n'existe pas."])}
            }
          },
          "cert": {
            "not_trusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Un problème est survenu avec votre certificat.</strong> <br/><br/>Choisissez une autre clé numérique ou fermez complètement votre navigateur et réessayez."])},
            "x509": {
              "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Un problème est survenu avec votre certificat.</strong> <br/><br/>Choisissez un autre clé numérique ou fermez complètement votre navigateur et réessayez."])}
            },
            "ocsp_crl": {
              "failed": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre certificat n'a pas pu être validé. Veuillez réessayer ultérieurement. Si le problème persiste, nous vous invitions à contrôler la validité de votre certificat personnel et/ou de votre certificat commercial. <br/>Pour plus d'informations sur votre certificat personnel (eID), consultez cette <0>page d'aide</0>.<br/>Pour plus d'informations sur les certificats commerciaux, consultez cette <1>page d'aide</1>."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq/52"])},
                "url2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/faq-list?cat=80"])}
              }
            }
          },
          "otc": {
            "activation": {
              "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé. Votre activation a été bloquée."])},
              "failed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["<strong>Code d'activation erroné.</strong> Indiquez votre code d'activation personnel correct. <strong>Vous disposez encore de ", _interpolate(_list(0)), "</strong> tentatives. Ensuite, l'activation sera bloquée pour votre sécurité."])},
              "too": {
                "many": {
                  "tries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez <strong>saisi 3 fois de suite un code d'activation erroné</strong>. C'est la raison pour laquelle nous avons bloqué votre activation pour votre sécurité."])}
                }
              },
              "request": {
                "new": {
                  "activation_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Demandez un nouveau code d'activation et un nouveau lien d'activation</strong> à votre maison communale, bureau d'enregistrement, ambassade ou au Service Desk Transformation Digitale."])}
                }
              }
            }
          },
          "partner": {
            "login": {
              "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>L'identification a échoué.</strong><br/><br/>Il n'est pas possible d'etablir une connexion avec MYDIGIPASS.COM."])}
            }
          },
          "password": {
            "missing": {
              "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez un mot de passe."])},
              "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez un nom d'utilisateur."])}
            }
          },
          "sms": {
            "gateway": {
              "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le service SMS est temporairement indisponible. Veuillez réessayer ultérieurement."])}
            }
          },
          "token": {
            "user": {
              "not_found": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne disposez pas de cette clé numérique (code de sécurité via token), ou la clé a peut-être expiré. Identifiez-vous avec une autre clé ou activez cette clé numérique dans <0>CSAM - Mes clés numériques</0>."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=fr"])}
              }
            }
          },
          "totp": {
            "invalid": {
              "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'identification a échoué."])}
            },
            "missing": {
              "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez un code unique."])}
            },
            "no": {
              "device": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom d'utilisateur et mot de passe sont correct mais vous ne disposez pas de cette clé numérique (code de sécurité via une application mobile). Identifiez-vous d'une autre façon ou activez cette clé numérique dans <0>CSAM - Mes clés numériques</0>."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=fr"])}
              }
            }
          }
        }
      },
      "create": {
        "firstname": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez un nom correct de minimum 2 caractères. N'utilisez que des lettres de l'alphabet, des espaces et un tiret (« - »)."])}
        },
        "lastname": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez un nom correct de minimum 2 caractères. N'utilisez que des lettres de l'alphabet, des espaces et un tiret (« - »)."])}
        },
        "username": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez introduire un nom d’utilisateur."])},
          "notunique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom d'utilisateur est déjà utilisé. Veuillez choisir un autre nom d'utilisateur."])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom d’utilisateur doit comprendre entre 6 et 64 caractères, avec au moins une lettre, et ne doit se composer que de lettres, de chiffres et/ou des caractères suivants"])},
          "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom d'utilisateur existe déjà. Choisissez un autre nom d'utilisateur."])}
        },
        "password": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez un mot de passe."])},
          "invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Votre mot de passe doit compter entre ", _interpolate(_list(1)), " et ", _interpolate(_list(0)), " caractères, contenir au moins  une lettre miniscule, une lettre majuscule, un chiffre et l’un des caractères suivants"])},
          "unchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez introduit votre mot de passe actuel. Veuillez introduire un autre mot de passe."])},
          "repeat": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez votre mot de passe."])},
            "notequal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe introduits ne correspondent pas."])}
          }
        },
        "email": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez une adresse e-mail."])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez une adresse e-mail valide."])},
          "notunique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette adresse e-mail est déjà utilisée. Veuillez introduire une autre adresse e-mail."])},
          "not": {
            "unique": {
              "weak": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette adresse e-mail est déjà utilisée. Veuillez introduire une autre adresse e-mail ou vous identifier avec le nom d'utilisateur et le mot de passe associés à cette adresse e-mail. <0>Mot de passe oublié</0> ?"])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.base)/public/resetpassword/chooseCredential?language=fr"])}
              },
              "normal": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette adresse e-mail est déjà utilisée. Veuillez introduire une autre adresse e-mail ou vous identifier avec le nom d'utilisateur et le mot de passe associés à cette adresse e-mail. <0>Mot de passe oublié</0> ?"])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.base)/public/resetpassword/chooseCredential?language=fr"])}
              }
            }
          },
          "confirm": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez votre adresse e-mail."])},
            "notequal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les adresses e-mail introduites ne correspondent pas."])}
          }
        },
        "nrn": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez un numéro d’identification belge valide."])}
        },
        "phone": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone incorrect. Le numéro doit comprendre entre 8 et 9 chiffres pour un numéro national et entre 2 et 14 chiffres pour un numéro international. N'utilisez que des chiffres et pas de points ou de tirets (« - »)."])}
        },
        "gender": {
          "not": {
            "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez faire un choix."])}
          }
        },
        "country": {
          "not": {
            "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez faire votre pays de naissance."])}
          }
        },
        "city": {
          "not": {
            "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez introduire votre lieu de naissance."])}
          }
        },
        "consent": {
          "notchecked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez marquer votre accord avec les conditions générales et la politique de respect de la vie privée."])}
        }
      }
    },
    "lifecycle": {
      "error": {
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez introduit votre mot de passe actuel. Veuillez introduire un autre mot de passe."])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom d'utilisateur existe déjà. Choisissez un autre nom d'utilisateur."])}
      }
    },
    "fas": {
      "error": {
        "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'identification a échoué."])},
        "partner": {
          "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre authentification, qui était utilisée par un de nos partenaires autorisés, a été interrompue. Veuillez fermer complètement votre navigateur et réessayer."])},
          "error": {
            "multiple": {
              "browsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous utilisez plusieurs navigateurs sur votre smartphone, veuillez utiliser uniquement le navigateur par défaut pour vous identifier. Si vous le souhaitez, vous pouvez modifier le navigateur par défaut dans les paramètres de votre smartphone."])}
            },
            "incognito": {
              "browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous utilisez le mode Incognito ou Navigation privée de votre navigateur ? Désactivez-le, puis réessayez de vous identifier."])}
            },
            "contact": {
              "helpdesk": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si les options ci-dessus ne permettent pas de résoudre le problème, contactez le Service Desk Transformation Digitale. Vous trouverez ses coordonnées <0>ici</0>."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/formulaire-de-contact"])}
              }
            }
          }
        },
        "consent": {
          "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez refusé l'autorisation de recevoir les messages des services publics adhérents par voie électronique. Sans cette autorisation vous ne pouvez pas utiliser ce service."])}
        },
        "redirect": {
          "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous recevez un message d’erreur lorsque vous vous authentifiez, vérifiez que vous avez défini le navigateur mobile utilisé comme navigateur par défaut (évitez Samsung Internet) et que vous n'utilisez pas le mode privé. Vous éviterez ainsi ce message d'erreur."])},
          "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le problème persiste, nous vous conseillons d’utiliser le site web de votre application."])}
        }
      }
    },
    "eidas": {
      "registration": {
        "pending": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre identification n'a pas pu être traitée automatiquement."])},
          "mail": {
            "sent": {
              "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nous avons envoyé un e-mail à <strong>", _interpolate(_list(0)), "</strong>, et prendrons contact avec vous lorsque votre identification sera traitée. Pour plus d'informations, consultez notre <0>page d'aide</0>."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/eidas"])}
            }
          }
        }
      },
      "error": {
        "page": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'identification"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n’êtes pas identifié. Vérifiez les causes les plus courantes :"])},
          "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'identification via eIDAS n'est pas possible dans les situations suivantes :"])},
          "name": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification avec eID ou carte électronique pour étrangers avec un nom déclaré :"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas vous identifier via eIDAS si vous avez un nom déclaré, puisque vous n'avez pas pu prouver votre identité à l’Administration belge."])}
          },
          "enterprise": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification auprès d'une entreprise privée :"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous ne prenons en charge l'identification qu'auprès de l'Administration en ligne."])}
          },
          "contact": {
            "helpdesk": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les options ci-dessus ne permettent pas de résoudre vos problèmes ? Contactez le <0>Service Desk Transformation Digitale</0>."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr/formulaire-de-contact"])}
            }
          }
        },
        "nomatch": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre enregistrement n'a malheureusement pas pu être traité automatiquement. Les données personnelles que vous avez introduites ne correspondent pas avec celles du numéro d'identification belge renseigné."])},
          "text": {
            "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nous avons envoyé un e-mail à <strong>", _interpolate(_list(0)), "</strong>, et prendrons contact avec vous lorsque votre identification sera traitée. Pour plus d'informations, consultez notre <0>page d'aide</0>."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.fedict.belgium.be/fr/eidas#7283"])}
          }
        },
        "maxattempts": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre enregistrement n’a malheureusement pas pu être traité automatiquement, car le nombre maximum de tentatives a été atteint."])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nous avons envoyé un e-mail à ", _interpolate(_list(0)), " reprenant les détails de votre enregistrement. Veuillez contacter le bureau d'assistance de l'application que vous souhaitez utiliser."])}
        },
        "duplicates": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre enregistrement n’a malheureusement pas pu être traité automatiquement. Il existe déjà dans le registre national un numéro d’identification belge avec le même nom et date de naissance."])},
          "text": {
            "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nous avons envoyé un e-mail à <strong>", _interpolate(_list(0)), "</strong>, et prendrons contact avec vous lorsque votre identification sera traitée. Pour plus d'informations, consultez notre <0>page d'aide</0>."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.fedict.belgium.be/fr/eidas#7287"])}
          }
        },
        "notfound": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre enregistrement n'a malheureusement pas pu être traité automatiquement. Le numéro d'identification belge renseigné n'existe pas."])},
          "text": {
            "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nous avons envoyé un e-mail à <strong>", _interpolate(_list(0)), "</strong>, et prendrons contact avec vous lorsque votre identification sera traitée. Pour plus d'informations, consultez notre <0>page d'aide</0>."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.fedict.belgium.be/fr/eidas#7279"])}
          }
        },
        "unavailable": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos services rencontrent malheureusement des problèmes."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos services rencontrent actuellement des problèmes nous empêchant d’effectuer des recherches dans le registre national. Vous ne pouvez donc pas vous identifier pour l’instant.<br/>Nous travaillons à la résolution de ce problème dans les plus brefs délais. Réessayez de vous identifier plus tard."])}
        },
        "invalididentifier": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez malheureusement pas vous identifier, car votre numéro d’identification belge n’est plus valable."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez contacter le bureau d’assistance de l’application que vous souhaitez utiliser."])},
          "bosahelpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez contacter le Helpdesk de BOSA."])}
        },
        "nobiscreation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre identification n'a pas pu être traitée automatiquement."])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nous avons envoyé un e-mail à <strong>", _interpolate(_list(0)), "</strong> reprenant les détails de votre enregistrement. Veuillez contacter le bureau d'assistance de l'application que vous souhaitez utiliser."])}
        }
      }
    }
  },
  "footer": {
    "nav": {
      "copy_right": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.csam.base)/fr/index.html"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAM"])}
      },
      "terms_of_use": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.public.base)/eula/sma/fr/License%20agreement%20FEDICT%20IAM.pdf"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d'utilisation"])}
      },
      "privacy": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.bosa.base)/sites/default/files/content/FAS/privacyverklaring_csam_1.0_fr.pdf"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vie privée"])}
      },
      "accessibility": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibilité"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/accessibility/fasui/fr/Déclaration%20d'accessibilité%20FR%20FASUI.pdf"])}
      },
      "powered_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by SPF BOSA - DG Transformation Digitale"])}
    }
  },
  "foreignerservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer votre clé numérique"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquez votre code d'activation personnel."])},
    "activation_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'activation"])},
    "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquer le code d'activation"])},
    "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre clé numérique"])},
    "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activez votre clé numérique"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce code, que vous avez reçu personnellement, comprend 20 caractères."])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommencer"])}
  },
  "header": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier à l'administration en ligne"])},
    "eidas": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier comme citoyen de l'UE"])}
    },
    "sma": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes clés numériques"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier à l'administration en ligne"])}
    },
    "weakprofile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement du nom d'utilisateur et du mot de passe"])}
    }
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre clé numérique pour vous identifier."])},
    "need_help": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide?"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/fr"])}
    },
    "group_header": {
      "eid_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé(s) numérique(s) avec <strong>l'eID</strong> ou <strong>identité numérique</strong>"])},
      "twofactor_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé(s) numérique(s) avec <strong>code de sécurité</strong> et <strong>nom d'utilisateur + mot de passe</strong>"])},
      "id_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé(s) numérique(s) avec <strong>nom d'utilisateur+ mot de passe</strong>"])},
      "european_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication européenne"])},
      "external_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé(s) numérique(s) par l'intermédiaire de <strong>partenaires externes</strong>"])}
    },
    "digital_key": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification"])},
      "eidservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec un lecteur de cartes eID"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De quoi avez-vous besoin? De votre eID (carte d'identité, carte pour étrangers ou kids-ID), de votre code PIN, d'un lecteur de cartes et du logiciel eID sur votre ordinateur."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lecteur de cartes eID"])}
      },
      "bmid": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via itsme"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour utiliser cette possibilté d'identification, vous devez déjà être enregistré(e) sur itsme."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["itsme"])},
        "register": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.itsme.be/fr/get-started"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre compte itsme"])}
        }
      },
      "myid": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via myID.be"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour utiliser cette possibilté d'identification, vous devez déjà être enregistré(e) sur myID.be."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myID.be"])},
        "register": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://myID.be/ActivateLogin?ui_locales=fr"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre compte myID.be"])}
        }
      },
      "bdiw": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification via MyGov.be"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour utiliser cette possibilité d'identification, vous devez déjà être enregistré(e) sur MyGov.be."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification via MyGov.be"])}
      },
      "vasco": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via mydigipass"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour utiliser cette possibilté d'identification, vous devez déjà être enregistré(e) sur mydigipass."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mydigipass"])}
      },
      "helena": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec Helena"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helena est une clé numérique qui vous permet de vous connecter aux applications eHealth en tant que patient."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helena"])}
      },
      "otpservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec un code de sécurité via SMS"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par compléter vos identifiant et mot de passe de Mes clés numériques, puis cliquez sur Continuer pour recevoir le SMS."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code de sécurité via SMS"])}
      },
      "totpservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec un code de sécurité via une application mobile"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par saisir vos identifiant et mot de passe de Mes clés numériques avant de cliquer sur Continuer pour indiquer le code unique."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code de sécurité via une application mobile"])}
      },
      "mailotpservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec un code de sécurité envoyé par e-mail"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par saisir vos identifiant et mot de passe de Mes clés numériques avant de cliquer sur Continuer pour indiquer le code unique."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code de sécurité envoyé par e-mail"])}
      },
      "commercialservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec un certificat commercial"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un certificat commercial est une manière valable de se connecter et de s'identifier dans une application publique en ligne. Le certificat commercial est destiné à un usage personnel et doit être chargé dans votre navigateur."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certificat commercial"])}
      },
      "commercialnoidservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec un certificat commercial"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un certificat commercial est une manière valable de se connecter et de s'identifier dans une application publique en ligne. Le certificat commercial est destiné à un usage personnel et doit être chargé dans votre navigateur."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certificat commercial"])}
      },
      "commercial": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec un certificat commercial"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un certificat commercial est une manière valable de se connecter et de s'identifier dans une application publique en ligne. Le certificat commercial est destiné à un usage personnel et doit être chargé dans votre navigateur."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certificat commercial"])}
      },
      "comcertnoid": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec un certificat commercial"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un certificat commercial est une manière valable de se connecter et de s'identifier dans une application publique en ligne. Le certificat commercial est destiné à un usage personnel et doit être chargé dans votre navigateur."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certificat commercial"])}
      },
      "citizentokenservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec code de sécurité via token"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vous connecter avec votre token, commencez par entrer votre identifiant et mot de passe de Mes clés numériques."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code de sécurité via token"])}
      },
      "passwordservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec vos nom d'utilisateur et mot de passe"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit de l’identifiant et du mot de passe de Mes clés numériques."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom d'utilisateur et mot de passe"])}
      },
      "lowprofileservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec vos nom d'utilisateur et mot de passe"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit de l’identifiant et du mot de passe de Mes clés numériques."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom d'utilisateur et mot de passe"])}
      },
      "storkservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec l'authentification européenne"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'authentification transfrontalière permet aux étrangers de s'authentifier à partir d'autres pays européens ou depuis la Belgique, sur des applications publiques belges qui offrent cette possibilité d'authentification."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authentification européenne"])}
      },
      "eidasservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec une identification électronique reconnue au plan européen"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous disposez d’un moyen d’identification électronique national reconnu par l’Europe, le règlement eIDAS vous permet de vous identifier."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identification électronique reconnue au plan européen"])}
      },
      "partnerservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with wireless eID card reader"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour utiliser cette possibilité d’identification, vous devez déjà être enregistré(e) sur MYDIGIPASS et posséder une eID et un lecteur de cartes sans fil."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wireless eID card reader"])}
      }
    },
    "sidebar": {
      "custom": {
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous souhaitez accéder à :"])}
      },
      "important_to_know": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important à savoir !"])},
      "once_you_are_connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois que vous êtes identifié(e) avec une clé numérique, vous avez automatiquement accès via CSAM à d'autres services publics en ligne sécurisés par la même clé. Cela vaut tant que la fenêtre de votre navigateur est active."])},
      "video": {
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://idp.iamfas.belgium.be/fasui/images/videos/Aanmelden-alloptions_FR.png"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/RFeYiUVEjo0?autoplay=1"])}
      }
    }
  },
  "info": {
    "application": {
      "version": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version de l'application"])}
      },
      "build": {
        "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build tag"])},
        "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build timestamp"])}
      }
    }
  },
  "lowprofileservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec un identifiant et un mot de passe"])},
    "login": {
      "step": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre"])},
        "strong_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom d'utilisateur"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
        "strong_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["votre mot de passe"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre <strong>nom d'utilisateur</strong> et <strong>votre mot de passe</strong>."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur <strong>Continuer</strong>"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
      },
      "reset_password": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur et/ou mot de passe oublié(s)?"])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore activé la clé numérique \"Code de sécurité via nom d'utilisateur et de mot de passe\" ?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vous identifier avec le code de sécurité via nom d'utilisateur et de mot de passe, vous devez d'abord activer cette clé numérique. Cliquez sur 'S'identifier avec le lecteur de cartes eID' et suivez les étapes."])}
    }
  },
  "mailotpservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiez-vous à l’aide du code de sécurité envoyé par e-mail"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le code de sécurité que vous avez reçu par e-mail"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur <strong>Continuer</strong>"])}
      },
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre mot de passe à usage unique"])},
      "warning": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Il peut s’écouler quelques minutes avant que vous ne receviez l’e-mail. Vérifiez également votre dossier de courrier indésirable. Le code ne reste valable que 10 minutes. Saisissez-le donc à temps."])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore activé la clé numérique \"Code de sécurité par e-mail\" ?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vous identifier avec le code de sécurité par e-mail, vous devez d'abord activer cette clé numérique. Cliquez sur 'S'identifier avec le lecteur de cartes eID' et suivez les étapes."])}
    },
    "footer": {
      "video": {
        "1": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation-Security_code_mail_FR.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/dFSWG-p4mQg?autoplay=1"])}
        },
        "2": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login-security_code_mail_FR.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/OiSPKOzmWuA?autoplay=1"])}
        }
      }
    }
  },
  "otpservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier avec un code de sécurité via SMS"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le code de sécurité que vous avez reçu par SMS :"])}
      },
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre mot de passe unique."])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur <strong>Continuer</strong>"])},
      "warning": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Le code ne reste valable que 10 minutes. Saisissez-le donc à temps."])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore activé la clé numérique \"Code de sécurité via SMS\" ?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vous identifier avec le code de sécurité via SMS, vous devez d'abord activer cette clé numérique. Cliquez sur 'S'identifier avec le lecteur de cartes eID' et suivez les étapes."])}
    },
    "footer": {
      "video": {
        "1": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden-aanmelden_met_sms_FR.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/V9gyD0_3z-M?autoplay=1"])}
        },
        "2": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeren-aanmelden_met_sms_FR.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/DP_bXYd2emY?autoplay=1"])}
        }
      }
    }
  },
  "passwordservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec un identifiant et un mot de passe"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre <strong>nom d'utilisateur</strong> et <strong>votre mot de passe</strong>."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur <strong>Continuer</strong>"])}
      },
      "reset_password": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur et/ou mot de passe oublié(s)?"])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore activé la clé numérique \"Code de sécurité via nom d'utilisateur et de mot de passe\" ?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vous identifier avec le code de sécurité via nom d'utilisateur et de mot de passe, vous devez d'abord activer cette clé numérique. Cliquez sur 'S'identifier avec le lecteur de cartes eID' et suivez les étapes."])}
    }
  },
  "postauthentication": {
    "setcapacity": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre mode d'identification :"])},
      "button": {
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
      },
      "options": {
        "citizen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en votre propre nom"])},
        "enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au nom d'une entreprise"])}
      },
      "explanation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi ce choix ?"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez accéder à l'administration en ligne en tant que membre de l'un de ces groupes cibles :"])},
        "text2": {
          "part1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en votre propre nom (citoyen)"])},
          "part2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au nom d'une entreprise (responsable d'une entreprise)"])}
        },
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque groupe cible offre aux utilisateurs d'autres possibilités et droits."])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention !"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est cependant impossible de s'identifier simultanément dans le même navigateur Internet pour deux groupes cibles différents. Si vous changez de groupe cible, on vous demandera sur toutes les applications sur lesquelles vous êtes identifié(e) de fermer votre groupe cible actuel. Dans le cas contraire, vous n'aurez pas accès à une application d'un autre groupe cible."])}
      }
    },
    "emailverification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez confirmer votre adresse e-mail"])},
      "link": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue ! Pour garantir votre sécurité, les clés numériques sont limitées dans le temps. Quelques mois avant l’expiration de ces clés, nous vous en informons par e-mail. Nous vous demandons de compléter votre adresse e-mail pour pouvoir vous informer correctement et à temps. Vous trouverez plus d'informations <0>ici</0>."])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be/fr/andere#7294"])}
      },
      "confirm": {
        "form": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez confirmer votre adresse e-mail."])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
          "email_repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer l’adresse e-mail"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])}
        }
      },
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez introduit votre adresse e-mail !"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur \"Continuer\" pour vous identifier."])}
      }
    },
    "setlifecycle": {
      "usernamepassword": {
        "warning": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier votre nom d’utilisateur et mot de passe"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Bienvenue! Nous avons remarqué que votre <strong>nom d’utilisateur et mot de passe</strong> expirent bientôt, le <strong><0>var:expiryDate</0></strong>. Pour éviter que vous ne puissiez plus vous identifier avec votre nom d’utilisateur et mot de passe ou des clés numériques qui en dépendent, nous vous invitons à remplir et à nous envoyer le formulaire ci-dessous. Si vous n’avez pas le temps, vous pouvez aussi le faire plus tard. Cliquez sur \"Non merci, je le ferai plus tard\" en dessous de ce formulaire."])}
        },
        "renewal": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez modifier votre nom d’utilisateur et/ou mot de passe."])}
        },
        "expiring": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom d’utilisateur et mot de passe expirent le <strong><0>var:expiryDate</0></strong> et doivent donc être modifiés."])}
        }
      },
      "token": {
        "warning": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouveler votre token"])},
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bienvenue ! Nous avons remarqué que votre <strong>code de sécurité via token</strong> expire bientôt, le <strong><0>", _interpolate(_named("expiryDate")), "</0></strong>. Pour éviter que vous ne puissiez plus vous identifier avec cette clé numérique, nous vous invitons à remplir et à nous envoyer le formulaire ci-dessous. Nous ferons alors en sorte que votre code de sécurité via token soit renouvelé à temps. Si vous n’avez pas le temps, vous pouvez aussi le faire plus tard. Cliquez sur \"Non merci, je le ferai plus tard\" en dessous du formulaire."])}
        },
        "renewal": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous remplissez ce formulaire, nous créerons automatiquement un nouveau code de sécurité via token pour vous et nous vous l’enverrons sur votre adresse e-mail."])}
        },
        "expiring": {
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre code de sécurité via token expire le <strong><0>", _interpolate(_named("expiryDate")), "</0></strong> et doit donc être renouvelé."])}
        }
      },
      "inputfields": {
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d’utilisateur "])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe "])},
        "repeatpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe "])},
        "email": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez et confirmez votre adresse e-mail. Si vous souhaitez utiliser une autre adresse e-mail, vous pouvez aussi la modifier ici."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail "])},
          "confirmlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer l’adresse e-mail "])}
        }
      },
      "tooltip": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non merci, je le ferai plus tard"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])}
    },
    "validation": {
      "username": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez introduire un nom d’utilisateur."])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom d’utilisateur doit comprendre entre 6 et 64 caractères, avec au moins une lettre, et ne doit se composer que de lettres, de chiffres et/ou des caractères suivants : -_."])},
        "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom d'utilisateur existe déjà. Choisissez un autre nom d'utilisateur."])}
      },
      "password": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez un mot de passe."])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe doit comprendre entre 8 et 100 caractères, avec au moins une lettre et un chiffre ou l’un des caractères suivants : !, $, #, %, -, *, _, '."])},
        "notequal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe introduits ne correspondent pas."])},
        "unchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez introduit votre mot de passe actuel. Veuillez introduire un autre mot de passe."])}
      },
      "repeatpassword": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez votre mot de passe."])}
      },
      "email": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez une adresse e-mail."])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez une adresse e-mail valide."])},
        "notunique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette adresse e-mail est déjà utilisée. Veuillez introduire une autre adresse e-mail."])}
      },
      "confirmemail": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez votre adresse e-mail."])},
        "notequal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les adresses e-mail introduites ne correspondent pas."])}
      }
    }
  },
  "pwdresetotp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le nom d'utilisateur et le mot de passe"])},
    "reset_password": {
      "step1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre clé"])}
      },
      "step2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier"])},
        "otp": {
          "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complétez le code unique que vous venez de recevoir par SMS:"])},
          "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur 'Continuer'"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code SMS unique"])}
        }
      },
      "step3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])}
      }
    }
  },
  "remote-consent": {
    "scopes": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[FR]governmentdocuments"])},
      "egovnrn": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[FR]national register number"])},
        "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["egovNRN"])}
      },
      "profile": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[FR]profile"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[FR]surname"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[FR]given name"])},
        "fedId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[FR]fedID"])},
        "preferredLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[FR]preferred language"])},
        "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[FR]e-mail address"])}
      },
      "details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[FR]Details about your public-service documents"])},
        "metaData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[FR]Meta-data"])},
        "documentsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[FR]Title of your document<br/>Document type<br/>Details of the sender<br/>Period of validity for the document<br/>"])},
        "content": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[FR]Content"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[FR]The service provider has no knowledge of the content of your documents. The content of the document is shown only if you are logged in and are sitting in front of your computer."])}
        }
      }
    },
    "button": {
      "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'accord"])},
      "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])}
    }
  },
  "totppasswordresetservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le nom d'utilisateur et le mot de passe"])},
    "reset_password": {
      "step1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre clé"])}
      },
      "step2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier"])},
        "chooseApp": {
          "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez l’application mobile que vous souhaitez utiliser:"])},
          "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur <strong>'Continuer'</strong>"])}
        },
        "totp": {
          "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrez l’application mobile que vous avez installée. Un code de sécurité unique est automatiquement généré."])},
          "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Complétez votre code de sécurité unique.<strong>"])},
          "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur <strong>'Continuer'</strong>"])}
        }
      },
      "step3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])}
      }
    }
  },
  "totpservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier avec un code de sécurité via une application mobile"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrez l'application mobile que vous installée. Un code de sécurité est généré automatiquement."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le code de sécurité unique:"])}
      },
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur <strong>Continuer</strong>"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre code unique"])}
    },
    "choice": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez l'application mobile que vous souhaitez utiliser :"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur <strong>Continuer</strong>."])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore activé la clé numérique \"Code de sécurité via application mobile\" ?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vous identifier avec le code de sécurité via application mobile, vous devez d'abord activer cette clé numérique. Cliquez sur \"S'identifier avec le lecteur de cartes eID\" et suivez les étapes."])}
    },
    "footer": {
      "video": {
        "1": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden-aanmelden_met_app_FR.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/2W3SA6t7T9c?autoplay=1"])}
        },
        "2": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeren-aanmelden_met_app_FR.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/fuj5q0XCtno?autoplay=1"])}
        }
      }
    }
  },
  "weakprofile": {
    "form": {
      "emailAddress": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])}
      },
      "firstname": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])}
      },
      "lastname": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])}
      },
      "username": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom d'utilisateur doit comprendre entre 6 et 64 caractères, avec au moins 1 lettre, et ne doit se composer que de lettres, de chiffres ou des caractères suivants"])}
      },
      "password": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
        "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Votre mot de passe doit compter entre ", _interpolate(_list(1)), " et ", _interpolate(_list(0)), " caractères, contenir au moins  une lettre miniscule, une lettre majuscule, un chiffre et l’un des caractères suivants"])}
      }
    },
    "email": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien d'activation est envoyé à cette adresse e-mail."])}
    },
    "registration": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom d'utilisateur et votre mot de passe ont été enregistrés avec succès!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons envoyé un e-mail à l'adresse <strong>var:email</strong> contenant un lien d'activation. Dès que vous cliquez sur ce lien, votre nom d'utilisateur et votre mot de passe sont activés."])}
      }
    },
    "info": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un nom d'utilisateur et un mot de passe ?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes-vous déjà identifié de par le passé avec une autre clé numérique comme avec un code de sécurité via application mobile, SMS ou token ? Vous pouvez alors vous identifier avec le même nom d'utilisateur et le même mot de passe. Allez à <0>CSAM - Mes clés numériques</0> pour le contrôler."])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=fr"])}
    }
  },
  "logout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez été déconnecté avec succès."])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous conseillons de fermer votre navigateur internet."])}
  }
}