<template>
    <div>
        <div class="sticky-footer">
            <PageTitle></PageTitle>
            <HeaderBosa></HeaderBosa>
            <TextOutputCallback v-if="stage === 'selectIdp' && textOutputText && textOutputType"
                :textMessage="textOutputText" :textType="textOutputType">
            </TextOutputCallback>
            <eidPreflight v-if="stage === 'redirect' && (store.subStage === 'eid' || store.subStage === 'reset_pwd_eid')"
                @eidLaunched="redirectEid"></eidPreflight>
            <UsernamePasswordCallback v-if="stage === 'UsernamePassword' || stage === 'StringPassword'"
                :textMessage="textOutputText" :textType="textOutputType" @submitCredentials="submitCredentials" />
            <PasswordCallback v-if="stage === 'secret'" :textMessage="textOutputText" :textType="textOutputType"
                @submitSecret="submitSecret"></PasswordCallback>
            <SelectEidasCallback v-if="stage === 'selectIdp' && store.subStage === 'eidas' && isEidasProviders"
                :providers="providers" @selectIdp="selectIdp"></SelectEidasCallback>
            <SelectIdp v-else-if="stage === 'selectIdp'" :providers="providers" @selectIdp="selectIdp" />
            <ChoiceCollectorCallback v-if="stage === 'choice' && isContextSelector" :prompt="prompt" :choices=choices
                @selectChoice="choiceSelected">
            </ChoiceCollectorCallback>
            <DeviceCollectorCallback v-else-if="stage === 'choice' && !isContextSelector" :choices=choices
                :textMessage="textOutputText" :textType="textOutputType" @selectChoice="choiceSelected">
            </DeviceCollectorCallback>
            <eidasRegistrationCallback v-if="stage === 'eidas_registration'"  :metadata="metadata" @submitEidasRegistration="submitTextInput">
            </eidasRegistrationCallback>
            <eidasReRegistrationCallback v-if="stage === 'eidas_re_registration'" :textMessage="textOutputText"
                :textType="textOutputType" @submitEidasReRegistration="submitTextInput">
            </eidasReRegistrationCallback>
            <ActivationCodeCallback v-if="stage === 'activation_code'" :textMessage="textOutputText"
                :textType="textOutputType" @submitActivationCode="submitTextInput">
            </ActivationCodeCallback>
            <div v-if="!stage" class="spinner-icon"></div>
            <FaqBosa v-if="stage === 'selectIdp' && !store.subStage === 'eidas'"></FaqBosa>
        </div>
        <FooterBosa></FooterBosa>
    </div>
</template> 
<script>
import clickableData from '@/assets/json/autoclickable.json';
import { defineAsyncComponent } from 'vue';
const UsernamePasswordCallback = defineAsyncComponent(() => import("@/components/callbacks/Username-Password-Callback.vue"));
const SelectEidasCallback = defineAsyncComponent(() => import("@/components/callbacks/SelectEidas-Callback.vue"));
const PasswordCallback = defineAsyncComponent(() => import("@/components/callbacks/Password-Callback.vue"));
const ChoiceCollectorCallback = defineAsyncComponent(() => import("@/components/callbacks/ChoiceCollector-Callback.vue"));
const eidPreflight = defineAsyncComponent(() => import("@/components/callbacks/eid-preflight.vue"));
const DeviceCollectorCallback = defineAsyncComponent(() => import("@/components/callbacks/DeviceCollector-Callback.vue"));
const TextOutputCallback = defineAsyncComponent(() => import("@/components/callbacks/TextOutput-Callback.vue"));
const ActivationCodeCallback = defineAsyncComponent(() => import("@/components/callbacks/ActivationCode-Callback.vue"));
const eidasRegistrationCallback = defineAsyncComponent(() => import("@/components/callbacks/EidasRegistration-Callback.vue"));
const eidasReRegistrationCallback = defineAsyncComponent(() => import("@/components/callbacks/EidasReRegistration-Callback.vue"));
import SelectIdp from '@/components/callbacks/SelectIdP-Callback.vue';
import PageTitle from "@/components/static/Page-Title.vue";
import HeaderBosa from "@/components/static/Header-Bosa.vue";
import FaqBosa from "@/components/static/Faq-Bosa.vue";
import FooterBosa from "@/components/static/Footer-Bosa.vue";
import { FRAuth } from "@forgerock/javascript-sdk";
import { ref, onMounted, watch } from "vue";
import { useStageStore } from "@/stores/stageStore";
import { useGoToStore } from "@/stores/goToStore";
import { useRouter } from "vue-router";
import i18n from '@/i18n';
import { handleRedirectCallback } from '@/handleRedirect';
import { v4 as uuidv4 } from 'uuid';

export default {
    components: { ActivationCodeCallback, UsernamePasswordCallback, PasswordCallback, ChoiceCollectorCallback, DeviceCollectorCallback, eidasRegistrationCallback, eidasReRegistrationCallback, PageTitle, HeaderBosa, FaqBosa, FooterBosa, SelectIdp, SelectEidasCallback, eidPreflight, TextOutputCallback },
    setup() {
        const locales = ["nl", "fr", "de", "en"];
        const store = useStageStore();
        const goStore = useGoToStore();
        const router = useRouter();
        const step = ref(null);
        const stage = ref(null);
        let rstep;

        //static values
        let autoclickableKeys = [];
        clickableData.forEach(e => autoclickableKeys.push(e.name));

        //changes for IDP
        const providers = ref(null);
        const isEidasProviders = ref(false);

        //For choice callback
        const prompt = ref(null);
        const choices = ref(null);
        const isContextSelector = ref(false);

        //For text output
        const textOutputText = ref('');
        const textOutputType = ref('');

        //For metadata callbacks
        const metadata = ref(null);
        
        const getMetadata = (step) => {

            const cb = step.getCallbackOfType('MetadataCallback');
            metadata.value = cb.getData(); 
        }

        const getTextOutputMessageAndType = (step) => {
            const cb = step.getCallbackOfType('TextOutputCallback');
            textOutputText.value = cb.getMessage();
            textOutputType.value = cb.getMessageType();
        }

        const resetOutputMessageAndType = () => {
            textOutputText.value = "";
            textOutputType.value = "";
        }

        function getStage(step) {
            // Check if the step contains callbacks for capturing username and password
            const usernameCallbacks = step.getCallbacksOfType('NameCallback');
            const passwordCallbacks = step.getCallbacksOfType('PasswordCallback');
            const selectIdpCallbacks = step.getCallbacksOfType('SelectIdPCallback');
            const redirectCallbacks = step.getCallbacksOfType('RedirectCallback');
            const stringAttributeInputCallbacks = step.getCallbacksOfType('StringAttributeInputCallback');
            const choiceCollectorCallbacks = step.getCallbacksOfType('ChoiceCallback');
            const TextOutputCallbacks = step.getCallbacksOfType('TextOutputCallback');
            const NameCallbacks = step.getCallbacksOfType('NameCallback');
            const ConfirmationCallbacks = step.getCallbacksOfType('ConfirmationCallback');
            const TextInputCallbacks = step.getCallbacksOfType('TextInputCallback');
            const metadataCallbacks = step.getCallbacksOfType('MetadataCallback');

            if (usernameCallbacks.length && passwordCallbacks.length) {
                if (TextOutputCallbacks.length) {
                    getTextOutputMessageAndType(step);
                } else {
                    resetOutputMessageAndType();
                }
                return 'UsernamePassword';
            } else if (stringAttributeInputCallbacks.length && passwordCallbacks.length) {
                if (TextOutputCallbacks.length) {
                    getTextOutputMessageAndType(step);
                }
                return 'StringPassword';
            } else if (selectIdpCallbacks.length) {
                providers.value = getProviders(step);

                if (TextOutputCallbacks.length) {
                    getTextOutputMessageAndType(step);
                } else {
                    resetOutputMessageAndType();
                }

                // auto click: 
                // if only one button AND customer allows autoclick AND key is not disbabled
                if(providers.value.length === 1 && providers.value[0].uiConfig.autoClick && !providers.value[0].uiConfig.disabled) {
                    selectIdp(providers.value[0]);
                    return '';
                }

                return 'selectIdp';
            } else if (redirectCallbacks.length) {
                return 'redirect';
            } else if (passwordCallbacks.length || (NameCallbacks.length && ConfirmationCallbacks.length)) {
                if (TextOutputCallbacks.length) {
                    getTextOutputMessageAndType(step);
                } else {
                    resetOutputMessageAndType();
                }
                return 'secret';
            } else if (choiceCollectorCallbacks.length) {
                const cb = step.getCallbackOfType('ChoiceCallback');
                prompt.value = cb.getPrompt();
                choices.value = cb.getChoices();
                isContextSelector.value = choices.value.includes('postauthentication.setcapacity.options.citizen') ? true : false;
                if (TextOutputCallbacks.length) {
                    getTextOutputMessageAndType(step);
                } else {
                    resetOutputMessageAndType();
                }
                return 'choice';
            } else if (TextInputCallbacks.length) {
                if (TextOutputCallbacks.length) {
                    getTextOutputMessageAndType(step);
                } else {
                    resetOutputMessageAndType();
                }
                const cb = step.getCallbackOfType('TextInputCallback');
                switch (cb.getPrompt()) {
                    case 'activation_code':
                        return 'activation_code';
                    case 'eidas_registration':
                        if(metadataCallbacks.length) {
                            getMetadata(step);
                        }
                        return 'eidas_registration';
                    case 'eidas_re_registration':
                        return 'eidas_re_registration';
                }
            }

            return undefined;
        }
        //for username-password
        const submitCredentials = (credentials) => {

            if (stage.value === 'UsernamePassword') {
                const nameCallback = step.value.getCallbackOfType('NameCallback');
                const passwordCallback = step.value.getCallbackOfType('PasswordCallback');
                nameCallback.setName(credentials.username);
                passwordCallback.setPassword(credentials.password);
            } else if (stage.value === 'StringPassword') {
                const StringAttributeInputCallbacks = step.value.getCallbackOfType('StringAttributeInputCallback');
                const passwordCallback = step.value.getCallbackOfType('PasswordCallback');
                StringAttributeInputCallbacks.setValue(credentials.username);
                passwordCallback.setPassword(credentials.password);

            }
            nextStep(step.value);
        };
        //for username-password
        const submitSecret = (secret) => {

            if (store.subStage === 'oath' || store.subStage === 'reset_pwd_totp') {
                const NameCallback = step.value.getCallbackOfType('NameCallback');
                NameCallback.setName(secret.replace(/\s/g, ''));

            } else {

                const passwordCallback = step.value.getCallbackOfType('PasswordCallback');
                passwordCallback.setPassword(secret.replace(/\s/g, ''));

            }

            nextStep(step.value);
        };

        //for activation code
        const submitTextInput = (input) => {

            const TextInputCallback = step.value.getCallbackOfType('TextInputCallback');
            TextInputCallback.setInput(input);

            nextStep(step.value);
        };

        //for username-password
        const choiceSelected = (choice) => {

            const choiceCallback = step.value.getCallbackOfType('ChoiceCallback');
            choiceCallback.setChoiceValue(choice);
            nextStep(step.value);
        };
        //for IDP selector
        const getProviders = (step) => {

            const cb = step.getCallbackOfType('SelectIdPCallback')
            const providers = cb.getProviders();
            return providers;

        };

        const selectIdp = (prov) => {

            const cb = step.value.getCallbackOfType('SelectIdPCallback');
            cb.setProvider(prov.provider);
            store.modifySubStage(prov.provider);
            nextStep(step.value);
        };

        const redirectEid = () => {

            FRAuth.redirect(step.value);
        };


        //get to next step
        const nextStep = async (currentStep) => {
            await FRAuth.next(currentStep).then(handleStep).catch(handleFatalError);

        };

        const handleStep = async (receivedStep) => {

            switch (receivedStep.type) {
                case 'LoginSuccess': {
                    const url = receivedStep.getSuccessUrl();
                    window.location.href = url;
                    break;
                }

                case 'LoginFailure': {
                    const detail = receivedStep.getDetail();
                    if (detail) {
                        const errorCode = detail.errorCode;
                        if (errorCode === "110") {
                            router.push('/timeout/refused');
                            return;
                        } else {
                            const url = detail.failureUrl;
                            if (url) {
                                window.location.href = url;
                                return;
                            } else {
                                window.location.href = localStorage.getItem('url');
                                return;
                            }
                        }

                    } else {

                        window.location.href = `/error/ui?source=FASUI&error=Authentication Failed&error_description=Authentication has failed with no failure URL or error code.&error_code=500&trace_id=${store.uuidHeader}`;

                    }

                    break;
                }

                default: {

                    step.value = receivedStep;
                    stage.value = getStage(receivedStep);
                    store.modifyStage(stage.value);
                    if ((store.subStage != 'eid' && store.subStage != 'reset_pwd_eid') && stage.value === 'redirect') {

                        handleRedirectCallback(receivedStep);
                    }

                }
            }
        };

        const handleFatalError = (err) => {
            if(!store.uuidHeader){
                store.modifyUuidHeader(uuidv4());
            }
            window.location.href = `/error/ui?source=FASUI&error=${err.name}&error_description=${err.message.replace('Error:', '')}&trace_id=${store.uuidHeader}`;
        };

        const getCurrentQueryString = () => {

            const queryString = window.location.search;
            return queryString.slice(1);
        };

        //Other utils functions
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        };

        onMounted(async () => {

            const paramString = getCurrentQueryString();
            const params = new URLSearchParams(paramString);

            if (window.location.href.includes('#logout')) {
                router.push('/logout');
                return;
            }

            if (params.get('error') && params.get('state')) {

                router.push('/partner/refused');
                return;

            }

            if (params.get('goto')) {

                goStore.modifyGoTo(params.get('goto'));

            }

            if (params.get('state') || params.get('code') || params.get('scope') || params.get('form_post_entry') || params.get('responsekey')) {

                rstep = await FRAuth.resume(window.location.href)
                    .catch(err => {
                        if(err.message.includes("original redirect")){
                            router.push('/redirect-information/refused');
                            return;
                        } else {
                            handleFatalError(err);
                        }
                    });

            } else {


                // check Customer requested locale
                var hasRequestedLocale = false;
                if (params.get('locale')) {

                    let localeInParams = params.get('locale');

                    if (locales.includes(localeInParams)) {

                        i18n.global.locale.value = params.get('locale');
                        sessionStorage.setItem('lang', i18n.global.locale.value);
                        hasRequestedLocale = true;

                    }

                } else if (getCookie("fas2-saml-locale")) {

                    if (locales.includes(getCookie("fas2-saml-locale"))) {

                        i18n.global.locale.value = getCookie("fas2-saml-locale");
                        sessionStorage.setItem('lang', i18n.global.locale.value);

                        hasRequestedLocale = true;
                    }
                }


                // check previous fasui selected locale
                let lang = sessionStorage.getItem('lang');
                if (lang) {
                    hasRequestedLocale = true;
                }

                // if none of the above lookup
                // browser locale
                if (!hasRequestedLocale) {
                    // guess locale from browser
                    try {
                        var browserLang = navigator.language;
                        var formattedLocale = browserLang.split('-')[0].toLowerCase();
                        if (locales.includes(formattedLocale)) {

                            i18n.global.locale.value = formattedLocale;
                            sessionStorage.setItem('lang', i18n.global.locale.value);
                            hasRequestedLocale = true;
                        }
                    } catch (e) {
                        // ingore
                        console.log("Error invalid locale");
                    }
                    
                }

                // else defailt nl

                localStorage.setItem('url', window.location.href);
                rstep = await FRAuth.next()
                    .catch(err => {
                        handleFatalError(err);
                    });
            }


            handleStep(rstep);

        });

        watch(providers, (newProviders) => {

            let areAlltwoLetters = true;
            newProviders.forEach(prov => {

                if (prov.provider.length !== 2) {

                    areAlltwoLetters = false;

                }

            });
            if (areAlltwoLetters) {
                isEidasProviders.value = true;
            } else {
                isEidasProviders.value = false;
            }
        });

        return {
            step, stage, store, providers, isEidasProviders, prompt, choices, textOutputText, textOutputType, isContextSelector, selectIdp, submitCredentials, nextStep, submitSecret, submitTextInput, redirectEid, choiceSelected, metadata,
        };
    }
}

</script>
<style scoped>
.spinner-icon {
    display: inline-block;
    margin-left: 15%;
    margin-right: 10%;
    margin-bottom: 5%;
    margin-top: 5%;
    vertical-align: middle;
    border: 4px solid #871642;
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
