<script setup>
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
document.title = t('common.page.title');

const globalElement = document.getElementById('globalHtmlElement');
globalElement.setAttribute('lang', locale.value);

watch(
    () => locale.value,
    () => {
        document.title = t('common.page.title');
        globalElement.setAttribute('lang', locale.value);
    }
);

</script>
