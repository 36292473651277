<template>
    <div class="csam-cookie-bar csam-cookie-bar-noFixed" style="text-align: left;">
        <div class="cookiebar cookiebar-inverted container-fluid csam-cookie-bar csam-cookie-bar-noFixed">
            <div class="cookiebar-container container">
                <div class="cookiebar-text">
                    <div class="row">
                        <div class="col-lg-8 col-md-8 col-sm-12">
                            <p class="l-condensed">{{ $t('common.cookie.consent.text') }}</p>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12 cookie-accent">
                            <ul class="list-unstyled l-condensed">
                                <li><i class="fa fa-check margin-right-8" aria-hidden="true"> </i><a
                                        @click="cookiesAccepted" id="accept-cookies-button" role="button">{{
                                            $t('common.cookie.consent.confirm') }}</a></li>
                                <li><i class="fa fa-info-circle margin-right-8" aria-hidden="true"> </i><a
                                        @click="toggleShowInfo" id="toggle-cookie-information-button" role="button"
                                        class="toggleCookieConsent"><span>{{
                                            hideOrShowMessage }}</span></a></li>
                            </ul>
                        </div>
                        <div  v-show="isShowInformation" class="collapse-content" style="text-align: left; position: relative; margin-top: 10px" v-html="$t('common.cookie.consent.more_info.text')">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { ref, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

export default {

    name: 'CookieBar',
    emits: ['cookiesAccepted'],
    setup(_, { emit }) {

        const isShowInformation = ref(false);
        const hideOrShowMessage = ref('');

        //get Cookie and check value

        //i18N
        const { t, locale } = useI18n();

        const adaptHideOrShowMessage = () => {
            hideOrShowMessage.value = isShowInformation.value ? t('common.cookie.consent.read_less') : t('common.cookie.consent.read_more');

        }

        const toggleShowInfo = () => {
            isShowInformation.value = !isShowInformation.value;
            adaptHideOrShowMessage();

        }

        // Create a function to set the cookie
        const setCookie = (name, value, days) => {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

            const expires = `expires=${date.toUTCString()}`;
            const cookieString = `${name}=${value}; ${expires}; path=/`;

            document.cookie = cookieString;
        }

        const cookiesAccepted = () => {
            setCookie('user-accepted-cookie-disclaimer', 'true', 365);
            emit('cookiesAccepted');

        }

        onMounted(() => {

            adaptHideOrShowMessage();

        });

        // Watch for changes in the current locale
        watch(
            () => locale.value,
            () => { adaptHideOrShowMessage(); }
        );



        return { isShowInformation, hideOrShowMessage, toggleShowInfo, cookiesAccepted }

    }

}
</script>
<style>

.collapse-content{
    padding: 30px 15px;
    box-sizing: border-box;

}
    
</style>