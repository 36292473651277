export default {
  "citizentokenservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden mit Sicherungscode mit Token"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halten Sie Ihre persönliche Karte aus Papier (Token) griffbereit."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den Sicherheitscode <0>Nummer var:tokenRequested</0> Ihrer Karte ein."])}
      },
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitscode"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf <strong>Weiter</strong>"])}
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung! Sie können kein neues Token mehr aktivieren. Bürger, die bereits ein aktives Token haben, können dies jedoch noch weiterhin zur Anmeldung in den Online-Diensten verwenden."])}
    }
  },
  "commercialnoidservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden mit einem kommerziellen Zertifikat"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf <strong>Anmelden</strong>."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie dazu aufgefordert werden, wählen Sie das kommerzielle Zertifikat aus, das Sie verwenden möchten."])}
      },
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])}
    },
    "error": {
      "verification": {
        "step": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen Sie alle offenen Fenster Ihres Browsers."])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starten Sie Ihren Browser und versuchen Sie, sich erneut anzumelden."])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie, ob Sie das richtige Zertifikat ausgewählt haben."])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr kommerzielles Zertifikat konnte nicht verifiziert werden."])},
        "isabel": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Wenn Sie sich mit einem Isabel-Zertifikat anmelden möchten, müssen Sie über die aktuelle Version der Isabel-„Middleware” verfügen. Besuchen Sie dazu die <0>Isabel-Website</90>, wählen Sie 'Isabel-Sicherheitskomponenten' aus und laden Sie die letzte Version herunter.)"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.isabel.eu/en/forbusiness/support6/isabel6/installation/download.html"])}
        },
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie Fragen zur aAnmeldung mit einem kommerziellen Zertifikat haben, finden Sie schnell eine Antwort in der Rubrik <0>FAQ</0>."])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["http://5244.stg.fedict.be/de/faq/160"])}
      }
    }
  },
  "commercialservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden mit einem kommerziellen Zertifikat"])}
  },
  "common": {
    "page": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])}
    },
    "login": {
      "username": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies sind der Benutzername und das Passwort aus „Meine digitalen Schlüssel”."])}
      },
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
      "choose_other_digital_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen anderen digitalen Schlüssel"])},
      "button": {
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
        "test_eid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID testen"])},
        "test_result": {
          "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID fehlgeschlagen!"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID OK!"])}
        }
      }
    },
    "sidebar": {
      "no_eid": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie keine eID?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" wie Sie die Online-Behördendienste ohne eID nutzen können."])},
        "link": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be/de/identifizierung-ohne-eid"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesen Sie hier"])}
        }
      },
      "activate_other_key": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie einen anderen Schlüssel aktivieren?"])},
        "link": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melden Sie sich <0>hier</0> an, um Ihre digitalen Schlüssel zu erstellen oder zu bearbeiten."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=de"])}
        }
      }
    },
    "help": {
      "need_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe nötig?"])},
      "unable_to_find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht gefunden, was Sie gesucht haben?"])},
      "read-our": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Lesen Sie unsere "])},
      "quickly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und finden Sie schnell eine Antwort auf Ihre Frage."])},
      "link": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq-list"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesen Sie unsere <1>Hilfeseite</1> und finden Sie schnell eine Antwort auf Ihre Frage."])},
        "help_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfeseite"])}
      }
    },
    "cookie": {
      "consent": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Verwendung unserer Anwendungen werden Cookies in Übereinstimmung mit unserer Datenschutzpolitik auf Ihrem Computer gespeichert."])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich akzeptiere Cookies"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen"])},
        "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen ausblenden"])},
        "more_info": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Für die Anwendung „Meine digitalen Schlüssel” verwendet FOD Beleid en Ondersteuning – DG Digitale Transformation Cookies zur Leistungsoptimierung der Website, funktionelle Cookies für die Benutzerfreundlichkeit und vorübergehende Sitzungscookies, die für die Authentifizierung während der Sitzung erforderlich sind. Sie können die Cookies ablehnen, aber dann werden einige Teile unserer Websites nicht oder nicht optimal funktionieren.</p><br/><p><strong>Unbedingt erforderliche Cookies</strong></p><p>Diese Cookies sind unentbehrlich, um Ihre Identität auf sichere Weise zu überprüfen und Ihnen auf Basis dieser Überprüfung Zugang zu den von Ihnen gewünschten Anwendungen zu gewähren.</p><br/><p><strong>Funktionelle Cookies</strong></p><p>Funktionelle Cookies sind Cookies, die das Funktionieren der Websites und die Benutzerfreundlichkeit verbessern. Fedict verwendet Cookies, um Ihre Sprachpräferenzen zu speichern.</p><br/><p><strong>Cookies für Website-Leistungen</strong></p><p>FOD Beleid en Ondersteuning – DG Digitale Transformation verwendet Cookies zur Lastverteilung. Diese werden bei Websites verwendet, die häufig besucht werden, und dienen zur Lastverteilung der Anfragen über mehrere, separate Netzwerke und Server.</p><br/><p>Sie können die Cookies mit Ihren Browsereinstellungen ablehnen. Auf der folgenden Website erfahren Sie, wie Sie Cookies für die meistverwendeten Browser ablehnen können: <a href=\"http://www.aboutcookies.org/\">http://www.aboutcookies.org/</a>. Sie können die Cookies jederzeit auf Ihrem Computer löschen.</p>"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["http://www.aboutcookies.org/"])}
        }
      }
    },
    "modal": {
      "warning": {
        "firefox": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installieren des eID-Add-Ons für Firefox"])},
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie Ihre eID über Firefox verwenden? Dann benötigen Sie das offizielle Add-On 'eID Belgium'. Wenn Sie es noch nicht installiert haben, klicken Sie auf 'Add-On für Firefox installieren'."])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie das Add-On bereits installiert? Klicken Sie auf 'Mit Ihrer eID anmelden'."])},
          "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis:"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Um sich mit Ihrer eID anzumelden, müssen Sie die eID-Software installieren. Wenn Sie sie noch nicht installiert haben, können Sie sie von der "])},
          "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID-Software-Website herunterladen."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.eid.base)/de"])},
          "button": {
            "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Ihrer eID anmelden"])},
            "addon": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add-On für Firefox installieren"])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.firefox_addons.base)/de/firefox/addon/belgium-eid/?src=search"])}
            }
          }
        }
      }
    },
    "alt_text": {
      "images": {
        "emailOtpStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitscode per E-Mail"])},
        "mobileAppStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitscode mittels mobiler Anwendung"])},
        "certificateStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommerziellen Zertifikat"])},
        "certificateStepNoId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommerziellen Zertifikat"])},
        "usernamePasswordStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername und Passwort"])},
        "smsStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitscode per SMS"])},
        "tokenStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherungscode mit Token"])}
      }
    },
    "greetings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit freundlichen Grüßen,<br/>Service Desk Digitale Transformatie"])},
    "faq": {
      "what_do_i_need_eid": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/123"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was benötige ich?"])}
      },
      "101": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/101"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was muss ich tun, wenn ich vermute, dass jemand meine digitalen Schlüssel missbraucht?"])}
      },
      "105": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/105"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe meinen PIN-Code vergessen"])}
      },
      "106": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/faq/106"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe keinen Kartenleser"])}
      },
      "107": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/107"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe keinen eID"])}
      },
      "109": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/109"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bekomme immer wieder die Meldung, dass mein Code nicht gültig ist."])}
      },
      "117": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/117"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Konto wurde gesperrt. Was muss ich tun?"])}
      },
      "118": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/118"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe meinen Benutzernamen und/oder mein Passwort vergessen. Was muss ich tun?"])}
      },
      "119": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/119"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte mich auf einem Tablet oder Smartphone anmelden"])}
      },
      "142": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/142"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was benötige ich?"])}
      },
      "143": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/143"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte mein Smartphone oder Tablet austauschen. Was geschieht jetzt mit der mobilen App?"])}
      },
      "145": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/145"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche mobilen App kann ich verwenden, um mich mit einem \"einmaligen Code mittels mobiler App\" anzumelden?"])}
      },
      "153": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/153"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was benötige ich?"])}
      },
      "156": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/156"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was benötige ich?"])}
      },
      "158": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/158"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ich bereits einen Benutzernamen und ein Passwort für die Anmeldung mittels Token, SMS oder mobiler App eingestellt habe, ist es dann noch nötig, um einen Benutzernamen und ein Passwort als gesonderten digitalen Schlüssel zu aktivieren?"])}
      },
      "170": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/170"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat die Behörde Einsicht in meine Daten, wenn ich digitale Schlüssel verwende?"])}
      },
      "172": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/172"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie sicher sind die verschiedenen digitalen Schlüssel?"])}
      },
      "174": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/174"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie melde ich mich bei behördlichen Online-Diensten sicher ab?"])}
      },
      "7258": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/de/eidas#7258"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist eIDAS?"])}
      },
      "7262": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/de/eidas#7262"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum muss ich mich bei eIDAS ausweisen?"])}
      },
      "7347": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/de/eidas#7347"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich kenne meine belgische Identifikationsnummer nicht."])}
      },
      "7269": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/de/eidas#7269"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werden meine Daten sicher und geschützt gespeichert?"])}
      },
      "7334": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/de/eidas#7334"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was brauche ich, um mich (erstmalig) bei eIDAS anzumelden?"])}
      },
      "7338": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/de/eidas#7338"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit welchen europäischen elektronischen Identitätsnachweisen kann ich mich anmelden?"])}
      },
      "7277": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/de/eidas#7277"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erhalte eine Fehlermeldung, die besagt, dass meine Identifikation nicht automatisch verarbeitet werden kann."])}
      },
      "list": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq-list"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Hilfefragen"])}
      },
      "what_do_i_need_sms": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/148#6396"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was benötige ich?"])}
      },
      "cost_sms": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/152#6395"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostet es etwas, um einen Sicherheitscode per SMS zu erhalten?"])}
      },
      "new_phone_sms": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/150#6753"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe eine neue Handynummer. Was geschieht nun mit dem Sicherheitscode per SMS?"])}
      },
      "sms_help1": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann ich mein Handy verwenden, um für eine andere Person eine SMS mit dem Sicherheitscode zu empfangen?"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/151#6394"])}
      },
      "lost_token": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/114#6831"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe mein Token verloren. Was muss ich tun?"])}
      },
      "commercial_certif": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein registriertes kommerzielles Zertifikat"])}
      },
      "156_login": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/156#6464"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was brauche ich, um mich anzumelden?"])}
      },
      "118_username_password": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/118#6443"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername und Passwort vergessen?"])}
      },
      "158_app_sms_token": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/158#6465"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereits einen Benutzernamen und ein Passwort über mobile App, SMS oder Token?"])}
      },
      "otc": {
        "activation_code_lost": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/ich-habe-meinen-aktivierungscode-verloren-was-muss-ich-tun#7129"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe meinen Aktivierungscode verloren. Was muss ich tun?"])}
        },
        "what_is_activation_code": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/was-ist-mein-aktivierungscode#7165"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist mein Aktivierungscode?"])}
        },
        "wrong_code_entered": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/ich-habe-dreimal-hintereinander-den-falschen-aktivierungscode-eingegeben-was-soll-ich-tun#7169"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe dreimal hintereinander den falschen Aktivierungscode eingegeben. Was muss ich tun?"])}
        }
      },
      "why_login_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sicherheitscode-e-mail#7309"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung mit der E-Mail-Adresse - warum?"])}
      },
      "new_email_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sicherheitscode-e-mail#7312"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe eine neue E-Mail-Adresse. Was geschieht mit dem Sicherheitscode per E-Mail?"])}
      },
      "get_started_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sicherheitscode-e-mail#7318"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte loslegen! Was brauche ich?"])}
      },
      "x_attempts_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sicherheitscode-e-mail#7321"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erhalte die Meldung, dass ich nur noch X Versuche habe."])}
      },
      "lapsed_code_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sicherheitscode-e-mail#7324"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erhalte die Meldung, dass mein Sicherheitscode nichtig ist. Was soll ich tun?"])}
      },
      "7185": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/de/faq/wie-kann-ich-einen-benutzernamen-und-ein-passwort-registrieren#7185"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbst einen Benutzernamen und Passwort registrieren?"])}
      },
      "7189": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/de/faq/ich-will-einen-benutzernamen-und-ein-passwort-registrieren-aber-meine-e-mail-adresse-ist-bereits#7189"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was, wenn meine E-Mail-Adresse schon verwendet wird?"])}
      },
      "7193": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/de/faq/ich-will-einen-benutzernamen-und-ein-passwort-registrieren-aber-mein-benutzername-ist-bereits#7193"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was, wenn mein Benutzername schon verwendet wird?"])}
      },
      "wp_confirmation_help_text_1": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Aktivierungsmail empfangen?"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/de/faq/ich-habe-meinen-aktivierungslink-nicht-erhalten-was-muss-ich-tun#7133"])}
      },
      "wp_confirmation_help_text_2": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum Benutzernamen und Passwort aktivieren?"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/de/faq/warum-muss-ich-meinen-benutzernamen-und-mein-passwort-aktivieren#7197"])}
      },
      "wp_confirmation_help_text_3": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist diese E-Mail nach 5 Tagen noch verwendbar?"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/de/faq/der-aktivierungslink-um-meinen-benutzernamen-und-mein-passwort-zu-aktivieren-ist-5-tage-lang#7181"])}
      }
    }
  },
  "eidasregistration": {
    "registration": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen!"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da Sie sich gerade erstmalig bei eIDAS registrieren, möchten wir Sie bitten, zunächst einige Daten zu ergänzen. In Fällen, in denen unsere Systeme nicht in der Lage sind, Ihre Identität automatisch festzustellen, können wir Sie anhand dieser Daten wiederfinden und uns ggf. mit Ihnen in Verbindung setzen."])}
    },
    "reregistration": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneuter Anmeldeversuch"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen zurück! Der letzte Anmeldeversuch war nicht erfolgreich, da Sie eventuell eine falsche belgische Identifizierungsnummer eingegeben haben. Bitte prüfen Sie daher Ihre Angaben und ändern Sie diese bei Bedarf. Klicken Sie auf „Weiter“, wenn Sie dies erledigt haben."])},
      "attempts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Versuch ", _interpolate(_list(0)), " von ", _interpolate(_list(1))])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
    },
    "form": {
      "nrn": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgische Identifizierungsnummer (Nationalregister- oder BIS-Nummer)"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die belgische Identifizierungsnummer ist Ihre eindeutige Identifizierungsnummer, mit der Sie bei den belgischen Behörden gemeldet sind. Wenn Sie im belgischen Nationalregister gemeldet sind, dann ist dies Ihre Nationalregisternummer. Wenn Sie nicht im belgischen Nationalregister gemeldet sind, ist Ihre BIS-Nummer Ihre Identifizierungsnummer. Weitere Infos finden Sie im Hilfe-Bereich am Schluss dieser Seite."])}
      },
      "nonrn": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe keine belgische nationale Identifikationsnummer."])},
        "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Achtung!</strong> Wenn Sie keine Identifikationsnummer eingeben, können wir Ihre Daten nicht automatisch verarbeiten. Die Bearbeitung Ihrer Registrierung dauert in diesem Fall länger, wodurch Sie sich nicht sofort im System anmelden können."])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adress"])},
      "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse bestätigen"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "phonePrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorwahl der Telefonnummer"])},
      "gender": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlecht"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])}
      },
      "birthcountry": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsland"])},
        "defaultOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein land"])}
      },
      "birthplace": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsort"])},
        "defaultOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein Geburtsort"])}
      },
      "eula": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich stimme <0>den allgemeinen Nutzungsbedingungen</0> sowie <1>den Datenschutzbestimmungen zu</1>."])},
        "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich stimme "])},
        "generalterms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["den allgemeinen Nutzungsbedingungen"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" sowie "])},
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["den Datenschutzbestimmungen zu"])},
        "terms": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.public.base)/eula/sma/de/License%20agreement%20FEDICT%20IAM.pdf"])}
        },
        "privacy": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.bosa.base)/sites/default/files/content/FAS/privacyverklaring_csam_1.0_de.pdf"])}
        }
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versenden"])}
    }
  },
  "eidasservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden mit einem von der EU anerkannten elektronischen Identitätsnachweis"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie das Land in dem der digitale Schlüssel, mit dem Sie sich anmelden möchten, ausgestellt wurde."])},
    "countries": {
      "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Österreich"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgien"])},
      "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarien"])},
      "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zypern"])},
      "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tschechische Republik"])},
      "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutschland"])},
      "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dänemark"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spainen"])},
      "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estland"])},
      "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finnland"])},
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frankreich"])},
      "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Griechenland"])},
      "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kroatien"])},
      "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungarn"])},
      "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irland"])},
      "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italien"])},
      "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
      "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litauen"])},
      "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Großherzogtum Luxemburg"])},
      "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettland"])},
      "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
      "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederlande"])},
      "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polen"])},
      "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
      "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rümanien"])},
      "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slowakei"])},
      "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slowenien"])},
      "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schweden"])},
      "UK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinigtes Königreich"])},
      "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eIDAS-Node Validator"])},
      "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegen"])}
    }
  },
  "eidpasswordresetservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername und Passwort zurücksetzen"])},
    "reset_password": {
      "step1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Ihren Schlüssel aus"])},
        "eid": {
          "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen Sie Ihren eID-Kartenleser an Ihren Computer an."])},
          "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stecken Sie Ihren elektronischen Personalausweis (eID), Ihre Ausländerkarte oder Ihre Kids-ID in den Kartenleser."])},
          "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf <strong>'Weiter'</strong>, wählen Sie Ihr Zertifikat aus und geben Sie den PIN-Code Ihres Personalausweises ein, wenn Sie dazu aufgefordert werden."])}
        }
      },
      "step2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen Sie Ihre Identität"])}
      },
      "step3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])}
      }
    }
  },
  "eidservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden mit eID-Kartenleser"])},
    "alt-text": {
      "connected-eID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID-Kartenleser"])}
    },
    "login": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen Sie Ihren eID-Kartenleser an Ihren Computer an"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stecken Sie Ihren elektronischen Personalausweis (eID), Ihre Ausländerkarte oder Ihre Kids-ID in den Kartenleser"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf <strong>'Anmelden'</strong>, wählen Sie Ihr Zertifikat aus und geben Sie den PIN-Code Ihres Personalausweises ein, wenn Sie dazu aufgefordert werden"])},
      "button": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])}
      },
      "choose_other_digital_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen anderen digitalen Schlüssel"])}
    },
    "video": {
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://idp.iamfas.belgium.be/fasui/images/videos/Aanmelden-eID_kaartlezer_DE.png"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/ovFi24ai4n4?autoplay=1"])}
    },
    "error": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung fehlgeschlagen"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind nicht angemeldet. Überprüfen Sie die häufigsten Ursachen hierfür:"])},
      "image_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warndreieck"])},
      "cardreader": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie die eID richtig in das Kartenlesegerät eingesteckt?"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen Sie sicher, dass Sie die eID richtig in das Kartenlesegerät eingesteckt haben."])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen Sie alle Browserfenster und schließen Sie den Browser selbst."])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen Sie sicher, dass das Kartenlesegerät richtig angeschlossen ist."])},
        "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stecken Sie die eID in das Kartenlesegerät ein."])},
        "step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen Sie den Browser und versuchen Sie erneut, sich anzumelden."])}
      },
      "software": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wurde die eID-Software erst kürzlich installiert?"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie kürzlich die eID-Software (erneut) installiert?"])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starten Sie den Computer neu."])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie erneut, sich anzumelden."])},
        "nosoftware": {
          "preface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügen Sie über eine ältere Version der eID-Software oder über überhaupt keine Version?"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.eid.base)/de"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie sich die eID-Software herunter."])}
        }
      },
      "help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe, mir wird immer die gleiche Seite angezeigt."])},
        "eid_help_pages": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://eid.belgium.be/de/frage-und-antwort"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziehen Sie unsere eID-Hilfeseite zu Rate."])}
        },
        "contact_form_link": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be/de/kontakt"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns"])}
        }
      }
    }
  },
  "error": {
    "general": {
      "read_faq": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq-list"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollte sich dieser Fehler wiederholen, oder sollten Sie Fragen haben, so lesen Sie sich bitte zuerst unsere <0>FAQ</0> (Liste der häufig gestellten Fragen) durch, die Sie über den Link auf dieser Seite auf die rechte Seite erreichen."])}
      },
      "contact_us": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/contact"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Können Sie auf Ihre Frage keine Antwort finden? Bitte kontaktieren Sie uns über unser <0>Kontaktformular</0>"])}
      }
    },
    "service_unavailable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider liegt innerhalb unseres Dienstleistungsangebots derzeit eine Störung vor."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus diesem Grund steht die Suche im Nationalregister derzeit nicht zur Verfügung und ist eine Anmeldung nicht möglich.<br/>Die Behebung der Störung ist bereits in Bearbeitung. Bitte versuchen Sie später erneut, sich anzumelden."])}
    },
    "request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["request-id: "])},
    "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timestamp: "])},
    "server_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["server-id: "])},
    "correlation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correlation-id: "])},
    "error": {
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen"])},
      "unexpected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unerwarteter Fehler"])}
    },
    "page_not_found": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite nicht gefunden"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Seite, die Sie suchen, existiert nicht."])}
    },
    "validation": {
      "fas": {
        "error": {
          "key": {
            "failed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Versuch ", _interpolate(_list(0)), " ist fehlgeschlagen. Sie haben noch ", _interpolate(_list(1)), " Versuch(e), bevor diese Anmeldemöglichkeit blockiert wird."])},
            "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eines Ihrer Anmeldeverfahren wurde blockiert. Bitte versuchen Sie es in einer Stunde noch einmal."])},
            "user": {
              "not_found": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie verfügen nicht über den entsprechenden digitalen Schlüssel (Benutzername und Passwort) oder dieser ist eventuell abgelaufen. Melden Sie sich mit einem anderen Verfahren an oder aktivieren Sie dieses Anmeldeverfahren in "])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine digitalen Schlüssel"])}
              }
            }
          },
          "mailotp": {
            "nocredential": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie verfügen nicht über diese Anmeldemöglichkeit. Melden Sie sich mit einem anderen Verfahren an oder aktivieren Sie dieses Anmeldeverfahren in <0>Meine digitalen Schlüssel</0>."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=de"])}
            },
            "gateway_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Dienst ist leider vorübergehend nicht verfügbar. Versuchen Sie es später noch einmal."])}
          },
          "otp": {
            "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr einmaliges Passwort ist verfallen. Beantragen Sie ein neues einmaliges Passwort und versuchen Sie es erneut."])},
            "missing": {
              "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie ein einmaliges Passwort ein."])}
            },
            "no_phone": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie verfügen nicht über diese Anmeldemöglichkeit. Melden Sie sich mit einem anderen Verfahren an oder aktivieren Sie dieses Anmeldeverfahren in <0>Meine digitalen Schlüssel</0>."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=de"])}
            }
          },
          "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout: Sie waren zu lange inaktiv, bitte gehen Sie zurück zur Auswahlseite für die Authentifizierungsmittel."])},
          "page": {
            "not": {
              "found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Seite nicht gefunden</strong><br>Die Seite, die Sie suchen, existiert nicht."])}
            }
          },
          "cert": {
            "not_trusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Es gibt ein Problem mit Ihrem Zertifikat.</strong> <br/><br/>Wählen Sie ein anderes Anmeldeverfahren aus oder schließen Sie alle offenen Fenster Ihres Browsers und versuchen Sie es noch einmal."])},
            "x509": {
              "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Es gibt ein Problem mit Ihrem Zertifikat.</strong> <br/><br/>Wählen Sie ein anderes Anmeldeverfahren aus oder schließen Sie alle offenen Fenster Ihres Browsers und versuchen Sie es noch einmal."])}
            },
            "ocsp_crl": {
              "failed": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Zertifikat konnte nicht validiert werden. Versuchen Sie es später noch einmal. Wenn das Problem weiterhin besteht, überprüfen Sie die Gültigkeit und die Konfiguration Ihres persönlichen und/oder kommerziellen Zertifikats. <br/>Weitere Informationen über Ihr persönliches Zertifikat (eID) finden Sie in der Rubrik <0>FAQ</0>.<br/>Weitere Informationen über kommerzielle Zertifikate finden Sie in der Rubrik <1>FAQ</1>."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/management/identity/eid"])},
                "url2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/faq/53"])}
              }
            }
          },
          "login": {
            "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anmeldung ist gescheitert."])}
          },
          "otc": {
            "activation": {
              "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es tut uns leid, Ihre Aktivierung wurde blockiert."])},
              "failed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["<strong>Falscher Aktivierungscode.</strong> Geben Sie Ihren korrekten persönlichen Aktivierungscode ein. <strong>Sie haben noch ", _interpolate(_list(0)), "</strong> Versuche. Danach wird die Aktivierung zu Ihrer Sicherheit blockiert."])},
              "too": {
                "many": {
                  "tries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben <strong>dreimal nacheinander einen falschen Aktivierungscode eingegeben</strong>. Deshalb haben wir zu Ihrer Sicherheit Ihre Aktivierung blockiert."])}
                }
              },
              "request": {
                "new": {
                  "activation_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Beantragen Sie einen neuen Aktivierungscode und Aktivierungslink</strong> beim Rathaus, der Registrierungsstelle, der Botschaft oder dem Service Desk Digitale Transformatie."])}
                }
              }
            }
          },
          "partner": {
            "login": {
              "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Die Anmeldung ist fehlgeschlagen.</strong><br/><br/>Ihre Partnerdaten konnten nicht abgerufen werden, weil keine Verbindung mit dem Partner hergestellt werden kann."])}
            }
          },
          "password": {
            "missing": {
              "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie ein Passwort ein."])},
              "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie einen Benutzernamen ein."])}
            }
          },
          "sms": {
            "gateway": {
              "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Dienst ist leider vorübergehend nicht verfügbar. Versuchen Sie es später noch einmal."])}
            }
          },
          "token": {
            "user": {
              "not_found": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie verfügen nicht über den entsprechenden digitalen Schlüssel (Sicherheitscode mit Token) oder dieser ist eventuell abgelaufen. Melden Sie sich mit einem anderen Schlüssel an oder aktivieren Sie diesen digitalen Schlüssel in <0>CSAM - Meine digitalen Schlüssel</0>."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=de"])}
              }
            }
          },
          "totp": {
            "invalid": {
              "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anmeldung ist gescheitert."])}
            },
            "missing": {
              "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie einen einmaligen Code ein."])}
            },
            "no": {
              "device": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie verfügen nicht über diese Anmeldemöglichkeit. Melden Sie sich mit einem anderen Verfahren an oder aktivieren Sie dieses Anmeldeverfahren in <0>Meine digitalen Schlüssel</0>."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=de"])}
              }
            }
          },
          "nrn": {
            "nonrn": {
              "nrntext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Nationalregisternummer wurde von uns während des Anmeldevorgangs auf einen Online Dienst der Behörden nicht empfangen.\nWahrscheinlich ist Ihre Nationalregisternummer noch nicht in Ihrem Itsme® Konto gespeichert."])},
              "moreinfo": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr Informationen über Itsme® können Sie auf <0>https://www.itsme.be/de</0> finden."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.itsme.be/de"])}
              },
              "errortoken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error token:"])},
              "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timestamp:"])},
              "serverid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["serverid:"])}
            }
          },
          "identity": {
            "deceased": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Profil ist nicht mehr aktiv. Bitte kontaktieren Sie den <0>Service Desk</0> für weitere Informationen."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be/de/kontakt-formular"])}
            }
          }
        }
      },
      "create": {
        "firstname": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie einen korrekten Namen aus mindestens 2 Zeichen ein. Verwenden Sie nur Buchstaben aus dem Alphabet, Leerzeichen und einen Strich ('-')."])}
        },
        "lastname": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie einen korrekten Namen aus mindestens 2 Zeichen ein. Verwenden Sie nur Buchstaben aus dem Alphabet, Leerzeichen und einen Strich ('-')."])}
        },
        "username": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie einen Benutzernamen ein."])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Benutzername muss 6 bis 64 Zeichen lang sein und mindestens 1 Buchstaben enthalten. Bitte verwenden Sie ausschließlich Buchstaben, Zahlen und/oder diese Zeichen"])},
          "notunique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Benutzername existiert bereits. Wählen Sie einen anderen Benutzernamen."])},
          "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Benutzername existiert bereits. Wählen Sie einen anderen Benutzernamen."])}
        },
        "password": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie ein Passwort ein."])},
          "invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ihr Passwort muss ", _interpolate(_list(1)), " bis ", _interpolate(_list(0)), " Zeichen lang sein und mindestens einen Buchstaben, eine Ziffer und eines der folgenden Zeichen enthalten"])},
          "unchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben Ihr derzeitiges Passwort eingegeben. Bitten geben Sie ein anderes Passwort ein."])},
          "repeat": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bestätigen Sie Ihr Passwort."])},
            "notequal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die eingegebenen Passwörter stimmen nicht überein."])}
          }
        },
        "email": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine E-Mail-Adresse ein."])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie eine gültige E-Mail-Adresse ein."])},
          "notunique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese E-Mail-Adresse wird bereits verwendet. Bitte geben Sie eine andere E-Mail-Adresse ein."])},
          "not": {
            "unique": {
              "weak": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese E-Mail-Adresse existiert bereits. Geben Sie eine andere E-Mail-Adresse ein oder melden Sie sich mit dem Benutzernamen und Passwort an, die mit dieser E-Mail-Adresse verknüpft sind. Haben Sie Ihr <0>Passwort vergessen</0>?"])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.base)/public/resetpassword/chooseCredential?language=de"])}
              },
              "normal": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese E-Mail-Adresse existiert bereits. Geben Sie eine andere E-Mail-Adresse ein oder melden Sie sich mit dem Benutzernamen und Passwort an, die mit dieser E-Mail-Adresse verknüpft sind. Haben Sie Ihr <0>Passwort vergessen</0>?"])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.base)/public/resetpassword/chooseCredential?language=de"])}
              }
            }
          },
          "confirm": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bestätigen Sie Ihre E-Mail-Adresse."])},
            "notequal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die eingegebenen E-Mail-Adressen stimmen nicht überein."])}
          }
        },
        "nrn": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie eine gültige belgische Identifizierungsnummer ein."])}
        },
        "phone": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine korrekte Telefonnummer. Die Nummer muss 8- bis 9-stellig für eine Inlandsnummer bzw. 2- bis 14-stellig für eine internationale Nummer sein. Verwenden Sie nur Ziffern und keine Punkte bzw. keinen Strich ('-')."])}
        },
        "gender": {
          "not": {
            "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte treffen Sie eine Auswahl."])}
          }
        },
        "country": {
          "not": {
            "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihren Geburtsland ein."])}
          }
        },
        "city": {
          "not": {
            "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihren Geburtsort ein."])}
          }
        },
        "consent": {
          "notchecked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte stimmen Sie den allgemeinen Nutzungsbedingungen sowie den Datenschutzbestimmungen zu."])}
        }
      }
    },
    "lifecycle": {
      "error": {
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben Ihr derzeitiges Passwort eingegeben. Bitten geben Sie ein anderes Passwort ein."])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Benutzername existiert bereits. Wählen Sie einen anderen Benutzernamen. "])}
      }
    },
    "fas": {
      "error": {
        "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anmeldung ist gescheitert."])},
        "partner": {
          "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Authentifizierung, die über einen unserer autorisierten Partner erfolgt ist, wurde unterbrochen. Bitte schließen Sie Ihren Browser vollständig und versuchen Sie es erneut."])},
          "error": {
            "multiple": {
              "browsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie auf Ihrem Smartphone mehrere Browser verwenden, melden Sie sich bitte nur mit dem Standard-Browser an. In den Einstellungen Ihres Smartphones können Sie den gewünschten Standard-Browser festlegen."])}
            },
            "incognito": {
              "browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzen Sie den Browser im Inkognito-Modus oder rufen Sie die Website in einem sogenannten privaten Fenster auf? Dann rufen Sie die Website bitte in einem normalen Fenster auf bzw. schalten Sie den Inkognito-Modus aus und melden Sie sich erneut an."])}
            },
            "contact": {
              "helpdesk": {
                "text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Trotz der hier genannten Lösungsvorschläge ist die Anmeldung weiterhin nicht möglich? Dann wenden Sie sich an das Service Desk Digitale Transformatie"]), _normalize(["Service Desk Digitale Transformation. Die Kontaktdaten erhalten Sie <0>hier.</0>"])])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/kontakt-formular"])}
              }
            }
          }
        },
        "consent": {
          "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die Zustimmung verweigert, Nachrichten von den angeschlossenen Behörden elektronisch zu empfangen. Ohne diese Zustimmung können Sie der service nicht benutzen."])}
        },
        "redirect": {
          "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie bei der Authentifizierung eine Fehlermeldung erhalten, überprüfen Sie, ob Sie den von Ihnen verwendeten mobilen Browser als Standardbrowser eingestellt haben (vermeiden Sie Samsung Internet) und ob Sie nicht den privaten Modus verwenden."])},
          "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn das Problem weiterhin besteht, raten wir Ihnen, die Website Ihrer Anwendung zu verwenden."])}
        }
      }
    },
    "eidas": {
      "registration": {
        "pending": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Identität konnte nicht automatisch festgestellt werden."])},
          "mail": {
            "sent": {
              "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Wir haben eine E-Mail an <strong>", _interpolate(_list(0)), "</strong> gesendet und werden Sie kontaktieren, sobald Ihre Identitätsfeststellung erfolgt ist Weitere Informationen finden Sie auf unserer <0>Hilfe-Seite</0>."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/eidas"])}
            }
          }
        }
      },
      "error": {
        "page": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung fehlgeschlagen"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind nicht angemeldet. Überprüfen Sie die häufigsten Ursachen hierfür:"])},
          "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anmeldung über eIDAS ist in folgenden Situationen nicht möglich:"])},
          "name": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung mit eID oder Aufenthaltstitel mit einem selbst angegebenen Namen:"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie einen selbst angegebenen Namen ohne entsprechenden Nachweis führen, können Sie sich nicht über eIDAS anmelden, da Sie Ihre Identität den belgischen Behörden gegenüber nicht ausreichend belegen konnten."])}
          },
          "enterprise": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung bei privatwirtschaftlichen Unternehmen:"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir unterstützen ausschließlich die Anmeldung bei den Online-Diensten der belgischen Behörden."])}
          },
          "contact": {
            "helpdesk": {
              "text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Die hier aufgeführten Antworten lösen Ihr Problem nicht? Dann wenden Sie sich an das <0>Service Desk Digitale Transformatie"]), _normalize(["Service Desk Digitale Transformation</0>."])])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de/kontakt-formular"])}
            }
          }
        },
        "nomatch": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider konnte Ihre Registrierung nicht automatisch verarbeitet werden. Die von Ihnen eingegebenen persönlichen Angaben stimmen nicht mit den Angaben überein, die für die eingegebene belgische Identifizierungsnummer hinterlegt sind."])},
          "text": {
            "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Wir haben eine E-Mail an <strong>", _interpolate(_list(0)), "</strong> gesendet und werden Sie kontaktieren, sobald Ihre Identitätsfeststellung erfolgt ist Weitere Informationen finden Sie auf unserer <0>Hilfe-Seite</0>."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.fedict.belgium.be/de/eidas#7285"])}
          }
        },
        "maxattempts": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider konnte Ihre Registrierung nicht automatisch verarbeitet werden, da maximale Anzahl an Registrierungsversuchen "])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Wir haben eine E-Mail mit den Details Ihrer Registrierung an die E-Mail-Adresse ", _interpolate(_list(0)), " gesendet. Bitte wenden Sie sich an das Helpdesk der Anwendung, die Sie nutzen wollen."])}
        },
        "duplicates": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider konnte Ihre Registrierung nicht automatisch verarbeitet werden. Im Nationalregister ist bereits eine belgische Identifizierungsnummer mit demselben Namen und Geburtsdatum vorhanden."])},
          "text": {
            "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Wir haben eine E-Mail an <strong>", _interpolate(_list(0)), "</strong> gesendet und werden Sie kontaktieren, sobald Ihre Identitätsfeststellung erfolgt ist Weitere Informationen finden Sie auf unserer <0>Hilfe-Seite</0>."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.fedict.belgium.be/de/eidas#7288"])}
          }
        },
        "notfound": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider konnte Ihre Registrierung nicht automatisch verarbeitet werden. Die eingegebene belgische Identifizierungsnummer existiert nicht."])},
          "text": {
            "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Wir haben eine E-Mail an <strong>", _interpolate(_list(0)), "</strong> gesendet und werden Sie kontaktieren, sobald Ihre Identitätsfeststellung erfolgt ist Weitere Informationen finden Sie auf unserer <0>Hilfe-Seite</0>."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.fedict.belgium.be/de/eidas#7281"])}
          }
        },
        "unavailable": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider liegt innerhalb unseres Dienstleistungsangebots derzeit eine Störung vor."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider liegt innerhalb unseres Dienstleistungsangebots derzeit eine Störung vor.<br/>Die Behebung der Störung ist bereits in Bearbeitung. Bitte versuchen Sie später erneut, sich anzumelden."])}
        },
        "invalididentifier": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider ist eine Anmeldung nicht möglich, da Ihre belgische Identifizierungsnummer nicht mehr gültig ist."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wenden Sie sich an das Helpdesk der Anwendung, die Sie nutzen wollen."])},
          "bosahelpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wenden Sie sich für weitere Informationen zum Helpdesk von BOSA."])}
        },
        "nobiscreation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Identität konnte nicht automatisch festgestellt werden."])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Wir haben eine E-Mail mit den Details Ihrer Registrierung an die E-Mail-Adresse <strong>", _interpolate(_list(0)), "</strong> gesendet. Bitte wenden Sie sich an das Helpdesk der Anwendung, die Sie nutzen wollen."])}
        }
      }
    }
  },
  "footer": {
    "nav": {
      "copy_right": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.csam.base)/de/index.html"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAM"])}
      },
      "terms_of_use": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.public.base)/eula/sma/de/License%20agreement%20FEDICT%20IAM.pdf"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])}
      },
      "privacy": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.bosa.base)/sites/default/files/content/FAS/privacyverklaring_csam_1.0_de.pdf"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])}
      },
      "accessibility": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugänglichkeit"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/accessibility/fasui/de/Erklärung%20zur%20Zugänglichkeit%20DE%20FASUI.pdf"])}
      },
      "powered_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by FOD BOSA - DG Digitale Transformation"])}
    }
  },
  "foreignerservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihren digitalen Schlüssel aktivieren"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren persönlichen Aktivierungscode ein."])},
    "activation_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivierungscode"])},
    "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivierungscode eingeben"])},
    "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Ihren digitalen Schlüssel"])},
    "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren Sie Ihren digitalen Schlüssel"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Code besteht aus 20 Zeichen und Sie haben ihn persönlich empfangen."])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte erneut versuchen"])}
  },
  "header": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden bei den Online-Behörden"])},
    "eidas": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung als EU-Bürger"])}
    },
    "sma": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine digitalen Schlüssel"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden bei den Online-Behörden"])}
    },
    "weakprofile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung Benutzername und Passwort"])}
    }
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Ihren digitalen Schlüssel aus, um sich anzumelden"])},
    "need_help": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe nötig?"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/de"])}
    },
    "group_header": {
      "eid_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>eID</strong> oder <strong>digitaler Schlüssel von anerkannten Partnern</strong>"])},
      "twofactor_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitaler Schlüssel mit <strong>Sicherheitscode</strong> und <strong>Benutzername + Passwort</strong>"])},
      "id_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitale Schlüssel mit <strong>Benutzername + Passwort</strong>"])},
      "european_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europäische Authentifizierung"])},
      "external_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitale(r) Schlüssel über <strong>externe Partner</strong>"])}
    },
    "digital_key": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
      "eidservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit eID-Kartenleser"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was benötigen Sie? Ihre eID (Personalausweis, Ausländerkarte oder kids-ID), Ihren Pincode, einen Kartenleser und die eID-Software auf Ihrem Computer."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID-Kartenleser"])}
      },
      "bmid": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via itsme"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um dieses Anmeldeverfahren nutzen zu können, müssen Sie bereits bei itsme registriert sein."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["itsme"])},
        "register": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.itsme.be/de/get-started"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr itsme-Konto anlegen"])}
        }
      },
      "myid": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via myID.be"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um dieses Anmeldeverfahren nutzen zu können, müssen Sie bereits bei myID.be registriert sein."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myID.be"])},
        "register": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://myID.be/ActivateLogin?ui_locales=de"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr myID.be-Konto anlegen"])}
        }
      },
      "bdiw": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden via MyGov.be"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um dieses Anmeldeverfahren nutzen zu können, müssen Sie bereits bei MyGov.be registriert sein."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden via MyGov.be"])}
      },
      "vasco": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via mydigipass"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um dieses Anmeldeverfahren nutzen zu können, müssen Sie bereits bei mydigipass registriert sein."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mydigipass"])}
      },
      "helena": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit Helena"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helena ist ein digitaler Schlüssel, mit dem Sie sich als Patient bei den eHealth-Anwendungen anmelden können."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helena"])}
      },
      "otpservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit Sicherheitscode per SMS"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie zuerst Ihren Benutzernamen und Ihr Passwort aus Ihrem „Meine digitalen Schlüssel” ein und klicken Sie auf „Weiter”, um die SMS zu erhalten."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitscode per SMS"])}
      },
      "totpservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit Sicherheitscode mittels mobiler Anwendung"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie zuerst Ihren Benutzernamen und Ihr Passwort aus „Meine digitalen Schlüssel” ein und klicken Sie auf „Weiter”, um anschließend den einmaligen Code einzugeben."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitscode mittels mobiler Anwendung"])}
      },
      "mailotpservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit Sicherheitscode per E-Mail"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie zuerst Ihren Benutzernamen und Ihr Passwort aus „Meine digitalen Schlüssel” ein und klicken Sie auf „Weiter”, um anschließend den einmaligen Code einzugeben."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitscode per E-Mail"])}
      },
      "commercialservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit einem kommerziellen Zertifikat"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein kommerzielles Zertifikat ist ein gültiges Authentifizierungs- und Identifikationsmittel für eine Online-Behördenanwendung. Ein kommerzielles Zertifikat ist für den persönlichen Gebrauch bestimmt und muss in Ihrem Browser geladen worden sein."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einem kommerziellen Zertifikat"])}
      },
      "commercialnoidservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit einem kommerziellen Zertifikat"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein kommerzielles Zertifikat ist ein gültiges Authentifizierungs- und Identifikationsmittel für eine Online-Behördenanwendung. Ein kommerzielles Zertifikat ist für den persönlichen Gebrauch bestimmt und muss in Ihrem Browser geladen worden sein."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einem kommerziellen Zertifikat"])}
      },
      "commercial": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit einem kommerziellen Zertifikat"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein kommerzielles Zertifikat ist ein gültiges Authentifizierungs- und Identifikationsmittel für eine Online-Behördenanwendung. Ein kommerzielles Zertifikat ist für den persönlichen Gebrauch bestimmt und muss in Ihrem Browser geladen worden sein."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einem kommerziellen Zertifikat"])}
      },
      "comcertnoid": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit einem kommerziellen Zertifikat"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein kommerzielles Zertifikat ist ein gültiges Authentifizierungs- und Identifikationsmittel für eine Online-Behördenanwendung. Ein kommerzielles Zertifikat ist für den persönlichen Gebrauch bestimmt und muss in Ihrem Browser geladen worden sein."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einem kommerziellen Zertifikat"])}
      },
      "citizentokenservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit Sicherungscode mit Token"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um sich mit Ihrem Token anzumelden, geben Sie zuerst Ihren Benutzernamen und Ihr Passwort aus „Meine digitalen Schlüssel” ein."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherungscode mit Token"])}
      },
      "passwordservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit Benutzername und Passwort"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies sind der Benutzername und das Passwort aus „Meine digitalen Schlüssel”."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername und Passwort"])}
      },
      "lowprofileservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit Benutzername und Passwort"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies sind der Benutzername und das Passwort aus „Meine digitalen Schlüssel”."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername und Passwort"])}
      },
      "storkservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit europäischer Authentifizierung"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit „Cross Border Authentication” melden Sie sich als Ausländer von anderen europäischen Ländern oder von Belgien aus für belgische Behördenanwendungen an, die dieses Anmeldeverfahren unterstützen."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["europäischer Authentifizierung"])}
      },
      "eidasservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit einem von der EU anerkannten elektronischen Identitätsnachweis"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie einen nationalen und innerhalb der EU anerkannten elektronischen Identitätsnachweis besitzen, können Sie sich auf Grundlage der sogenannten eIDAS-Verordnung anmelden."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einem von der EU anerkannten elektronischen Identitätsnachweis"])}
      },
      "partnerservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with wireless eID card reader"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um dieses Anmeldeverfahren nutzen zu können, müssen Sie bereits bei MYDIGIPASS registriert sein sowie über eine eID und einen drahtlosen Kartenleser verfügen."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wireless eID card reader"])}
      }
    },
    "sidebar": {
      "custom": {
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie möchten Zugang erhalten zu:"])}
      },
      "important_to_know": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtige Informationen!"])},
      "once_you_are_connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach der Anmeldung haben Sie mit CSAM automatisch Zugang zu anderen Online-Behördendiensten mit dem gleichen Schlüssel gesichert. Dies gilt, solange Ihr Browserfenster aktiv ist."])},
      "video": {
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://idp.iamfas.belgium.be/fasui/images/videos/Aanmelden-alloptions_DE.png"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/_iY44nzYwKY?autoplay=1"])}
      }
    }
  },
  "info": {
    "application": {
      "version": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendungsversion"])}
      },
      "build": {
        "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build tag"])},
        "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build timestamp"])}
      }
    }
  },
  "lowprofileservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Benutzername und Passwort anmelden"])},
    "login": {
      "step": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren"])},
        "strong_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzernamen"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und Ihr"])},
        "strong_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren <strong>Benutzernamen</strong> und Ihr <strong>Passwort</strong> ein."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf <strong>Weiter</strong>"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
      },
      "reset_password": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzernamen und/oder Passwort vergessen?"])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie den digitalen Schlüssel \"Sicherungscode mit Benutzername und Passwort\" noch nicht aktiviert?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um sich mit Sicherungscode mit Benutzername und Passwort anzumelden, müssen Sie diesen digitalen Schlüssel zuerst aktivieren. Klicken Sie auf 'Anmelden mit eID-Kartenleser' und befolgen Sie die Anweisungen."])}
    }
  },
  "mailotpservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung mit Sicherheitscode per E-Mail"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den Sicherheitscode ein, den Sie per E-Mail erhalten haben"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf <strong>\"Weiter\"</strong>"])}
      },
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihr Einmal-Passwort ein"])},
      "warning": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung!"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Es kann einige Minuten dauern, bis Sie die E-Mail erhalten. Schauen Sie auch in Ihren Spam-Ordner! Der Code bleibt nur 10 Minuten gültig. Geben Sie ihn deshalb rechtzeitig ein."])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie den digitalen Schlüssel \"Sicherungscode per E-Mail\" noch nicht aktiviert?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um sich mit Sicherungscode per E-Mail anzumelden, müssen Sie diesen digitalen Schlüssel zuerst aktivieren. Klicken Sie auf 'Anmelden mit eID-Kartenleser' und befolgen Sie die Anweisungen."])}
    },
    "footer": {
      "video": {
        "1": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation-Security_code_mail_DE.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/gtE51OlC7LQ?autoplay=1"])}
        },
        "2": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login-security_code_mail_DE.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/nx0eAyfVPS8?autoplay=1"])}
        }
      }
    }
  },
  "otpservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden mit Sicherheitscode per SMS"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den Sicherheitscode ein, den Sie per SMS erhalten haben:"])}
      },
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den einmaligen Sicherheitscode ein:"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf <strong>Weiter</strong>"])},
      "warning": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung!"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Der Code bleibt nur 10 Minuten gültig. Geben Sie ihn deshalb rechtzeitig ein."])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie den digitalen Schlüssel \"Sicherungscode per SMS\" noch nicht aktiviert?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um sich mit Sicherungscode per SMS anzumelden, müssen Sie diesen digitalen Schlüssel zuerst aktivieren. Klicken Sie auf 'Anmelden mit eID-Kartenleser' und befolgen Sie die Anweisungen."])}
    },
    "footer": {
      "video": {
        "1": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden-aanmelden_met_sms_DE.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/vrRna0teoCo?autoplay=1"])}
        },
        "2": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeren-aanmelden_met_sms_DE.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/EaJt8it9IPo?autoplay=1"])}
        }
      }
    }
  },
  "passwordservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Benutzername und Passwort anmelden"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren <strong>Benutzernamen</strong> und Ihr <strong>Passwort</strong> ein."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf <strong>Weiter</strong>"])}
      },
      "reset_password": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzernamen und/oder Passwort vergessen?"])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie den digitalen Schlüssel \"Sicherungscode mit Benutzername und Passwort\" noch nicht aktiviert?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um sich mit Sicherungscode mit Benutzername und Passwort anzumelden, müssen Sie diesen digitalen Schlüssel zuerst aktivieren. Klicken Sie auf 'Anmelden mit eID-Kartenleser' und befolgen Sie die Anweisungen."])}
    }
  },
  "postauthentication": {
    "setcapacity": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie das Verfahren, mit dem Sie sich anmelden möchten:"])},
      "button": {
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
      },
      "options": {
        "citizen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["im eigenen Namen"])},
        "enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["im Namen eines Unternehmens"])}
      },
      "explanation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum diese Auswahl?"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Mitglied von einer dieser Zielgruppen können Sie Zugang zu den Online-Behörden erhalten:"])},
        "text2": {
          "part1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["im eigenen Namen (Bürger)"])},
          "part2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["im Namen eines Unternehmens (Verantwortlicher in einem Unternehmen)"])}
        },
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jede Zielgruppe bietet Ihnen als Benutzer andere Möglichkeiten und Rechte."])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung!"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist jedoch nicht möglich, sich im gleichen Internetbrowser gleichzeitig für zwei verschiedene Zielgruppen anzumelden. Wenn Sie die Zielgruppe wechseln, werden Sie aufgefordert, alle Anwendungen, für die Sie in Ihrer aktuellen Zielgruppe angemeldet sind, zu schließen. Ansonsten erhalten Sie keinen Zugang zu einer Anwendung in einer anderen Zielgruppe."])}
      }
    },
    "emailverification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bestätigen Sie Ihre E-Mail-Adresse"])},
      "link": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen! Zur Gewährleistung der Sicherheit sind die digitalen Schlüssel nur zeitlich begrenzt gültig. Einige Monate vor Ablauf dieser Schlüssel werden Sie per E-Mail hierüber informiert. Damit wir Sie rechtzeitig und korrekt informieren können, bitten wir Sie, Ihre E-Mail-Adresse einzugeben. Weitere Informationen finden Sie <0>hier</0>."])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be/de/andere#7294"])}
      },
      "confirm": {
        "form": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bestätigen Sie Ihre E-Mail-Adresse."])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
          "email_repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse bestätigen"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])}
        }
      },
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben Ihre E-Mail-Adresse erfolgreich eingegeben!"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf „Weiter“, um die Anmeldung fortzusetzen."])}
      }
    },
    "setlifecycle": {
      "usernamepassword": {
        "warning": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihren Benutzernamen und Ihr Passwort ändern"])},
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" Willkommen! laut unserem Datenbestand läuft die Gültigkeitsdauer Ihres <strong>Benutzernamens und Passworts</strong> am <strong><0>", _interpolate(_named("expiryDate")), "</0></strong> ab. Damit Sie sich auch weiterhin mit Ihrem Benutzernamen und Passwort oder anderen digitalen Schlüsseln anmelden können, die die Kombination Benutzername und Passwort nutzen, füllen Sie bitte nachfolgendes Formular aus und übermitteln Sie dieses. Wenn Sie dies nicht jetzt erledigen möchten, können Sie dies auch zu einem späteren Zeitpunkt nachholen. Klicken Sie in diesem Fall unterhalb des Formulars auf \"Nein, danke. Ich erledige dies später.\""])}
        },
        "renewal": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte ändern Sie Ihren Benutzernamen und/oder Ihr Passwort."])}
        },
        "expiring": {
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Benutzername und Ihr Passwort verlieren am <strong><0>", _interpolate(_named("expiryDate")), "</0></strong> ihre Gültigkeit und müssen daher geändert werden."])}
        }
      },
      "token": {
        "warning": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneuerung Ihres Tokens"])},
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Willkommen! Laut unserem Datenbestand läuft die Gültigkeitsdauer Ihres <strong>Sicherheitscodes mit Token</strong> am <strong><0>", _interpolate(_named("expiryDate")), "</0></strong> ab. Damit Sie sich auch weiterhin mit diesem digitalen Schlüssel anmelden können, füllen Sie bitte nachfolgendes Formular aus und übermitteln Sie dieses. So wird Ihr Sicherheitscode mit Token rechtzeitig erneuert. Wenn Sie dies nicht jetzt erledigen möchten, können Sie dies auch zu einem späteren Zeitpunkt nachholen. Klicken Sie in diesem Fall unterhalb des Formulars auf \"Nein, danke. Ich erledige dies später.\""])}
        },
        "renewal": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie dieses Formular ausfüllen, wird automatisch ein neuer Sicherheitscode mit Token erstellt und an Ihre hinterlegte E-Mail-Adresse gesendet."])}
        },
        "expiring": {
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Sicherheitscode mit Token verliert am <strong><0>", _interpolate(_named("expiryDate")), "</0></strong> seine Gültigkeit und muss daher erneuert werden."])}
        }
      },
      "inputfields": {
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
        "repeatpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
        "email": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen und bestätigen Sie Ihre E-Mail-Adresse. Wenn Sie eine andere E-Mail-Adresse verwenden möchten, können Sie diese ebenfalls hier ändern."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
          "confirmlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse bestätigen"])}
        }
      },
      "tooltip": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein, danke. Ich erledige dies später"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])}
    },
    "validation": {
      "username": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie einen Benutzernamen ein."])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Benutzername muss 6 bis 64 Zeichen lang sein und mindestens 1 Buchstaben enthalten. Bitte verwenden Sie ausschließlich Buchstaben, Zahlen und/oder diese Zeichen: '-_.'"])},
        "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Benutzername existiert bereits. Wählen Sie einen anderen Benutzernamen."])}
      },
      "password": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie ein Passwort ein."])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort muss 8 bis 100 Zeichen lang sein und mindestens 1 Buchstaben sowie mindestens 1 Zahl oder eines dieser Zeichen enthalten: !, $, #, %, -, *, _ '."])},
        "notequal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die eingegebenen Passwörter stimmen nicht überein."])},
        "unchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben Ihr derzeitiges Passwort eingegeben. Bitten geben Sie ein anderes Passwort ein."])}
      },
      "repeatpassword": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bestätigen Sie Ihr Passwort."])}
      },
      "email": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine E-Mail-Adresse ein."])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie eine gültige E-Mail-Adresse ein."])},
        "notunique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese E-Mail-Adresse wird bereits verwendet. Bitte geben Sie eine andere E-Mail-Adresse ein."])}
      },
      "confirmemail": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bestätigen Sie Ihre E-Mail-Adresse."])},
        "notequal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die eingegebenen E-Mail-Adressen stimmen nicht überein."])}
      }
    }
  },
  "pwdresetotp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername und Passwort zurücksetzen"])},
    "reset_password": {
      "step1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Ihren Schlüssel aus"])}
      },
      "step2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen Sie Ihre Identität"])},
        "otp": {
          "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den eindeutigen Code ein, den Sie per SMS erhalten haben:"])},
          "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf 'Weiter'."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eindeutiger SMS-Code"])}
        }
      },
      "step3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])}
      }
    }
  },
  "remote-consent": {
    "scopes": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[DE]governmentdocuments"])},
      "egovnrn": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[DE]national register number"])},
        "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["egovNRN"])}
      },
      "profile": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[DE]profile"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[DE]surname"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[DE]given name"])},
        "fedId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[DE]fedID"])},
        "preferredLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[DE]preferred language"])},
        "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[DE]e-mail address"])}
      },
      "details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[DE]Details about your public-service documents"])},
        "metaData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[DE]Meta-data"])},
        "documentsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[DE]Title of your document<br/>Document type<br/>Details of the sender<br/>Period of validity for the document<br/>"])},
        "content": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[DE]Content"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[DE]The service provider has no knowledge of the content of your documents. The content of the document is shown only if you are logged in and are sitting in front of your computer."])}
        }
      }
    },
    "button": {
      "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einverstanden"])},
      "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])}
    }
  },
  "totppasswordresetservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername und Passwort zurücksetzen"])},
    "reset_password": {
      "step1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Ihren Schlüssel aus."])}
      },
      "step2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen Sie Ihre Identität."])},
        "chooseApp": {
          "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die mobile App, die Sie verwenden möchten:"])},
          "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf <strong>'Weiter'</strong>."])}
        },
        "totp": {
          "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen Sie die mobile App, die Sie installiert haben. Es wird automatisch ein eindeutiger Sicherheitscode generiert."])},
          "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Geben Sie Ihren eindeutigen Sicherheitscode ein.</strong>"])},
          "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf <strong>'Weiter'</strong>."])}
        }
      },
      "step3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])}
      }
    }
  },
  "totpservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden mit Sicherheitscode mittels mobiler Anwendung"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen Sie die mobile Anwendung, die Sie installiert haben. Es wird automatisch ein Sicherheitscode erzeugt."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den einmaligen Sicherheitscode ein:"])}
      },
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf <strong>Weiter</strong>"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren einmaligen Code ein."])}
    },
    "choice": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die mobile Anwendung aus, die Sie verwenden möchten:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf <strong>Weiter</strong>."])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie den digitalen Schlüssel \"Sicherungscode über mobile App\" noch nicht aktiviert?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um sich mit Sicherungscode per mobiler App anzumelden, müssen Sie diesen digitalen Schlüssel zuerst aktivieren. Klicken Sie auf \"Anmelden mit eID-Kartenleser\" und befolgen Sie die Anweisungen."])}
    },
    "footer": {
      "video": {
        "1": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden-aanmelden_met_app_DE.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/h_0CS6nqH_w?autoplay=1"])}
        },
        "2": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeren-aanmelden_met_app_DE.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/f_0lnJFcW-A?autoplay=1"])}
        }
      }
    }
  },
  "weakprofile": {
    "form": {
      "emailAddress": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adress"])}
      },
      "firstname": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])}
      },
      "lastname": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])}
      },
      "username": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Benutzername sollte 6 bis 64 Zeichen enthalten. Er muss mindestens einen Buchstaben enthalten und sollte nur Buchstaben Ziffern oder die folgenden Zeichen enthalten"])}
      },
      "password": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
        "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ihr Passwort muss ", _interpolate(_list(1)), " bis ", _interpolate(_list(0)), " Zeichen lang sein und mindestens einen Buchstaben, eine Ziffer und eines der folgenden Zeichen enthalten"])}
      }
    },
    "email": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Aktivierungslink wird an diese E-Mail-Adresse gesendet."])}
    },
    "registration": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Benutzername und das Passwort wurden erfolgreich registriert!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben Ihnen eine E-Mail mit einem Aktivierungslink an  <strong>var:email</strong> geschickt. Wenn Sie auf den Link klicken, werden Ihr Benutzername und das Passwort aktiviert."])}
      }
    },
    "info": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben bereits einen Benutzernamen und ein Passwort?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie sich zuvor einmal mit einem anderen digitalen Schlüssel, wie einem Sicherheitscode über die mobile App, SMS oder Token angemeldet? In diesem Fall können Sie sich mit dem gleichen Benutzernamen und Passwort anmelden. Gehen Sie zu <0>CSAM-Meine digitalen Schlüssel</0>, um das zu überprüfen."])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=de"])}
    }
  },
  "logout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie wurden erfolgreich abgemeldet."])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir empfehlen Ihnen, Ihren Internetbrowser zu schließen."])}
  }
}