import { defineStore } from "pinia";
import { ref} from "vue";

export const useGoToStore = defineStore('goTo', () => {
    const goTo = ref('');

    const modifyGoTo = (receivedGoTo) => {
        goTo.value = receivedGoTo;
    };

    return {
        goTo,
        modifyGoTo
    };
});