import { defineStore } from "pinia";
import { ref} from "vue";

export const useStageStore = defineStore('stage', () => {
    const stage = ref('selectIdp');
    const subStage = ref('');
    const uuidHeader = ref('');

    const modifyStage = (nextState) => {
        stage.value = nextState;
    };

    const modifySubStage = (selectedSubStage) => {
        subStage.value = selectedSubStage;
    };

    const modifyUuidHeader = (newUuid) => {
        uuidHeader.value = newUuid;
    };

    return {
        stage,
        subStage,
        uuidHeader,
        modifyStage,
        modifySubStage,
        modifyUuidHeader
    };
});