import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import './assets/css/main.css'
import './assets/css/general.css'
import * as forgerock from '@forgerock/javascript-sdk';
import i18n from './i18n';
import { useGoToStore } from "@/stores/goToStore";
import { useStageStore } from './stores/stageStore'
import { getAM_URL } from '@/const';
import { v4 as uuidv4 } from 'uuid';

const pinia = createPinia();

createApp(App).use(i18n).use(pinia).use(router).mount('#app');


const goStore = useGoToStore();
const stageStore = useStageStore(); 

const goToMiddelware = (req, action, next) => {
  switch (action.type) {
    case 'START_AUTHENTICATE':
      req.url.searchParams.set('goto', goStore.goTo);
      stageStore.modifyUuidHeader(uuidv4());
      (function () {
        const headers = req.init.headers;
        headers.append('Accept-Language', i18n.global.locale.value);
        headers.append('X-FASUI-TRANSACTION-ID', stageStore.uuidHeader);
      })();
      break;
    case 'AUTHENTICATE':
      req.url.searchParams.set('goto', goStore.goTo);
      (function () {
        const headers = req.init.headers;
        headers.append('Accept-Language', i18n.global.locale.value);
        headers.append('X-FASUI-TRANSACTION-ID', stageStore.uuidHeader);
      })();
      break;
  }
  next();
};


// TODO: update for FAS and PER environment
forgerock.Config.set({
  serverConfig: {
    baseUrl: getAM_URL(),
    timeout: '90000'
  },
  realmPath: 'fas2',
  middleware: [goToMiddelware],
  tree: 'fas'
});


