const host = window.location.hostname;

let AM_URL='https://idp.iamfas.belgium.be/fas';
let AM_TESTING_EID_URL='https://certif.iamfas.belgium.be/eid-required/eid.svg';
let SMA_URL='https://iamapps.belgium.be/sma/public';

if (process.env.VUE_APP_AM_URL && process.env.AM_TESTING_EID_URL && process.env.VUE_APP_SMA_URL) {
    AM_URL = process.env.VUE_APP_AM_URL;
    AM_TESTING_EID_URL = process.env.AM_TESTING_EID_URL;
    SMA_URL=process.env.VUA_APP_SMA_URL;
    
} else {

    if (host.includes('local')) {
        AM_URL = 'https://idp.iamfas.local.belgium.be/fas';
        AM_TESTING_EID_URL = 'https://certif.iamfas.ta.belgium.be/eid-required/eid.svg';
        SMA_URL='https://iamapps.local.belgium.be/sma/public';

    } else if (host.includes('ta')) {
        AM_URL = 'https://idp.iamfas.ta.belgium.be/fas';
        AM_TESTING_EID_URL = 'https://certif.iamfas.ta.belgium.be/eid-required/eid.svg';
        SMA_URL='https://iamapps.ta.belgium.be/sma/public';

    } else if (host.includes('qa')) {
        AM_URL = 'https://idp.iamfas.qa.belgium.be/fas';
        AM_TESTING_EID_URL = 'https://certif.iamfas.qa.belgium.be/eid-required/eid.svg';
        SMA_URL='https://iamapps.qa.belgium.be/sma/public';

    } else if (host.includes('int')) {
        AM_URL = 'https://idp.iamfas.int.belgium.be/fas';
        AM_TESTING_EID_URL = 'https://certif.iamfas.int.belgium.be/eid-required/eid.svg';
        SMA_URL='https://iamapps.int.belgium.be/sma/public';

    } else {
        AM_URL = 'https://idp.iamfas.belgium.be/fas';
        AM_TESTING_EID_URL = 'https://certif.iamfas.belgium.be/eid-required/eid.svg';
        SMA_URL='https://iamapps.belgium.be/sma/public';

    }
}


export function getAM_URL() {
    return AM_URL;
}

export function getAM_TESTING_EID_URL() {
    return AM_TESTING_EID_URL;
}

export function getSMA_URL() {
    return SMA_URL;
}

