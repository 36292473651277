/**
* @description Performs DOM and URL actions necessary to execute a redirect based on the passed callback.
* Stores step information for resuming the tree if needed.
* @param {Object}step the step received from AM. 
*/
export function handleRedirectCallback(step) {
 const cb = step.getCallbackOfType('RedirectCallback');
 const redirectUrl = cb.getOutputByName('redirectUrl');
 const redirectByPost = cb.getOutputByName('redirectMethod') === 'POST';
 const expectToReturnFromRedirect = cb.getOutputByName('trackingCookie');

 if (expectToReturnFromRedirect) {
   // Save current step information for later resumption of tree.
   localStorage.setItem('FR-SDK-PreviousStep', JSON.stringify(step));
 }

 if (redirectByPost) {
   // Build and submit a post form that submits the passed redirect data
   const redirectData = cb.getOutputByName('redirectData');
   const form = document.createElement('form');
   form.method = 'post';
   form.action = redirectUrl;

   Object.entries(redirectData).forEach(([key, value]) => {
     const hiddenField = document.createElement('input');
     hiddenField.type = 'hidden';
     hiddenField.name = key;
     hiddenField.value = value;

     form.appendChild(hiddenField);
   });

   document.body.appendChild(form);
   form.submit();
 } else {
   // Plain redirect
   window.location.href = redirectUrl;
 }
}