<template>
    <div id="header">
        <div class="container-fluid no-gutter language-bar">
            <div class="container">
                <div class="row">
                    <div class="col-xs-11">
                        <ul class="unstyled-list list-inline language-list left-element">
                            <li v-for="locale in locales" :key="locale" class="cursor-pointer"><a
                                    @click="switchLocale(locale)" class="lowercase"
                                    :class="{ active: isActiveLocale(locale) }" href="#" role="button">{{ locale }}</a></li>
                        </ul>
                    </div>
                    <div class="col-xs-1">
                        <img :src="require('@/assets/images/be-logo.png')" alt="be-logo" class="be-logo">
                    </div>
                </div>
            </div>
        </div>
        <CookieBar v-if="showCookies" @cookies-accepted="cookiesAccepted"></CookieBar>
        <div class="container-fluid no-gutters csam-header white">
            <div class="container no-gutter">
                <div class="logo-container"><img :src="require('@/assets/images/logo-csam-white-background.png')"
                        :srcset="`${csamImg} 2x` " alt="logo-csam-white-background"
                        class="csam-header-logo"></div>
                <div class="csam-header-content white">
                    <div class="csam-header_tabel">
                        <div class="csam-header_tabel-cel csam-header_tabel-cel-lg main-title no-sub">
                            <h1 class="purple left-header" style="text-align:left">{{ $t("header.title") }}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import i18n from '@/i18n'
import CookieBar from "@/components/static/Cookie-Bar.vue";
import csamImg from "@/assets/images/logo-csam-white-background-2x.png";
import { ref } from 'vue';
export default {
    components: { CookieBar },
    setup() {
        const locales = ["nl", "fr", "de", "en"];

        //for cookies
        const showCookies = ref(true);

        const switchLocale = (locale) => {
            i18n.global.locale.value = locale;
            sessionStorage.setItem('lang', locale);
        }

        const isActiveLocale = (locale) => {
            return i18n.global.locale.value === locale;
        }

        let lang = sessionStorage.getItem('lang');
        if (lang) {
            switchLocale(lang);
        }

        //handle cookies bar

        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        };

        const isAcceptedCookie = () => {
            const present = getCookie('user-accepted-cookie-disclaimer');
            if (present === 'true') {
                return true;
            }

            return false;
        };

        const cookiesAccepted = () => {
            showCookies.value = false;
        };

        showCookies.value = !isAcceptedCookie();

        return { locales, showCookies, csamImg, switchLocale, isActiveLocale, cookiesAccepted };
    }

}
</script>
<style scoped>
.left-element {
    position: absolute;
    /* Position it absolutely within its containing element */
    left: 1;
    /* Position it at the left edge of the containing element */
    top: 50%;
    /* Adjust the top value as needed to vertically center the element */
    transform: translateY(-50%);
    /* Vertically center the element using transform */
}

.be-logo {
    width: 34px;
    height: 34px;
}
</style>
