export default {
  "citizentokenservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logging in with security code via token"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take your personal paper card (token) in hand"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the security code <0>number var:tokenRequested</0> of your card"])}
      },
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security code"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click <strong>Next</strong>"])}
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note! You cannot activate a new token anymore. Citizens with an active token can continue to use it in order to log into online services."])}
    }
  },
  "commercialnoidservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with a commercial certificate"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click <strong>Log in</strong>."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When asked to do so, select the commercial certificate you wish to use."])}
      },
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])}
    },
    "error": {
      "verification": {
        "step": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close all open windows in your browser."])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start your browser and try to log in again."])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check that you have selected the right certificate."])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your commercial certificate could not be verified."])},
        "isabel": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(If you wish to log in with an Isabel certificate, make sure you have the most recent Isabel 'middleware'. For this, go to the <0>Isabel website</0>, choose 'Isabel security components' and download the most recent version.)"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.isabel.eu/en/forbusiness/support6/isabel6/installation/download.html"])}
        },
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have any questions about logging in with a commercial certificate, you will quickly find an answer in the <0>help pages</0>."])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["http://5244.stg.fedict.be/en/faq/160"])}
      }
    }
  },
  "commercialservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with a commercial certificate"])}
  },
  "common": {
    "page": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])}
    },
    "login": {
      "username": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These are the username and password from My digital keys."])}
      },
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "choose_other_digital_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a different digital key"])},
      "button": {
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "test_eid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test eID"])},
        "test_result": {
          "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID Failed!"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID OK!"])}
        }
      }
    },
    "sidebar": {
      "no_eid": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you not have an eID?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" about how you can use the online public services without the eID."])},
        "link": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be/en/identification-without-eid"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])}
        }
      },
      "activate_other_key": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to activate another key?"])},
        "link": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in <0>here</0> to create or edit your digital keys."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=en"])}
        }
      }
    },
    "help": {
      "need_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help?"])},
      "unable_to_find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to find what you were looking for?"])},
      "read-our": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Read our"])},
      "quickly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and quickly find an answer to your question."])},
      "link": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq-list"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Read our <1>help page</1> and quickly find an answer to your question."])},
        "help_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["help page"])}
      }
    },
    "cookie": {
      "consent": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On using our applications, cookies are installed in accordance with our privacy policy."])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept cookies"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information"])},
        "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide information"])},
        "more_info": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>For the \"My digital keys\" application FPS Policy and Support – DG Digital Transformation uses cookies to improve the performance of the website, functional cookies for use-friendliness and temporary session cookies required for authentication during the session. If you refuse to accept the cookies, some parts of our websites might not work, or not work at their best.</p><br/><p><strong>Necessary cookies</strong></p><p>These cookies are essential to check your identity securely and based on this grant you access to the applications you wish to use.</p><br/><p><strong>Functional cookies</strong></p><p>Functional cookies are cookies that improve the website functionality and user-friendliness. Fedict uses cookies to remember your language preference.</p><br/><p><strong>Cookies for website performance</strong></p><p>FOD Beleid en Ondersteuning – DG Digitale Transformatie uses load-balancing cookies. They are used for websites that are frequently visited and serve to spread the load of requests across several separate networks and servers.</p><br/><p>You can refuse to accept cookies in your browser settings. You will find the procedure for refusing to accept cookies for the most commonly used browsers on the following website: <a href=\"http://www.aboutcookies.org/\">http://www.aboutcookies.org/</a>. You can remove the cookies from your computer at any time.</p>"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["http://www.aboutcookies.org/"])}
        }
      }
    },
    "modal": {
      "warning": {
        "firefox": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install the eID add-on for Firefox"])},
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to use your eID via Firefox? Then you need the official “eID Belgium” add-on. If you have not installed it yet, please click ‘Install add-on for Firefox’."])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you already installed the add-on? Please click ‘Log in with your eID’."])},
          "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note:"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" In order to log in with your eID, you need to install the eID software. If you have not installed it yet, you can download it on the "])},
          "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID software website."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.eid.base)/en"])},
          "button": {
            "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with your eID"])},
            "addon": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install add-on for Firefox"])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.firefox_addons.base)/en/firefox/addon/belgium-eid/?src=search"])}
            }
          }
        }
      }
    },
    "alt_text": {
      "images": {
        "emailOtpStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security code by email"])},
        "mobileAppStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security code via mobile app"])},
        "certificateStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commercial certificate"])},
        "certificateStepNoId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commercial certificate"])},
        "usernamePasswordStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["username and password"])},
        "smsStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security code via text message"])},
        "tokenStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security code via token"])}
      }
    },
    "greetings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With kind regards,<br/>Service Desk Digital Transformation"])},
    "faq": {
      "what_do_i_need_eid": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/123"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do I need?"])}
      },
      "101": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/101"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do I need to do if I suspect someone else is misusing my digital keys?"])}
      },
      "105": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/105"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I forgot my PIN"])}
      },
      "106": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/106"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't have a card reader"])}
      },
      "107": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/107"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't have an electronic ID card (eID)"])}
      },
      "109": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/109"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I always get the message that my code is invalid."])}
      },
      "117": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/117"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account is blocked. What do I need to do?"])}
      },
      "118": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/118"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I forgot my username and/or my password. What do I need to do?"])}
      },
      "119": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/119"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are the possibilities of identifying with a smartphone or tablet?"])}
      },
      "142": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/142"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do I need?"])}
      },
      "143": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/143"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to replace my smartphone or tablet. What about the mobile application?"])}
      },
      "145": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/145"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which mobile app can I use to identify myself with \"security code via mobile app\""])}
      },
      "153": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/153"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do I need?"])}
      },
      "156": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/156"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do I need?"])}
      },
      "158": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/158"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If I already have a username and a password to connect with security code on paper (token), SMS or mobile application, is it still necessary to enter the username and password as a distinct digital key?"])}
      },
      "170": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/170"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does the public service have access to my data when I use my digital keys?"])}
      },
      "172": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/172"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How safe are the different digital keys?"])}
      },
      "174": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/174"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I safely log off from the online government services?"])}
      },
      "7258": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/en/eidas#7258"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is eIDAS?"])}
      },
      "7262": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/en/eidas#7262"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why do I have to identify myself with eIDAS?"])}
      },
      "7347": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/en/eidas#7347"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I do not know my Belgian identification number."])}
      },
      "7269": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/en/eidas#7269"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Will my details be kept safe?"])}
      },
      "7334": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/en/eidas#7334"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do I need to log into eIDAS (for the first time)?"])}
      },
      "7338": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/en/eidas#7338"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which European electronic identification means can I use to log in?"])}
      },
      "7277": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/en/eidas#7277"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I get an error notification that my identification cannot be processed automatically."])}
      },
      "list": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq-list"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All questions"])}
      },
      "what_do_i_need_sms": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/148#6396"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do I need?"])}
      },
      "cost_sms": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/152#6395"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do I have to pay to receive a security code via SMS?"])}
      },
      "new_phone_sms": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/150#6753"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have a new cell phone number. What about the security codes sent by SMS?"])}
      },
      "sms_help1": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I use my mobile phone to receive a security code via SMS for somebody else?"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/151#6394"])}
      },
      "lost_token": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/114#6831"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I lost my token. What should I do?"])}
      },
      "commercial_certif": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A registered commercial certificate"])}
      },
      "156_login": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/156#6464"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do I need to be able to log in?"])}
      },
      "118_username_password": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/118#6443"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgotten your username and password?"])}
      },
      "158_app_sms_token": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/158#6465"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\nAlready set a username and password via mobile app, text message or token?"])}
      },
      "username_password": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your username and password"])}
      },
      "otc": {
        "activation_code_lost": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/i-have-lost-my-activation-code-what-should-i-do#7129"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have lost my activation code. What should I do?"])}
        },
        "what_is_activation_code": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/what-my-activation-code#7165"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is my activation code?"])}
        },
        "wrong_code_entered": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/i-entered-wrong-activation-code-three-times-row-what-should-i-do#7169"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I entered the wrong activation code three times in a row. What should I do?"])}
        }
      },
      "why_login_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security-code-email#7310"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why log in with e-mail?"])}
      },
      "new_email_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security-code-email#7313"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have a new e-mail address. What should I do with the security code by e-mail?"])}
      },
      "get_started_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security-code-email#7319"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to get started! What do I need?"])}
      },
      "x_attempts_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security-code-email#7322"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I get a message that I have only X attempts left."])}
      },
      "lapsed_code_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security-code-email#7325"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I get a message that my security code has lapsed. What should I do?"])}
      },
      "7185": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/en/faq/how-can-i-register-username-and-password#7185"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register a username and password yourself?"])}
      },
      "7189": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/en/faq/i-want-register-username-and-password-my-e-mail-address-already-use-what-should-i-do#7189"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What if my e-mail address is already in use?"])}
      },
      "7193": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/en/faq/i-want-register-username-and-password-my-username-already-use-what-should-i-do#7193"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What if my username is already in use?"])}
      },
      "wp_confirmation_help_text_1": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not received an activation e-mail?"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/en/faq/i-have-not-received-my-activation-link-what-should-i-do#7133"])}
      },
      "wp_confirmation_help_text_2": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why activate username and password?"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/en/faq/why-do-i-need-activate-my-username-and-password#7197"])}
      },
      "wp_confirmation_help_text_3": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can the e-mail still be used after 5 days?"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/en/faq/activation-link-activate-your-username-and-password-valid-5-days-can-i-use-e-mail-link#7181"])}
      }
    },
    "url": {
      "bosa": {
        "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://dt.bosa.be"])}
      },
      "fas2web": {
        "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["http://5244.stg.fedict.be"])}
      },
      "csam": {
        "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.csam.be"])}
      },
      "fedict": {
        "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["http://www.fedict.belgium.be"])}
      },
      "public": {
        "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://iamapps-public.belgium.be"])}
      },
      "sma": {
        "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://iamapps.belgium.be/sma"])},
        "self_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.base)/generalinfo"])}
      },
      "help": {
        "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be"])}
      },
      "eid": {
        "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://eid.belgium.be"])}
      },
      "firefox_addons": {
        "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://addons.mozilla.org"])}
      }
    }
  },
  "eidasregistration": {
    "registration": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since this is the first time you have logged in with eIDAS, we ask that you first enter some details. If we cannot automatically identify you in our system, these details can help us find your record and then contact you if necessary."])}
    },
    "reregistration": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try logging in again"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back! You could not log in last time because you may have entered an incorrect Belgian identification number or none at all. We therefore ask you to check your details and change them if necessary. Click 'Continue' when you are done."])},
      "attempts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Attempt ", _interpolate(_list(0)), " of ", _interpolate(_list(1))])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
    },
    "form": {
      "nrn": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgian identification number (National Register or BIS number)"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Belgian identification number is your unique identification number with the Belgian government. If you are registered in the Belgian Register, this is your national register number. If you not registered in the National Register, then your BIS number is your identification number. For more information, please see the help section at the bottom of this page."])}
      },
      "nonrn": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I do not have a Belgian national identification number."])},
        "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Note:</strong> If you do not enter an identification number, we cannot process your details automatically. Processing will take longer, and you will not be able to log in immediately."])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
      "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your e-mail address"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone number"])},
      "phonePrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone number prefix"])},
      "gender": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])}
      },
      "birthcountry": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country of birth"])},
        "defaultOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a country"])}
      },
      "birthplace": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of birth"])},
        "defaultOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a place of birth"])}
      },
      "eula": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to <0>the General Terms and Conditions</0> and <1>Privacy Policy</1>."])},
        "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to "])},
        "generalterms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the General Terms and Conditions"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" and "])},
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
        "terms": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.public.base)/eula/sma/en/License%20agreement%20FEDICT%20IAM.pdf"])}
        },
        "privacy": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.bosa.base)/sites/default/files/content/FAS/privacyverklaring_csam_1.0_en.pdf"])}
        }
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
    }
  },
  "eidasservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with an electronic identification means recognized at European level"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the country where the digital key you wish to log in with has been issued."])},
    "countries": {
      "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgium"])},
      "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
      "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
      "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech Republic"])},
      "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
      "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denmark"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spain"])},
      "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
      "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
      "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greece"])},
      "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatia"])},
      "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungary"])},
      "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ireland"])},
      "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italy"])},
      "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
      "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithuania"])},
      "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grand Duchy of Luxembourg"])},
      "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvia"])},
      "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
      "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Netherlands"])},
      "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poland"])},
      "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
      "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
      "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakia"])},
      "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
      "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweden"])},
      "UK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Kingdom"])},
      "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eIDAS-Node Validator"])},
      "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norway"])}
    }
  },
  "eidpasswordresetservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset username and password"])},
    "reset_password": {
      "step1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your key"])},
        "eid": {
          "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your eID card reader to your computer."])},
          "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert your electronic identity card (eID), foreigner's card or kids-ID into the card reader."])},
          "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click <strong>'Next'</strong>, select your certificate and enter the PIN of your identity card when asked to do so."])}
        }
      },
      "step2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your identity"])}
      },
      "step3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
      }
    }
  },
  "eidservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with eID card reader"])},
    "alt-text": {
      "connected-eID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID card reader"])}
    },
    "login": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your eID card reader to your computer"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert your electronic identity card (eID), foreigner's card or kids-ID into the card reader"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click <strong>'Log in'</strong>, select your certificate and enter the PIN of your identity card when asked to do so"])},
      "button": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])}
      }
    },
    "video": {
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://idp.iamfas.belgium.be/fasui/images/videos/Aanmelden-eID_kaartlezer_EN.png"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/RBFe-FdPw34?autoplay=1"])}
    },
    "error": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login failed"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not logged in. Check the most frequent causes:"])},
      "image_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning triangle"])},
      "cardreader": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you inserted the eID into the card reader properly?"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure you insert the eID into your card reader properly."])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close all browser windows and shut down the browser itself."])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure the card reader is connected properly."])},
        "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert your eID into the card reader"])},
        "step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open your browser and try to log in again."])}
      },
      "software": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was the eID software installed recently?"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you recently (re)install the eID software?"])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restart your computer."])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try to log in again."])},
        "nosoftware": {
          "preface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have an older version of the eID software, or no version at all?"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.eid.base)/en"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the eID software"])}
        }
      },
      "help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help, I keep getting the same page!"])},
        "eid_help_pages": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://eid.belgium.be/en/question-and-answer"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consult our eID help page."])}
        },
        "contact_form_link": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be/en/contact"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])}
        }
      }
    }
  },
  "error": {
    "general": {
      "read_faq": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq-list"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should this error continue to occur, or should you have any questions, please consult the <0>help pages</0>."])}
      },
      "contact_us": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/contact"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you cannot find an answer to your question, please contact us via our <0>contact form</0>."])}
      }
    },
    "service_unavailable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, our services are not functioning properly."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are currently experiencing problems with our services. Therefore, you cannot log in at this time.<br/>We are working to resolve this as quickly as possible. Please try again later."])}
    },
    "request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["request-id: "])},
    "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timestamp: "])},
    "server_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["server-id: "])},
    "correlation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correlation-id: "])},
    "error": {
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
      "unexpected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unexpected error"])}
    },
    "page_not_found": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you are looking for does not exist."])}
    },
    "validation": {
      "fas": {
        "error": {
          "key": {
            "failed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Attempt ", _interpolate(_list(0)), " failed. You can try ", _interpolate(_list(1)), " more time(s) before this digital key is blocked."])},
            "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of your digital keys has been blocked. Try again in an hour."])},
            "user": {
              "not_found": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have this digital key (username and password) or the key may have expired. Log in in with a different key or activate this digital key in "])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAM - My digital keys"])}
              }
            }
          },
          "mailotp": {
            "nocredential": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your username and password are correct but this digital key is not available to you (security code via e-mail). Log in in with a different key or activate this digital key in <0>CSAM - My digital keys</0>."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=en"])}
            },
            "gateway_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The e-mail service is temporarily unavailable. Please try again later."])}
          },
          "otp": {
            "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your single-use password has expired. Request a new single-use password and try again."])},
            "missing": {
              "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a one-time password."])}
            },
            "no_phone": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your username and password are correct but this digital key is not available to you (security code via text message). log in with a different key or activate this digital key in <0>CSAM - My digital keys</0>."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=en"])}
            }
          },
          "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've been inactive to long, please try to reconnect."])},
          "page": {
            "not": {
              "found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Page not found</strong><br>The page you are looking for does not exist."])}
            }
          },
          "cert": {
            "not_trusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Something went wrong with your certificate.</strong> <br/><br/>Choose an alternative digital key or close your browser completely and try again."])},
            "x509": {
              "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Something went wrong with your certificate.</strong> <br/><br/>Choose an alternative digital key or close your browser completely and try again."])}
            },
            "ocsp_crl": {
              "failed": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your certificate could not be validated. Please try again later. If the problem persists, check the validity of your personal and/or commercial certificates. <br/>For more information about your personal certificate (eID), consult these <0>help page</0>.<br/>For more information about commercial certificates, consult these <1>help pages</1>."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/52"])},
                "url2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/faq/53"])}
              }
            }
          },
          "login": {
            "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login failed."])}
          },
          "otc": {
            "activation": {
              "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We regret your activation has been blocked"])},
              "failed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["<strong>Wrong activation code.</strong> Enter your correct personal activation code. <strong>You have ", _interpolate(_list(0)), "</strong> attempts left. After that, the activation will be blocked for your protection."])},
              "too": {
                "many": {
                  "tries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You entered an <strong> incorrect code 3 times</strong>. We have therefore blocked your activation for your security."])}
                }
              },
              "request": {
                "new": {
                  "activation_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Request a new activation link</strong> via the town-hall, the registration office or the Service Desk Digital Transformation."])}
                }
              }
            }
          },
          "partner": {
            "login": {
              "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Login failed</strong><br/><br/>We were unable to established a connection with MYDIGIPASS.COM."])}
            }
          },
          "password": {
            "missing": {
              "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a password."])},
              "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a username."])}
            }
          },
          "sms": {
            "gateway": {
              "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The SMS service is temporarily unavailable. Please try again later."])}
            }
          },
          "token": {
            "user": {
              "not_found": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have this digital key (security code via token) or the key may have expired. Login using a different key or activate this digital key in <0>CSAM - My digital keys</0>."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=en"])}
              }
            }
          },
          "totp": {
            "invalid": {
              "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login failed."])}
            },
            "missing": {
              "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a one-time code."])}
            },
            "no": {
              "device": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your username and password are correct but this digital key is not available to you (security code via mobile app). log in with a different key or activate this digital key in <0>CSAM - My digital keys</0>."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=en"])}
              }
            }
          },
          "nrn": {
            "nonrn": {
              "nrntext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We haven't received your national registry number while logging in to an online service of the government.\nProbably, your national registry number is not linked to your itsme®-account"])},
              "moreinfo": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information about itsme® can be found at <0>https://www.itsme.be/en</0>."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.itsme.be/en"])}
              },
              "errortoken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error token:"])},
              "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timestamp:"])},
              "serverid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["serverid:"])}
            }
          },
          "identity": {
            "deceased": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This profile is no longer active. Please contact the <0>Service Desk</0> for more information."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be/en/contact-form"])}
            }
          },
          "ial": {
            "insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, you have limited access rights. This is the reason you cannot log into this service."])},
            "incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, you have limited access rights. This is the reason you cannot log into this service."])}
          }
        }
      },
      "create": {
        "firstname": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a correct name with at least 2 characters. Use only letters of the alphabet, spaces and a hyphen ('-')."])}
        },
        "lastname": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a correct name with at least 2 characters. Use only letters of the alphabet, spaces and a hyphen ('-')."])}
        },
        "username": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a username."])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your username must be 6 to 64 characters long, must contain at least one letter and may only contain letters, numbers or the following characters"])},
          "notunique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This username already exists. Choose a different username."])},
          "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This username already exists. Choose a different username."])}
        },
        "password": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a password."])},
          "invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Your password must be between ", _interpolate(_list(1)), " and ", _interpolate(_list(0)), " characters in length and contain a minimum of 1 lowercase, 1 uppercase, one number and one of the following characters"])},
          "unchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You entered your current password. Please enter a different password."])},
          "repeat": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your password."])},
            "notequal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entered passwords do not match."])}
          }
        },
        "email": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter an e-mail address."])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid e-mail address."])},
          "notunique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This e-mail address already exists. Please enter a different e-mail address."])},
          "not": {
            "unique": {
              "weak": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This e-mail address already exists. Please enter a different e-mail address or log in with the username and password linked to this e-mail address. <0>Forgotten your password</0>?"])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.base)/public/resetpassword/chooseCredential?language=en"])}
              },
              "normal": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This e-mail address already exists. Please enter a different e-mail address or log in with the username and password linked to this e-mail address. <0>Forgotten your password</0>?"])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.base)/public/resetpassword/chooseCredential?language=en"])}
              }
            }
          },
          "confirm": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your e-mail address."])},
            "notequal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entered e-mail addresses do not match."])}
          }
        },
        "nrn": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid Belgian identification number."])}
        },
        "phone": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect telephone number. The number must contain 8 to 9 numerals for a domestic number, and 2 to 14 for an international number. Use numerals only; no periods or hyphens ('-')."])}
        },
        "gender": {
          "not": {
            "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose an option."])}
          }
        },
        "country": {
          "not": {
            "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your country of birth."])}
          }
        },
        "city": {
          "not": {
            "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your place of birth."])}
          }
        },
        "consent": {
          "notchecked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must agree with the General Terms and Conditions and the Privacy Policy."])}
        }
      }
    },
    "lifecycle": {
      "error": {
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You entered your current password. Please enter a different password."])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This username already exists. Choose a different username. "])}
      }
    },
    "fas": {
      "error": {
        "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login failed"])},
        "partner": {
          "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your authentication, which was used through one of our recognized partners, was aborted. Please close your internet browser completely and try again."])},
          "error": {
            "multiple": {
              "browsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you use more than one browser on your smartphone, please only use the default browser to log in. If you prefer, you can change the default browser in your smartphone's settings."])}
            },
            "incognito": {
              "browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using your browser in an incognito window or private mode? Disable the incognito window or private mode and try to log in again."])}
            },
            "contact": {
              "helpdesk": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If these options do not provide a solution, please contact the Digital Transformation Service Desk. You can find the contact details <0>here</0>."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/contact-form"])}
              }
            }
          }
        },
        "consent": {
          "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You refused the permission to receive messages from the affiliated government services electronically. Without this permission you cannot use this service."])}
        },
        "redirect": {
          "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you get an error message when authenticating, check that you have set the mobile browser you are using as the default browser (avoid Samsung Internet) and that you are not using private mode."])},
          "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the problem persists, we advise you to use your application's website."])}
        }
      }
    },
    "eidas": {
      "registration": {
        "pending": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your identification could not be processed automatically."])},
          "mail": {
            "sent": {
              "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We have sent an e-mail to <strong>", _interpolate(_list(0)), "</strong> and will contact you when your identification has been processed. For more information, please see our <0>help page</0>."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/eidas"])}
            }
          }
        }
      },
      "error": {
        "page": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login failed"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not logged in. Check the most frequent causes:"])},
          "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login via eIDAS is not possible in the following situations:"])},
          "name": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with eID or foreigners card with a stated name:"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is not possible to login via eIDAS if you have a stated name, since you have not been able to prove your identity to the Belgian government."])}
          },
          "enterprise": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with a private company:"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We only support logging in with the government online."])}
          },
          "contact": {
            "helpdesk": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you not found an answer to your problems above? Please contact the <0>Digital Transformation Service Desk</0>."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en/contact-form"])}
            }
          }
        },
        "nomatch": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, your registration could not be processed automatically. The personal data you entered does not match the data associated with the specified Belgian identification number."])},
          "text": {
            "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We have sent an e-mail to <strong>", _interpolate(_list(0)), "</strong> and will contact you when your identification has been processed. For more information, please see our <0>help page</0>."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.fedict.belgium.be/en/eidas#7284"])}
          }
        },
        "maxattempts": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, your registration could not be processed automatically because the maximum number of attempts has been reached."])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We have sent an e-mail to ", _interpolate(_list(0)), " containing the details of your registration. Please contact the helpdesk of the application you want to use."])}
        },
        "duplicates": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, your registration could not be processed automatically. There is already a Belgian identification number in the National Register with the same name and date of birth."])},
          "text": {
            "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We have sent an e-mail to <strong>", _interpolate(_list(0)), "</strong> and will contact you when your identification has been processed. For more information, please see our <0>help page</0>."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.fedict.belgium.be/en/eidas#7289"])}
          }
        },
        "notfound": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, your registration could not be processed automatically. The specified Belgian identification number was not found."])},
          "text": {
            "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We have sent an e-mail to <strong>", _interpolate(_list(0)), "</strong> and will contact you when your identification has been processed. For more information, please see our <0>help page</0>."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.fedict.belgium.be/en/eidas#7280"])}
          }
        },
        "unavailable": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, our services are not functioning properly."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are currently experiencing problems with our services, so we cannot perform any searches in the National Register. Therefore, you cannot log in at this time.<br/>We are working to resolve this as quickly as possible. Please try again later.."])}
        },
        "invalididentifier": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, you cannot register because your Belgian identification number has expired."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact the helpdesk of the application you want to use."])},
          "bosahelpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact the help desk of BOSA."])}
        },
        "nobiscreation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your identification could not be processed automatically."])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We have sent an e-mail to <strong>", _interpolate(_list(0)), "</strong> containing the details of your registration. Please contact the helpdesk of the application you want to use."])}
        }
      }
    }
  },
  "footer": {
    "nav": {
      "copy_right": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.csam.base)/en/index.html"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAM"])}
      },
      "terms_of_use": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.public.base)/eula/sma/en/License%20agreement%20FEDICT%20IAM.pdf"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions of use"])}
      },
      "privacy": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.bosa.base)/sites/default/files/content/FAS/privacyverklaring_csam_1.0_en.pdf"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])}
      },
      "accessibility": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibility"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/accessibility/fasui/en/Accessibility%20statement%20EN%20FASUI.pdf"])}
      },
      "powered_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by FPS BOSA - DG Digital Transformation"])}
    }
  },
  "foreignerservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activating your digital key"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your personal activation code."])},
    "activation_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation code"])},
    "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter activation code"])},
    "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your digital key"])},
    "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate your digital key"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This code consists of 20 characters which have been sent to you personally."])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])}
  },
  "header": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log on to online public services"])},
    "eidas": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logging in as an EU citizen"])}
    },
    "sma": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My digital keys"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log on to online public services"])}
    },
    "weakprofile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration of username and password"])}
    }
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your digital key to log in"])},
    "need_help": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help?"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/en"])}
    },
    "group_header": {
      "eid_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>eID</strong> or <strong>digital keys of recognised partners</strong>"])},
      "twofactor_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital keys with <strong>security code</strong> and <strong>username + password</strong>"])},
      "id_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital key with <strong>username + password</strong>"])},
      "european_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["European authentication"])},
      "external_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital key(s) via <strong>external partners</strong>"])}
    },
    "digital_key": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
      "eidservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with eID card reader"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do you need? 1) Your eID (identity card, foreigner card or kids-ID), your PIN, a card reader and the eID software."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID card reader"])}
      },
      "bmid": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via itsme"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use this login option, you must already have registered with itsme."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["itsme"])},
        "register": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.itsme.be/en/get-started"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your itsme account"])}
        }
      },
      "myid": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via myID.be"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use this login option, you must already have registered with myID.be."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myID.be"])},
        "register": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://myID.be/ActivateLogin?ui_locales=en"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your myID.be account"])}
        }
      },
      "bdiw": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In via MyGov.be"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use this login option, you must already have registered with MyGov.be."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In via MyGov.be"])}
      },
      "vasco": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via mydigipass"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use this login option, you must already have registered with mydigipass"])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mydigipass"])}
      },
      "helena": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with Helena"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helena is a digital key that allows you to log in to the eHealth applications as a patient."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helena"])}
      },
      "otpservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with security code via text message"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First enter your username and password from My digital keys and click Next to receive the text message."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security code via text message"])}
      },
      "mailotpservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with the security code by e-mail"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First enter your username and password from My digital keys and click Next to then enter the one-time code."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security code by e-mail"])}
      },
      "totpservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with security code via mobile app"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First enter your username and password from My digital keys and click Next to then enter the one-time code."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security code via mobile app"])}
      },
      "commercialservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with a commercial certificate"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A commercial certificate is a valid way to log in and identify yourself for an online government application. A commercial certificate is intended for personal use and must be loaded in your browser."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commercial certificate"])}
      },
      "commercialnoidservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with a commercial certificate"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A commercial certificate is a valid way to log in and identify yourself for an online government application. A commercial certificate is intended for personal use and must be loaded in your browser."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commercial certificate"])}
      },
      "commercial": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with a commercial certificate"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A commercial certificate is a valid way to log in and identify yourself for an online government application. A commercial certificate is intended for personal use and must be loaded in your browser."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commercial certificate"])}
      },
      "comcertnoid": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with a commercial certificate"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A commercial certificate is a valid way to log in and identify yourself for an online government application. A commercial certificate is intended for personal use and must be loaded in your browser."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commercial certificate"])}
      },
      "citizentokenservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with security code via token"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To log in with your token, first enter your username and password from My digital keys."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security code via token"])}
      },
      "passwordservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with username and password"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These are the username and password from My digital keys."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["username and password"])}
      },
      "lowprofileservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with username and password"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These are the username and password from My digital keys."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["username and password"])}
      },
      "storkservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with European authentication"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With \"Cross Border Authentication\" you log in as a foreigner from other European countries or from Belgium, for Belgian government applications that support this login option."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["European authentication"])}
      },
      "eidasservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with an electronic identification means recognized at European level"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have a national electronic identification means recognized by Europe, by virtue of the eIDAS Regulation you can log in."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic identification means recognized at European level"])}
      },
      "partnerservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with wireless eID card reader"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use this login option, you must already have registered with MYDIGIPASS and have an eID and a wireless card reader."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wireless eID card reader"])}
      }
    },
    "sidebar": {
      "custom": {
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You want to access: "])}
      },
      "important_to_know": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important to know!"])},
      "once_you_are_connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you have logged in, you automatically have access via CSAM to other online government services secured with the same digital key. This applies as long as your browser window is active."])},
      "video": {
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://idp.iamfas.belgium.be/fasui/images/videos/Aanmelden-alloptions_EN.png"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/FHJjtU7QYEE?autoplay=1"])}
      }
    }
  },
  "info": {
    "application": {
      "version": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application version"])}
      },
      "build": {
        "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build tag"])},
        "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build timestamp"])}
      }
    }
  },
  "lowprofileservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with username and password"])},
    "login": {
      "step": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your"])},
        "strong_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["username"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
        "strong_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your <strong>username</strong> and <strong>password</strong>"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click <strong>Next</strong>"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
      },
      "reset_password": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgotten your username and/or password?"])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you still need to activate digital key \"Security code via username and password\"?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To login with a security code via username and password, you first need to activate this digital key. Click 'Logging in with eID-card reader' and follow the steps shown."])}
    }
  },
  "mailotpservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logging in with the security code by e-mail"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the security code that was sent to you by e-mail"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click <strong>“Next”</strong>"])}
      },
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your one-time code"])},
      "warning": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note!"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" It may take a few minutes before you receive the e-mail. Also check your spam folder. The code is only valid for 10 minutes. Enter the code immediately."])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you still need to activate digital key \"Security code by e-mail\"?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To login with a security code by e-mail, you first need to activate this digital key. Click 'Logging in with eID-card reader' and follow the steps shown."])}
    },
    "footer": {
      "video": {
        "1": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation-Security_code_mail_EN.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/lPCX72EDLww?autoplay=1"])}
        },
        "2": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login-security_code_mail_EN.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/9DE0aVUViVU?autoplay=1"])}
        }
      }
    }
  },
  "otpservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with security code via text message"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the security code you have received by text message:"])}
      },
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your one-time code"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click <strong>Next</strong>"])},
      "warning": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note!"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The code is only valid for 10 minutes. Enter the code immediately."])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you still need to activate digital key \"Security code via text message\"?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To login with a security code via text message, you first need to activate this digital key. Click 'Logging in with eID-card reader' and follow the steps shown."])}
    },
    "footer": {
      "video": {
        "1": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden-aanmelden_met_sms_EN.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/NNJKBfIXAqI?autoplay=1"])}
        },
        "2": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeren-aanmelden_met_sms_EN.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/8qtCbOE4Naw?autoplay=1"])}
        }
      }
    }
  },
  "passwordservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with username and password"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your <strong>username</strong> and <strong>password</strong>"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click <strong>Next</strong>"])}
      },
      "reset_password": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgotten your username and/or password?"])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you still need to activate digital key \"Security code via username and password\"?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To login with a security code via username and password, you first need to activate this digital key. Click 'Logging in with eID-card reader' and follow the steps shown."])}
    }
  },
  "postauthentication": {
    "setcapacity": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose how you want to log in:"])},
      "button": {
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
      },
      "options": {
        "citizen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in your own name"])},
        "enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in the name of a company"])}
      },
      "explanation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why this choice?"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can access the government online as a member of one of these target groups:"])},
        "text2": {
          "part1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in own name (citizen)"])},
          "part2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in the name of a company (responsible person in a company)"])}
        },
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each target group offers you different options and rights as a user."])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note:"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is, however, not possible to log in for two different target groups in the same internet browser. In the event that you wish to change target group, you will be asked to close all applications to which you are logged in in your current target group. If you do not do this, you cannot access an application in another target group."])}
      }
    },
    "emailverification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm your e-mail address"])},
      "link": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome! To guarantee your security, digital keys are limited in time. You will be informed of the expiry of these keys a few months in advance by e-mail. In order to provide you with timely and correct information, we ask you to fill in your e-mail address. You can find more information <0>here</0>."])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be/en/andere#7294"])}
      },
      "confirm": {
        "form": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm your e-mail address."])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
          "email_repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm e-mail address"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
        }
      },
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have entered your e-mail address successfully!"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click 'Continue' to continue logging in."])}
      }
    },
    "setlifecycle": {
      "usernamepassword": {
        "warning": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change your username and password"])},
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" Welcome! We see that your <strong>username password</strong> will soon expire, on <strong><0>", _interpolate(_named("expiryDate")), "</0></strong>. To avoid being unable to log in with username and password or digital keys that make use of them, we ask you to complete and send the form below. If you do not have time now, you can also do this at a later time. In that case, click \"No thanks, I'll do this later\" at the bottom of this form."])}
        },
        "renewal": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please change your username and/or password."])}
        },
        "expiring": {
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your username and password will expire on <strong><0>", _interpolate(_named("expiryDate")), "</0></strong> and must therefore be changed."])}
        }
      },
      "token": {
        "warning": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew your token"])},
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welcome! We see that your <strong>security code via token</strong> will soon expire, on <strong><0>", _interpolate(_named("expiryDate")), "</0></strong>. To avoid being unable to log in with this digital key, we ask you to complete and send the form below. We will then ensure that your security code via token is renewed on time. If you do not have time now, you can also do this at a later time. In that case, click \"No thanks, I'll do this later\" at the bottom of the form.\""])}
        },
        "renewal": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After you complete this form we will automatically create a new security code via token for you, which we will send to your e-mail address."])}
        },
        "expiring": {
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your security code via token will expire on <strong><0>", _interpolate(_named("expiryDate")), "</0></strong> and must therefore be renewed."])}
        }
      },
      "inputfields": {
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "repeatpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
        "email": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check and confirm your e-mail address. If you wish to use a different e-mail address, you can also change it here."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
          "confirmlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm e-mail address"])}
        }
      },
      "tooltip": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No thanks, I'll do this later"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
    },
    "validation": {
      "fasui": {
        "password": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some error"])}
        }
      }
    }
  },
  "pwdresetotp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset username and password"])},
    "reset_password": {
      "step1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your key"])}
      },
      "step2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm you identity"])},
        "otp": {
          "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the unique code you have just received via SMS:"])},
          "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click 'Next'"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique SMS code"])}
        }
      },
      "step3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
      }
    }
  },
  "remote-consent": {
    "scopes": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["governmentdocuments"])},
      "egovnrn": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["national register number"])},
        "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["egovNRN"])}
      },
      "profile": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profile"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["surname"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["given name"])},
        "fedId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fedID"])},
        "preferredLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["preferred language"])},
        "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail address"])}
      },
      "details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details about your public-service documents"])},
        "metaData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta-data"])},
        "documentsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title of your document<br/>Document type<br/>Details of the sender<br/>Period of validity for the document<br/>"])},
        "content": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The service provider has no knowledge of the content of your documents. The content of the document is shown only if you are logged in and are sitting in front of your computer."])}
        }
      }
    },
    "button": {
      "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow"])},
      "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse"])}
    }
  },
  "totppasswordresetservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset username and password"])},
    "reset_password": {
      "step1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your key"])}
      },
      "step2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your identity"])},
        "chooseApp": {
          "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the mobile app you would like to use:"])},
          "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click <strong>'Next'</strong>"])}
        },
        "totp": {
          "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open the mobile app you have installed. A unique security code will be generated automatically."])},
          "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Enter your unique security code.</strong>"])},
          "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click <strong>'Next'</strong>."])}
        }
      },
      "step3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
      }
    }
  },
  "totpservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with security code via mobile app"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open the mobile app you have installed. A security code is automatically generated."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the one-time security code:"])}
      },
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click <strong>Next</strong>"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your one-time code"])}
    },
    "choice": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the mobile application you wish to use:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click <strong>Next</strong>"])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you still need to activate digital key \"Security code via mobile app\"?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To login with security code via mobile app, you first need to activate this digital key. Click \"Logging in with eID-card reader\" and follow the steps shown."])}
    },
    "footer": {
      "video": {
        "1": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden-aanmelden_met_app_EN.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/TbOOv_iQJTM?autoplay=1"])}
        },
        "2": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeren-aanmelden_met_app_EN.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/CNmzOnsbCvE?autoplay=1"])}
        }
      }
    }
  },
  "weakprofile": {
    "form": {
      "emailAddress": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])}
      },
      "firstname": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])}
      },
      "lastname": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])}
      },
      "username": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your user name should contain 6 to 64 characters. It needs at least 1 letter and should only contain letters, digits, or the next characters"])}
      },
      "password": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Your password must be between ", _interpolate(_list(1)), " and ", _interpolate(_list(0)), " characters in length and contain a minimum of 1 lowercase, 1 uppercase, one number and one of the following characters"])}
      }
    },
    "email": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The activation link will be sent to this e-mail address."])}
    },
    "registration": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your username and password have been registered successfully!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent an e-mail to <strong>var:email</strong> containing an activation link. Clicking on the link will activate your username and password."])}
      }
    },
    "info": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have a username and password?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you logged in before with a different digital key, such as with a security code via mobile app, SMS or token? If so, you can log in with the same username and the same password. Go to <0>CSAM My digital keys</0> to check this."])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=en"])}
    }
  },
  "logout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been successfully logged out."])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We advise you to close your internet browser."])}
  }
}