<template>
    <div class="csam-container" id="content">
        <div class="container-fluid no-gutter csam-content-container white">
            <div class="container csam-content-inner-container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                            <div class="col-sm-10" style="text-align:left">
                                <h2 class="h2-title purple margin-bottom-20">{{ $t("home.title") }}</h2>
                            </div>
                            <div class="col-sm-2 padding-left-0">
                                <h2 class="sub-title Grey102 text-right align-sm-left"><a
                                        :href="`https://sma-help.bosa.belgium.be/${locale}`">{{ $t("home.need_help.text") }}</a></h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 csam-content-container">
                        <div class="row no-gutter" style="text-align:left">
                            <div v-if="eidOrPartners.length > 0" class="col-sm-12 col-md-12 col-lg-8">
                                <div class="sub-title Grey102" v-html="$t('home.group_header.eid_group')"></div>
                            </div>
                        </div>
                        <div class="row no-gutter-mobile row-eq-height">
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" v-for="prov in eidOrPartners"
                                :key="prov.provider">
                                <div class="csam-aanmeld-container row-eq-column margin-bottom-0" :class="{ 'disabled': prov.uiConfig.disabled }">
                                    <div class="csam-aanmeld--icon"><img :src="prov.image" :alt="prov.alt">
                                    </div>
                                    <div class="csam-aanmeld--content"  style="text-align:left"><a @click="idpSelected(prov)" class="no_underline" tabindex="0">
                                            <h3 class="csam-aanmeld_title">{{ $t('home.digital_key.title') }}</h3>
                                            <div class="csam-aanmeld_subtext">{{ prov.message }}</div>
                                        </a></div>
                                    <div class="csam-aanmeld--info"><span :id="prov.tooltipId"
                                            class="fa fa-question-circle LightGrey fa--sizes"
                                            :title="(prov.tooltip)"></span></div>
                                </div>
                                <div style="text-align:left"><a v-if="prov.register" :href="prov.registerLink">{{ prov.registerMessage }}</a></div>
                            </div>
                        </div>
                        <div class="row no-gutter" style="text-align:left">
                            <div v-if="secCodeAndPass.length > 0" class="col-sm-12 col-md-12 col-lg-8">
                                <div class="sub-title Grey102" v-html="$t('home.group_header.twofactor_group')">
                                </div>
                            </div>
                        </div>
                        <div class="row no-gutter-mobile row-eq-height">
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" v-for="prov in secCodeAndPass"
                                :key="prov.provider">
                                <div class="csam-aanmeld-container row-eq-column " :class="{ 'disabled': prov.uiConfig.disabled }">
                                    <div class="csam-aanmeld--icon"><img :src="prov.image" :alt="prov.alt">
                                    </div>
                                    <div class="csam-aanmeld--content"  style="text-align:left"><a @click="idpSelected(prov)" class="no_underline" tabindex="0">
                                            <h3 class="csam-aanmeld_title">{{ $t('home.digital_key.title') }}</h3>
                                            <div class="csam-aanmeld_subtext">{{ prov.message }}</div>
                                        </a></div>
                                    <div class="csam-aanmeld--info"><span :id="prov.tooltipId"
                                            class="fa fa-question-circle LightGrey fa--sizes"
                                            :title="(prov.tooltip)"></span></div>
                                </div>
                            </div>
                        </div>
                        <div v-if="usernamePass || lowprofile" class="row no-gutter" style="text-align:left">
                            <div class="col-sm-12 col-md-12 col-lg-8">
                                <div class="sub-title Grey102" v-html="$t('home.group_header.id_group')"></div>
                            </div>
                        </div>
                        <div v-if="usernamePass" class="row no-gutter-mobile row-eq-height">
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <div class="csam-aanmeld-container row-eq-column " :class="{ 'disabled': passwordProv.uiConfig.disabled }">
                                    <div class="csam-aanmeld--icon"><img :src="require('@/assets/images/user-pw.svg')"
                                            alt="username and password"></div>
                                    <div class="csam-aanmeld--content"  style="text-align:left"><a class="no_underline"
                                            @click="idpSelected(passwordProv)" tabindex="0">
                                            <h3 class="csam-aanmeld_title">{{ $t('home.digital_key.title') }}</h3>
                                            <div class="csam-aanmeld_subtext">{{
                                                $t('home.digital_key.passwordservice.subtext') }}</div>
                                        </a></div>
                                    <div class="csam-aanmeld--info"><span
                                            id="tooltip-home:digital_key.passwordservice.tooltip"
                                            class="fa fa-question-circle LightGrey fa--sizes"
                                            :title="$t('home.digital_key.passwordservice.tooltip')"></span></div>
                                </div>
                            </div>
                        </div>
                        <div v-if="lowprofile" class="row no-gutter-mobile row-eq-height">
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <div class="csam-aanmeld-container row-eq-column " :class="{ 'disabled': lowprofileProv.uiConfig.disabled }">
                                    <div class="csam-aanmeld--icon"><img :src="require('@/assets/images/user-pw.svg')"
                                            alt="lowprofile"></div>
                                    <div class="csam-aanmeld--content"  style="text-align:left"><a class="no_underline"
                                            @click="idpSelected(lowprofileProv)" tabindex="0">
                                            <h3 class="csam-aanmeld_title">{{ $t('home.digital_key.title') }}</h3>
                                            <div class="csam-aanmeld_subtext">{{
                                                $t('home.digital_key.lowprofileservice.subtext') }}</div>
                                        </a></div>
                                    <div class="csam-aanmeld--info"><span
                                            id="tooltip-home:digital_key.lowprofileservice.tooltip"
                                            class="fa fa-question-circle LightGrey fa--sizes"
                                            :title="$t('home.digital_key.lowprofileservice.tooltip')"></span></div>
                                </div>
                            </div>
                        </div>
                        <div v-if="eidas" class="row no-gutter" style="text-align:left">
                            <div class="col-sm-12 col-md-12 col-lg-8">
                                <div class="sub-title Grey102">{{ $t('home.group_header.european_group') }}</div>
                            </div>
                        </div>
                        <div v-if="eidas" class="row no-gutter-mobile row-eq-height">
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <div class="csam-aanmeld-container row-eq-column " :class="{ 'disabled': eidasProv.uiConfig.disabled }">
                                    <div class="csam-aanmeld--icon"><img :src="require('@/assets/images/eidas.png')"
                                            alt="Electronic identification means recognized at European level"></div>
                                    <div class="csam-aanmeld--content"  style="text-align:left"><a class="no_underline"
                                            @click="idpSelected(eidasProv)" tabindex="0">
                                            <h3 class="csam-aanmeld_title">{{ $t('home.digital_key.title') }}</h3>
                                            <div class="csam-aanmeld_subtext">{{ $t('home.digital_key.eidasservice.subtext')
                                            }}</div>
                                        </a></div>
                                    <div class="csam-aanmeld--info"><span id="tooltip-home:digital_key.eidasservice.tooltip"
                                            class="fa fa-question-circle LightGrey fa--sizes"
                                            :title="$t('home.digital_key.eidasservice.tooltip')"></span></div>
                                </div>
                            </div>
                        </div>
                        <div v-if="activationCode" class="row no-gutter" style="text-align:left">
                            <div class="col-sm-12 col-md-12 col-lg-8">
                                <div class="sub-title Grey102">{{ $t('foreignerservice.title') }}</div>
                            </div>
                        </div>
                        <div v-if="activationCode" class="row no-gutter-mobile row-eq-height">
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <div class="csam-aanmeld-container row-eq-column " :class="{ 'disabled': activationCodeProv.uiConfig.disabled }">
                                    <div class="csam-aanmeld--icon"><img :src="require('@/assets/images/CSAM-logo.svg')"
                                            alt="logo-csam-white-background"></div>
                                    <div class="csam-aanmeld--content"  style="text-align:left"><a class="no_underline"
                                            @click="idpSelected(activationCodeProv)" tabindex="0">
                                            <h3 class="csam-aanmeld_title">{{ $t('home.digital_key.title') }}</h3>
                                            <div class="csam-aanmeld_subtext">{{ $t('foreignerservice.subtitle') }}</div>
                                        </a></div>
                                    <div class="csam-aanmeld--info"><span id="tooltip-home:digital_key.eidasservice.tooltip"
                                            class="fa fa-question-circle LightGrey fa--sizes"
                                            :title="$t('foreignerservice.tooltip')"></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="col-sm-12 col-md-7 col-lg-7 margin-bottom-16" style="text-align:left">
                            <div>
                                <h3 id="sidebar-important-to-know" class=" font-size-16 strong">{{
                                    $t('home.sidebar.important_to_know') }}</h3>
                                <p class="font-size-14">{{ $t('home.sidebar.once_you_are_connected') }}</p>
                            </div>
                        </div>
                        <div class="col-md-offset-1 col-md-4 col-sm-offset-0 col-sm-5">
                            <div class="embed-responsive embed-responsive-16by9 margin-top-15">

                                <div @click="openVideo" :style="{
                                    display: showVideo ? 'none' : 'block',
                                    'background-image': `url('${require(`@/assets/images/videos/Aanmelden-alloptions_${locale.toUpperCase()}.png`)}')`,
                                    'position': 'absolute',
                                    'width': '360px',
                                    'height': '205px',
                                    'background-size': 'cover',
                                    'opacity': '3.7',
                                    'z-index': '1',
                                    'cursor': 'pointer'
                                }"></div>
                                <iframe v-if="showVideo" :src="$t('home.sidebar.video.url')" allowfullscreen></iframe>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import bdiwImg from '@/assets/images/bdiw.svg';
import eidImg from '@/assets/images/connected-eID.svg';
import itsmeImg from '@/assets/images/itsme.svg';
import myIDImg from '@/assets/images/MyID_logo_square.svg';
import mailImg from '@/assets/images/mail.svg';
import appImg from '@/assets/images/mobile-app.svg';
import smsImg from '@/assets/images/sms.svg';
import comcertImg from '@/assets/images/certificate.svg';

export default {
    name: "SelectIdp",
    props: ['providers'],
    emits: ['selectIdp'],


    setup(props, { emit }) {
        let eidOrPartners = [];
        let secCodeAndPass = [];
        //Use Booleans for single item types
        let usernamePass = false;
        let lowprofile = false;
        let eidas = false;
        let activationCode = false;
        let passwordProv;
        let lowprofileProv;
        let eidasProv;
        let activationCodeProv;


        //Display embed youtube video
        const showVideo = ref(false);
        const openVideo = () => {

            showVideo.value = true;
        }
        //i18N
        const { t, locale } = useI18n();
        const translatedMessages = {};

        const translationKeys = [
            'home.digital_key.bmid.register.text',
            'home.digital_key.bmid.register.url',
            'home.digital_key.bmid.subtext',
            'home.digital_key.bmid.tooltip',
            'home.digital_key.bmid.icon_alt',
            'home.digital_key.myid.register.text',
            'home.digital_key.myid.register.url',
            'home.digital_key.myid.subtext',
            'home.digital_key.myid.icon_alt',
            'home.digital_key.eidservice.subtext',
            'home.digital_key.eidservice.icon_alt',
            'home.digital_key.eidservice.tooltip',
            'home.digital_key.bdiw.subtext',
            'home.digital_key.bdiw.icon_alt',
            'home.digital_key.bdiw.tooltip',
            'home.digital_key.mailotpservice.icon_alt',
            'home.digital_key.mailotpservice.subtext',
            'home.digital_key.mailotpservice.tooltip',
            'home.digital_key.totpservice.icon_alt',
            'home.digital_key.totpservice.subtext',
            'home.digital_key.totpservice.tooltip',
            'home.digital_key.otpservice.icon_alt',
            'home.digital_key.otpservice.subtext',
            'home.digital_key.otpservice.tooltip',
            'home.digital_key.commercialnoidservice.icon_alt',
            'home.digital_key.commercialnoidservice.subtext',
            'home.digital_key.commercialnoidservice.tooltip'
        ];

        for (const key of translationKeys) {
            translatedMessages[key] = t(key);
        }


        const fillProviders = () => {
            eidOrPartners = [];
            secCodeAndPass = [];
            usernamePass = false;
            lowprofile = false;
            eidas = false;
            activationCode = false;
            passwordProv;
            lowprofileProv;
            eidasProv;
            activationCodeProv;

            props.providers.forEach(el => {
                switch (el.provider) {
                    case "bdiw":
                        el.alt = translatedMessages['home.digital_key.bdiw.icon_alt'];
                        el.message = translatedMessages['home.digital_key.bdiw.subtext'];
                        el.tooltipId = "tooltip-home:digital_key.bdiw.tooltip";
                        el.tooltip = translatedMessages['home.digital_key.bdiw.tooltip'];
                        el.image = bdiwImg;
                        eidOrPartners.push(el);
                        break;
                    case "bdiw_substantial":
                        el.alt = translatedMessages['home.digital_key.bdiw.icon_alt'];
                        el.message = translatedMessages['home.digital_key.bdiw.subtext'];
                        el.tooltipId = "tooltip-home:digital_key.bdiw.tooltip";
                        el.tooltip = translatedMessages['home.digital_key.bdiw.tooltip'];
                        el.image = bdiwImg;
                        eidOrPartners.push(el);
                        break;
                    case "eid":
                        el.alt = translatedMessages['home.digital_key.eidservice.icon_alt'];
                        el.message = translatedMessages['home.digital_key.eidservice.subtext'];
                        el.tooltipId = "tooltip-home:digital_key.eidservice.tooltip";
                        el.tooltip = translatedMessages['home.digital_key.eidservice.tooltip'];
                        el.image = eidImg;
                        eidOrPartners.push(el);
                        break;
                    case "itsme":
                        el.alt = translatedMessages['home.digital_key.bmid.icon_alt'];
                        el.message = translatedMessages['home.digital_key.bmid.subtext'];
                        el.tooltipId = "tooltip-home:digital_key.bmid.tooltip";
                        el.tooltip = translatedMessages['home.digital_key.bmid.tooltip'];
                        el.image = itsmeImg;
                        el.register = true;
                        el.registerLink = translatedMessages['home.digital_key.bmid.register.url'];
                        el.registerMessage = translatedMessages['home.digital_key.bmid.register.text'];
                        eidOrPartners.push(el);
                        break;
                    case "itsmesubstantial":
                        el.alt = translatedMessages['home.digital_key.bmid.icon_alt'];
                        el.message = translatedMessages['home.digital_key.bmid.subtext'];
                        el.tooltipId = "tooltip-home:digital_key.bmid.tooltip";
                        el.tooltip = translatedMessages['home.digital_key.bmid.tooltip'];
                        el.image = itsmeImg;
                        el.register = true;
                        el.registerLink = translatedMessages['home.digital_key.bmid.register.url'];
                        el.registerMessage = translatedMessages['home.digital_key.bmid.register.text'];
                        eidOrPartners.push(el);
                        break;
                    case "myid":
                        el.alt = translatedMessages['home.digital_key.myid.icon_alt'];
                        el.message = translatedMessages['home.digital_key.myid.subtext'];
                        el.tooltipId = "tooltip-home:digital_key.myid.tooltip";
                        el.tooltip = translatedMessages['home.digital_key.myid.tooltip'];
                        el.image = myIDImg;
                        el.register = true;
                        el.registerLink = translatedMessages['home.digital_key.myid.register.url'];
                        el.registerMessage = translatedMessages['home.digital_key.myid.register.text'];
                        eidOrPartners.push(el);
                        break;
                    case "mailotp":
                        el.alt = translatedMessages['home.digital_key.mailotpservice.icon_alt'];
                        el.message = translatedMessages['home.digital_key.mailotpservice.subtext'];
                        el.tooltipId = "tooltip-home:digital_key.mailotpservice.tooltip";
                        el.tooltip = translatedMessages['home.digital_key.mailotpservice.tooltip'];
                        el.image = mailImg;
                        secCodeAndPass.push(el);
                        break;
                    case "oath":
                        el.alt = translatedMessages['home.digital_key.totpservice.icon_alt'];
                        el.message = translatedMessages['home.digital_key.totpservice.subtext'];
                        el.tooltipId = "tooltip-home:digital_key.totpservice.tooltip";
                        el.tooltip = translatedMessages['home.digital_key.totpservice.tooltip'];
                        el.image = appImg;
                        secCodeAndPass.push(el);
                        break;
                    case "sms":
                        el.alt = translatedMessages['home.digital_key.otpservice.icon_alt'];
                        el.message = translatedMessages['home.digital_key.otpservice.subtext'];
                        el.tooltipId = "tooltip-home:digital_key.otpservice.tooltip";
                        el.tooltip = translatedMessages['home.digital_key.otpservice.tooltip'];
                        el.image = smsImg;
                        secCodeAndPass.push(el);
                        break;
                    case "comcert":
                        el.alt = translatedMessages['home.digital_key.commercialnoidservice.icon_alt'];
                        el.message = translatedMessages['home.digital_key.commercialnoidservice.subtext'];
                        el.tooltipId = "tooltip-home:digital_key.commercialnoidservice.tooltip";
                        el.tooltip = translatedMessages['home.digital_key.commercialnoidservice.tooltip'];
                        el.image = comcertImg;
                        secCodeAndPass.push(el);
                        break;
                    case "password":
                        usernamePass = true;
                        passwordProv = el;
                        break;
                    case "lowprofile":
                        lowprofile = true;
                        lowprofileProv = el;
                        break;
                    case "eidas":
                        eidas = true;
                        eidasProv = el;
                        break;
                    //Password reset flow
                    case "reset_pwd_eid":
                        el.alt = translatedMessages['home.digital_key.eidservice.icon_alt'];
                        el.message = translatedMessages['home.digital_key.eidservice.subtext'];
                        el.tooltipId = "tooltip-home:digital_key.eidservice.tooltip";
                        el.tooltip = translatedMessages['home.digital_key.eidservice.tooltip'];
                        el.image = eidImg;
                        eidOrPartners.push(el);
                        break;
                    case "reset_pwd_totp":
                        el.alt = translatedMessages['home.digital_key.totpservice.icon_alt'];
                        el.message = translatedMessages['home.digital_key.totpservice.subtext'];
                        el.tooltipId = "tooltip-home:digital_key.totpservice.tooltip";
                        el.tooltip = translatedMessages['home.digital_key.totpservice.tooltip'];
                        el.image = appImg;
                        secCodeAndPass.push(el);
                        break;
                    case "reset_pwd_otp":
                        el.alt = translatedMessages['home.digital_key.otpservice.icon_alt'];
                        el.message = translatedMessages['home.digital_key.otpservice.subtext'];
                        el.tooltipId = "tooltip-home:digital_key.otpservice.tooltip";
                        el.tooltip = translatedMessages['home.digital_key.otpservice.tooltip'];
                        el.image = smsImg;
                        secCodeAndPass.push(el);
                        break;
                    case "activation_code":
                        activationCode = true;
                        activationCodeProv = el;
                        break;

                }

            });

        }

        //initiate providers when page loads
        fillProviders();

        //emit selection back to the login page
        const idpSelected = (prov) => {
            emit('selectIdp', prov);
        }

        // Watch for changes in the current locale
        watch(
            () => locale.value,
            () => {
                // Update all translated messages when the locale changes
                for (const key of translationKeys) {
                    translatedMessages[key] = t(key);
                }
                fillProviders();
            }
        );

        // On component mount, set the initial translated messages
        onMounted(() => {
            for (const key of translationKeys) {
                translatedMessages[key] = t(key);
            }
            fillProviders();
        });

        return { eidOrPartners, secCodeAndPass, usernamePass, lowprofile, eidas, activationCode ,passwordProv, lowprofileProv, eidasProv, activationCodeProv, idpSelected, showVideo, openVideo, locale };

    }

}
</script>


<style scoped>
a {
    cursor: pointer;
}

.disabled {
    opacity: 0.2; /* Make it appear grayed out */
    pointer-events: none;
 }
  
</style>
