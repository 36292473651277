export default {
  "citizentokenservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met beveiligingscode via token"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem uw persoonlijk papieren kaartje (token) bij de hand"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul de beveiligingscode <0>nummer var:tokenRequested</0> van je kaartje in"])}
      },
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beveiligingscode"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op <strong>Volgende</strong>"])}
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgelet! U kunt geen nieuw token meer activeren. Burgers met een actief token kunnen dit wel nog blijven gebruiken om zich aan te melden op onlinediensten."])}
    }
  },
  "commercialnoidservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met een commercieel certificaat"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op <strong>Aanmelden</strong>."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer ernaar gevraagd wordt, selecteert u het commercieel certificaat dat u wil gebruiken."])}
      },
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])}
    },
    "error": {
      "verification": {
        "step": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluit alle openstaande vensters van uw browser af."])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start uw browser en probeer opnieuw aan te melden."])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer of u het juiste certificaat heeft geselecteerd."])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw commercieel certificaat kon niet geverifieerd worden."])},
        "isabel": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Wenst u zich aan te melden met een Isabel certificaat, zorg er dan voor dat u over de meest recente Isabel 'middleware' beschikt. Ga hiervoor naar de <0>Isabel website</0>, kies 'Isabel-beveiligingscomponenten' en download de meest recente versie.)"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.isabel.eu/nl/forbusiness/support6/isabel6/installation/download.html"])}
        },
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft u vragen over aanmelden met een commercieel certificaat, vind snel een antwoord in de <0>hulppagina's</0>."])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["http://5244.stg.fedict.be/nl/faq/160"])}
      }
    }
  },
  "commercialservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met een commercieel certificaat"])}
  },
  "common": {
    "page": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])}
    },
    "login": {
      "username": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit zijn de gebruikersnaam en het wachtwoord uit Mijn digitale sleutels."])}
      },
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
      "choose_other_digital_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een andere digitale sleutel"])},
      "button": {
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
        "test_eid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test eID"])},
        "test_result": {
          "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID mislukt!"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID OK!"])}
        }
      }
    },
    "sidebar": {
      "no_eid": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u geen eID?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" hoe u de online overheidsdiensten kan gebruiken zonder eID."])},
        "link": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be/nl/identificatie-zonder-eid"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lees hier"])}
        }
      },
      "activate_other_key": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenst u een andere sleutel te activeren?"])},
        "link": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meld u <0>hier</0> aan om uw digitale sleutels aan te maken of te bewerken."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=nl"])}
        }
      }
    },
    "help": {
      "need_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hulp nodig?"])},
      "unable_to_find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet gevonden wat u zocht?"])},
      "read-our": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Raadpleeg onze"])},
      "quickly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en vind snel een antwoord op uw vraag."])},
      "link": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq-list"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raadpleeg onze <1>hulppagina's</1> en vind snel een antwoord op uw vraag."])},
        "help_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hulppagina's"])}
      }
    },
    "cookie": {
      "consent": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij gebruik van onze toepassingen worden cookies geplaatst in overeenstemming met ons privacybeleid."])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik aanvaard cookies"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie"])},
        "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verberg informatie"])},
        "more_info": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Voor de toepassing \"Mijn digitale sleutels\" maakt FOD Beleid en Ondersteuning – DG Digitale Transformatie gebruik van cookies om de prestaties van de website te verbeteren, functionele cookies voor de gebruiksvriendelijkheid en tijdelijke sessiecookies noodzakelijk voor de authenticatie tijdens de sessie. U kan de cookies weigeren, maar dan zullen sommige onderdelen van onze websites niet of niet optimaal werken.</p><br/><p><strong>Noodzakelijke cookies</strong></p><p>Deze cookies zijn onmisbaar om op een veilige manier uw identiteit te controleren en u op basis daarvan toegang te geven tot de toepassingen waartoe u toegang wenst te bekomen.</p><br/><p><strong>Functionele cookies</strong></p><p>De functionele cookies zijn cookies die het functioneren van de websites en de gebruiksvriendelijkheid verbeteren. Fedict gebruikt cookies om jouw taalvoorkeur te onthouden.</p><br/><p><strong>Cookies voor websiteprestaties</strong></p><p>Fedict gebruikt load balancing cookies. Ze worden gebruikt bij websites die veel worden bezocht en dienen om de lasten van de aanvragen te verdelen over meerdere, aparte netwerken en servers.</p><br/><p>Via uw browserinstellingen kan u cookies weigeren. Op de volgende website vindt u voor de meest gebruikte browsers de werkwijze om cookies te weigeren: <a href=\"http://www.aboutcookies.org/\">http://www.aboutcookies.org/</a>. U kan op elk moment de cookies verwijderen van uw computer.</p>"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["http://www.aboutcookies.org/"])}
        }
      }
    },
    "modal": {
      "warning": {
        "firefox": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID add-on voor Firefox installeren"])},
          "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u uw eID gebruiken via Firefox? Dan hebt u de officiële \"eID België\" add-on nodig. Als u deze nog niet geïnstalleerd hebt, klik dan onderaan op 'Add-on voor Firefox installeren'."])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u de add-on al geïnstalleerd? Klik dan onderaan op 'Aanmelden met eID'."])},
          "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgelet!"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Om aan te melden met uw eID, hebt u ook de eID software nodig op uw computer. Hebt u deze nog niet geïnstalleerd? Dan kan u dat doen via de "])},
          "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID software website"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.eid.base)/nl"])},
          "button": {
            "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met eID"])},
            "addon": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add-on voor Firefox installeren"])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.firefox_addons.base)/nl/firefox/addon/belgium-eid/?src=search"])}
            }
          }
        }
      }
    },
    "alt_text": {
      "images": {
        "emailOtpStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beveiligingscode via e-mail"])},
        "mobileAppStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beveiligingscode via mobiele app"])},
        "certificateStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commercieel certificaat"])},
        "certificateStepNoId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commercieel certificaat"])},
        "usernamePasswordStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gebruikersnaam en wachtwoord"])},
        "smsStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beveiligingscode via SMS"])},
        "tokenStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beveiligingscode via token"])}
      }
    },
    "greetings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met vriendelijke groet,<br/>Service Desk Digitale Transformatie"])},
    "faq": {
      "what_do_i_need_eid": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/123"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat heb ik nodig?"])}
      },
      "101": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/101"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat moet ik doen als ik een vermoeden heb dat iemand mijn digitale sleutels misbruikt?"])}
      },
      "105": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/105"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben mijn pincode vergeten"])}
      },
      "106": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/106"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik heb geen kaartlezer"])}
      },
      "107": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/107"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik heb geen eID"])}
      },
      "109": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/109"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik krijg steeds opnieuw de melding dat mijn code niet geldig is"])}
      },
      "117": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/117"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn account werd geblokkeerd. Wat moet ik doen?"])}
      },
      "118": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/118"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben mijn gebruikersnaam en/of wachtwoord vergeten. Wat moet ik doen?"])}
      },
      "119": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/119"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat zijn de mogelijkheden om via smartphone of tablet aan te melden?"])}
      },
      "142": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/142"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat heb ik nodig?"])}
      },
      "143": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/143"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik wil mijn smartphone of tablet vervangen. Wat nu met de mobiele app?"])}
      },
      "145": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/145"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welke mobiele apps kan ik gebruiken om aan te melden met \"eenmalige code via mobiele app\"?"])}
      },
      "153": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/153"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat heb ik nodig?"])}
      },
      "156": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/156"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat heb ik nodig?"])}
      },
      "158": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/158"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als ik reeds een gebruikersnaam en wachtwoord heb ingesteld voor aanmelden via token, SMS of mobiele app, is het dan nog nodig om gebruikersnaam en wachtwoord als aparte digitale sleutel te activeren?"])}
      },
      "170": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/170"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft de overheid inzage in mijn gegevens als ik gebruik maak van digitale sleutels?"])}
      },
      "172": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/172"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe veilig zijn de verschillende digitale sleutels?"])}
      },
      "174": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/174"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe meld ik me veilig af bij onlinediensten van de overheid?"])}
      },
      "7258": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/nl/eidas#7258"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat is eIDAS?"])}
      },
      "7262": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/nl/eidas#7262"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarom moet ik mij identificeren bij eIDAS?"])}
      },
      "7347": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/nl/eidas#7347"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ken mijn Belgisch identificatienummer niet."])}
      },
      "7269": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/nl/eidas#7269"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worden mijn gegevens veilig bijgehouden?"])}
      },
      "7334": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/nl/eidas#7334"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat heb ik nodig om me (voor de eerste keer) aan te melden bij eIDAS?"])}
      },
      "7338": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/nl/eidas#7338"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met welke Europese elektronische identificatiemiddelen kan ik me aanmelden?"])}
      },
      "7277": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/nl/eidas#7277"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik krijg een foutmelding dat mijn identificatie niet automatisch verwerkt kan worden."])}
      },
      "list": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq-list"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle hulpvragen"])}
      },
      "what_do_i_need_sms": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/148#6396"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat heb ik nodig?"])}
      },
      "cost_sms": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/152#6395"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kost het mij iets om een beveiligingscode via sms te ontvangen?"])}
      },
      "new_phone_sms": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/150#6753"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik heb een nieuw GSM nummer. Wat nu met de beveiligingscode via sms?"])}
      },
      "sms_help1": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ik mijn GSM gebruiken om voor een ander een beveiligingscode per SMS te ontvangen?"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/151#6394"])}
      },
      "lost_token": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/114#6831"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben mijn token kwijt. Wat moet ik doen?"])}
      },
      "commercial_certif": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een geregistreerd commercieel certificaat"])}
      },
      "156_login": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/156#6464"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat heb ik nodig om mij aan te melden?"])}
      },
      "118_username_password": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/118#6443"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam en wachtwoord vergeten?"])}
      },
      "158_app_sms_token": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/158#6465"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al een gebruikersnaam en wachtwoord ingesteld via mobiele app, sms of token?"])}
      },
      "otc": {
        "activation_code_lost": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/ik-ben-mijn-activatiecode-kwijt-wat-moet-ik-doen#7129"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben mijn activatiecode kwijt. Wat moet ik doen?"])}
        },
        "what_is_activation_code": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/ik-heb-driemaal-de-verkeerde-activatiecode-ingevuld-wat-moet-ik-doen#7169"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat is mijn activatiecode?"])}
        },
        "wrong_code_entered": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/identificatie-zonder-eid#7169"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik heb driemaal de verkeerde activatiecode ingevuld. Wat moet ik doen?"])}
        }
      },
      "why_login_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beveiligingscode-e-mail#7302"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarom aanmelden met e-mail?"])}
      },
      "new_email_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beveiligingscode-e-mail#7303"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik heb een nieuw e-mailadres. Wat nu met de beveiligingscode via e-mail?"])}
      },
      "get_started_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beveiligingscode-e-mail#7305"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik wil aan de slag! Wat heb ik nodig?"])}
      },
      "x_attempts_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beveiligingscode-e-mail#7306"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik krijg een melding dat ik nog maar X aantal pogingen heb."])}
      },
      "lapsed_code_mailotp": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beveiligingscode-e-mail#7307"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik krijg een melding dat mijn beveiligingscode vervallen is. Wat moet ik doen?"])}
      },
      "7185": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/nl/faq/hoe-kan-ik-een-gebruikersnaam-en-wachtwoord-registreren#7185"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelf een gebruikersnaam en wachtwoord registreren?"])}
      },
      "7189": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/nl/faq/ik-wil-een-gebruikersnaam-en-wachtwoord-registreren-maar-mijn-e-mailadres-reeds-gekend-wat-moet#7189"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat als mijn e-mailadres al gekend is?"])}
      },
      "7193": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/nl/faq/ik-wil-een-gebruikersnaam-en-wachtwoord-registreren-maar-mijn-gebruikersnaam-reeds-gekend-wat#7193"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat als mijn gebruikersnaam al gekend is?"])}
      },
      "wp_confirmation_help_text_1": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen activatiemail ontvangen?"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/nl/faq/ik-heb-mijn-activatielink-niet-ontvangen-wat-moet-ik-doen#7133"])}
      },
      "wp_confirmation_help_text_2": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarom gebruikersnaam en wachtwoord activeren?"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/nl/faq/waarom-moet-ik-mijn-gebruikersnaam-en-wachtwoord-activeren#7197"])}
      },
      "wp_confirmation_help_text_3": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is deze e-mail nog bruikbaar na 5 dagen?"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.help.base)/nl/faq/de-activatielink-om-mijn-gebruikersnaam-en-wachtwoord-te-activeren-5-dagen-geldig-de-e-mail-met#7181"])}
      }
    }
  },
  "eidasregistration": {
    "registration": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom!"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangezien het de eerste keer is dat u zich aanmeldt met eIDAS, vragen we u eerst om nog enkele gegevens in te vullen. Indien we u niet automatisch kunnen identificeren in onze systemen, kunnen deze gegevens ons helpen om u terug te vinden en u daarna te contacteren indien nodig."])}
    },
    "reregistration": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw proberen aanmelden"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom terug! U kon vorige keer niet aanmelden omdat u mogelijk geen of een foutief Belgisch identificatienummer hebt opgegeven. Daarom vragen we u om uw gegevens te controleren en indien nodig aan te passen. Klik op 'Ga verder' als u klaar bent."])},
      "attempts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Poging ", _interpolate(_list(0)), " van ", _interpolate(_list(1))])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga verder"])}
    },
    "form": {
      "nrn": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgisch identificatienummer (rijksregister- of bisnummer)"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het Belgisch identificatienummer is uw uniek identificatienummer bij de Belgische overheid. Bent u ingeschreven in het Belgisch rijksregister, dan is dit uw rijksregisternummer. Bent u niet ingeschreven in het rijksregister, dan is uw bisnummer uw identificatienummer. Voor meer info kunt u de hulpsectie onderaan deze pagina raadplegen."])}
      },
      "nonrn": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik heb geen Belgisch nationaal identificatienummer."])},
        "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Opgelet!</strong> Als u geen identificatienummer invult, kunnen we uw gegevens niet automatisch verwerken. De verwerking zal langer duren, waardoor u zich niet meteen kunt aanmelden."])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
      "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig uw E-mailadres"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
      "phonePrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorvoegsel telefoonnummer"])},
      "gender": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslacht"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrouw"])}
      },
      "birthcountry": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboorteland"])},
        "defaultOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een land"])}
      },
      "birthplace": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboorteplaats"])},
        "defaultOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een geboorteplaats"])}
      },
      "eula": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ga akkoord met <0>de algemene voorwaarden</0> en <1>het privacybeleid</1>."])},
        "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ga akkoord met "])},
        "generalterms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de algemene voorwaarden"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" en "])},
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["het privacybeleid"])},
        "terms": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.public.base)/eula/sma/nl/License%20agreement%20FEDICT%20IAM.pdf"])}
        },
        "privacy": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.bosa.base)/sites/default/files/content/FAS/privacyverklaring_csam_1.0_nl.pdf"])}
        }
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstuur"])}
    }
  },
  "eidasservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met een elektronische identificatiemiddel erkend op Europees niveau"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies het land waar de digitale sleutel waar u zich mee wenst aan te melden, uitgereikt werd."])},
    "countries": {
      "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oostenrijk"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["België"])},
      "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarije"])},
      "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
      "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tsjechische Republiek"])},
      "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duitsland"])},
      "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denemarken"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanje"])},
      "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estland"])},
      "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frankrijk"])},
      "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Griekenland"])},
      "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kroatië"])},
      "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongarije"])},
      "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ierland"])},
      "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italië"])},
      "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
      "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litouwen"])},
      "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groothertogdom Luxemburg"])},
      "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letland"])},
      "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
      "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederland"])},
      "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polen"])},
      "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
      "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roemenië"])},
      "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slowakije"])},
      "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenië"])},
      "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweden"])},
      "UK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verenigd Koninkrijk"])},
      "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eIDAS-Node Validator"])},
      "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noorwegen"])}
    }
  },
  "eidpasswordresetservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam en wachtwoord opnieuw instellen"])},
    "reset_password": {
      "step1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies uw sleutel"])},
        "eid": {
          "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluit uw eID-kaartlezer aan op uw computer."])},
          "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steek uw elektronische identiteitskaart (eID), vreemdelingenkaart of kids-ID in de kaartlezer."])},
          "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op <strong>'Volgende'</strong>, selecteer uw certificaat en geef de pincode van uw identiteitskaart in wanneer daarom gevraagd wordt."])}
        }
      },
      "step2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificeren"])}
      },
      "step3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw instellen"])}
      }
    }
  },
  "eidservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met eID kaartlezer"])},
    "alt-text": {
      "connected-eID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID kaartlezer"])}
    },
    "login": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluit uw eID-kaartlezer aan op uw computer"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steek uw elektronische identiteitskaart (eID), vreemdelingenkaart of kids-ID in de kaartlezer"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op <strong>'Aanmelden'</strong>, selecteer uw certificaat en geef de pincode van uw identiteitskaart in wanneer daarom gevraagd wordt"])},
      "button": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])}
      },
      "choose_other_digital_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een andere digitale sleutel"])}
    },
    "video": {
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://idp.iamfas.belgium.be/fasui/images/videos/Aanmelden-eID_kaartlezer_NL.png"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/TVNjQ7kUSvA?autoplay=1"])}
    },
    "error": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden mislukt"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent niet aangemeld. Controleer de meest voorkomende oorzaken:"])},
      "image_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarschuwingsdriehoek"])},
      "cardreader": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID correct in kaartlezer geplaatst?"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer of u uw eID correct in uw kaartlezer hebt geplaatst."])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluit al uw browservensters en ook uw browser zelf volledig af."])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer of uw kaartlezer correct is aangesloten."])},
        "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steek uw eID in de kaartlezer."])},
        "step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start uw browser en probeer opnieuw aan te melden."])}
      },
      "software": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID software zojuist geïnstalleerd?"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u zojuist de eID software (opnieuw) geïnstalleerd?"])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstart uw computer volledig."])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer opnieuw aan te melden."])},
        "nosoftware": {
          "preface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u nog geen eID software geïnstalleerd, of hebt u een oude versie?"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.eid.base)/nl"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download de eID software"])}
        }
      },
      "help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help, ik zie deze pagina steeds opnieuw!"])},
        "eid_help_pages": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://eid.belgium.be/nl/vraag-en-antwoord"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raadpleeg onze eID hulppagina."])}
        },
        "contact_form_link": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be/nl/contact"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacteer ons"])}
        }
      }
    }
  },
  "error": {
    "general": {
      "read_faq": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq-list"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als deze fout blijft voorkomen of als u vragen hebt, raadpleeg de <0>hulppagina's</0>."])}
      },
      "contact_us": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/contact"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vindt u geen antwoord op uw vraag, contacteer ons dan via het <0>contactformulier</0>."])}
      }
    },
    "service_unavailable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helaas, er zijn problemen met onze services."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn momenteel problemen met onze services. Daardoor kan u momenteel niet aanmelden.<br/>We werken eraan om dit zo snel mogelijk op te lossen. Probeer later nog eens aan te melden."])}
    },
    "request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["request-id: "])},
    "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timestamp: "])},
    "server_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["server-id: "])},
    "correlation_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correlation-id: "])},
    "error": {
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets fout gegaan"])},
      "unexpected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onverwachte fout"])}
    },
    "page_not_found": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina niet gevonden"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De pagina die u zoekt, bestaat niet."])}
    },
    "validation": {
      "fas": {
        "error": {
          "key": {
            "failed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Poging ", _interpolate(_list(0)), " is niet geslaagd. U kan nog ", _interpolate(_list(1)), " keer proberen vooraleer deze digitale sleutel geblokkeerd wordt."])},
            "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een van uw digitale sleutels werd geblokkeerd. Probeer het over een uur opnieuw."])},
            "user": {
              "not_found": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U beschikt niet over deze digitale sleutel (gebruikersnaam en wachtwoord) of de sleutel is mogelijk vervallen. Meld u aan met een andere sleutel of activeer deze digitale sleutel in "])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAM - Mijn digitale sleutels"])}
              }
            }
          },
          "mailotp": {
            "nocredential": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gebruikersnaam en wachtwoord zijn correct maar u hebt deze digitale sleutel (beveiligingscode via e-mail) niet geactiveerd. Meld u aan met een andere sleutel of activeer deze aanmeldmogelijkheid in <0>CSAM - Mijn digitale sleutels</0>."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=nl"])}
            },
            "gateway_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De E-mail dienst is tijdelijk niet beschikbaar. Probeer het later opnieuw."])}
          },
          "otp": {
            "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw beveiligingscode is vervallen. Vraag een nieuwe beveiligingscode aan en probeer het opnieuw."])},
            "missing": {
              "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul de beveiligingscode in."])}
            },
            "no_phone": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gebruikersnaam en wachtwoord zijn correct maar u hebt deze digitale sleutel (beveiligingscode via SMS) niet geactiveerd. Meld u aan met een andere sleutel of activeer deze aanmeldmogelijkheid in <0>CSAM - Mijn digitale sleutels</0>."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=nl"])}
            }
          },
          "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent een tijd inactief geweest, gelieve opnieuw aan te melden."])},
          "page": {
            "not": {
              "found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Pagina niet gevonden</strong><br>De pagina die u zoekt, bestaat niet."])}
            }
          },
          "cert": {
            "not_trusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Er ging iets fout met uw certificaat.</strong> <br/><br/>Kies een alternatieve digitale sleutel of sluit uw browser volledig af en probeer het opnieuw."])},
            "x509": {
              "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Er ging iets fout met uw certificaat.</strong> <br/><br/>Kies een andere digitale sleutel of sluit uw browser volledig en probeer het opnieuw."])}
            },
            "ocsp_crl": {
              "failed": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw certificaat kon niet gevalideerd worden. Probeer het later opnieuw. Als het probleem aanhoudt, controleer de geldigheid van uw persoonlijk en/of commercieel certificaat. <br/>Voor meer informatie over uw persoonlijk certificaat (eID), raadpleeg deze <0>hulppagina</0>.<br/>Voor meer informatie over commerciële certificaten, raadpleeg deze <1>hulppagina</1>."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq/52"])},
                "url2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/faq-list?cat=67"])}
              }
            }
          },
          "login": {
            "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden is niet gelukt."])}
          },
          "otc": {
            "activation": {
              "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het spijt ons, uw activatie werd geblokkeerd"])},
              "failed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["<strong>Foutieve activatiecode.</strong> Geef uw correcte persoonlijke activatiecode in. <strong>U hebt nog ", _interpolate(_list(0)), "</strong> pogingen. Daarna wordt de activatie voor uw veiligheid geblokkeerd."])},
              "too": {
                "many": {
                  "tries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U gaf <strong>3 keer na mekaar een foutieve activatiecode</strong> in. Daarom hebben wij voor uw veiligheid uw activatie geblokkeerd."])}
                }
              },
              "request": {
                "new": {
                  "activation_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Vraag een nieuwe activatiecode en activatielink aan</strong> via het gemeentehuis, het registratiekantoor, de ambassade of de Service Desk Digitale Transformatie."])}
                }
              }
            }
          },
          "partner": {
            "login": {
              "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Aanmelden is niet gelukt</strong><br/><br/>Er kan geen verbinding gemaakt worden met MYDIGIPASS.COM."])}
            }
          },
          "password": {
            "missing": {
              "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul een wachtwoord in."])},
              "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul een gebruikersnaam in."])}
            }
          },
          "sms": {
            "gateway": {
              "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De SMS dienst is tijdelijk niet beschikbaar. Probeer het later opnieuw."])}
            }
          },
          "token": {
            "user": {
              "not_found": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U beschikt niet over deze digitale sleutel (beveiligingscode via token) of de sleutel is mogelijk vervallen. Meld u aan met een andere sleutel of activeer deze digitale sleutel in <0>CSAM - Mijn digitale sleutels</0>."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=nl"])}
              }
            }
          },
          "totp": {
            "invalid": {
              "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden is niet gelukt."])}
            },
            "missing": {
              "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul de beveiligingscode in."])}
            },
            "no": {
              "device": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gebruikersnaam en wachtwoord zijn correct maar u hebt deze digitale sleutel (beveiligingscode via mobiele app) niet geactiveerd. Meld u aan met een andere sleutel of activeer deze digitale sleutel in <0>CSAM - Mijn digitale sleutels</O>."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=nl"])}
              }
            }
          },
          "nrn": {
            "nonrn": {
              "nrntext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hebben bij uw aanmelding op een onlinedienst van de overheid uw rijksregisternummer niet ontvangen. Vermoedelijk is uw rijksregisternummer niet gekoppeld aan uw itsme®-profiel."])},
              "moreinfo": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zie <0>https://www.itsme.be/nl</0> voor meer informatie over itsme®."])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.itsme.be/nl"])}
              },
              "errortoken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error token:"])},
              "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timestamp:"])},
              "serverid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["serverid:"])}
            }
          },
          "identity": {
            "deceased": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit profiel is niet meer actief. Contacteer de <0>Service Desk</0> voor meer informatie."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be/nl/contactformulier"])}
            }
          }
        }
      },
      "create": {
        "firstname": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een correcte naam op van minimaal 2 karakters. Gebruik alleen letters uit het alfabet, spaties en een streepje ('-')."])}
        },
        "lastname": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een correcte naam op van minimaal 2 karakters. Gebruik alleen letters uit het alfabet, spaties en een streepje ('-')."])}
        },
        "username": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul een gebruikersnaam in."])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gebruikersnaam moet tussen 6 en 64 karakters lang zijn, minimum 1 letter bevatten en mag alleen letters, nummers en/of deze karakters bevatten"])},
          "notunique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er bestaat al een profiel met deze gebruikersnaam, gelieve een andere te kiezen."])},
          "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze gebruikersnaam bestaat al. Kies een andere gebruikersnaam."])}
        },
        "password": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul een wachtwoord in."])},
          "invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Uw wachtwoord moet tussen ", _interpolate(_list(1)), " en ", _interpolate(_list(0)), " karakters lang zijn, minimum één kleine letter, één grote letter, één cijfer en één van deze karakters bevatten"])},
          "unchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U vulde uw huidige wachtwoord in. Gelieve een ander wachtwoord in te vullen."])},
          "repeat": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig uw wachtwoord."])},
            "notequal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De ingevulde wachtwoorden komen niet overeen."])}
          }
        },
        "email": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul een e-mailadres in."])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een geldig e-mailadres op."])},
          "notunique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit e-mailadres is al in gebruik. Gelieve een ander e-mailadres in te vullen."])},
          "not": {
            "unique": {
              "weak": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit e-mailadres is al in gebruik. Gelieve een ander e-mailadres in te vullen of u aan te melden met de gebruikersnaam en het wachtwoord gekoppeld aan dit e-mailadres. <0>Wachtwoord vergeten</0>?"])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.base)/public/resetpassword/chooseCredential?language=nl"])}
              },
              "normal": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit e-mailadres is al in gebruik. Gelieve een ander e-mailadres in te vullen of u aan te melden met de gebruikersnaam en het wachtwoord gekoppeld aan dit e-mailadres. <0>Wachtwoord vergeten</0>?"])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.base)/public/resetpassword/chooseCredential?language=nl"])}
              }
            }
          },
          "confirm": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig uw e-mailadres."])},
            "notequal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De ingevulde e-mailadressen komen niet overeen."])}
          }
        },
        "nrn": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul een geldig Belgisch identificatienummer in."])}
        },
        "phone": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen correct telefoonnummer. Het nummer moet 8 tot 9 cijfers bevatten voor een binnenlands nummer en 2 tot 14 voor een internationaal nummer. Gebruik alleen cijfers, en geen punten of een streepje ('-')."])}
        },
        "gender": {
          "not": {
            "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve een keuze te maken."])}
          }
        },
        "country": {
          "not": {
            "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve uw geboorteland aan te duiden."])}
          }
        },
        "city": {
          "not": {
            "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve uw geboorteplaats in te vullen."])}
          }
        },
        "consent": {
          "notchecked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U dient akkoord te gaan met de algemene voorwaarden en het privacybeleid."])}
        }
      }
    },
    "lifecycle": {
      "error": {
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U vulde uw huidige wachtwoord in. Gelieve een ander wachtwoord in te vullen."])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze gebruikersnaam bestaat al. Kies een andere gebruikersnaam."])}
      }
    },
    "fas": {
      "error": {
        "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden is niet gelukt"])},
        "partner": {
          "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw authenticatie, dewelke gebruikt werd via één van onze erkende partners, werd onderbroken. Gelieve uw browser volledig af te sluiten en het opnieuw te proberen."])},
          "error": {
            "multiple": {
              "browsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u meerdere browsers gebruikt op uw smartphone, gelieve dan enkel de standaard browser te gebruiken om aan te melden. Als u dat wil kan u de standaard browser wijzigen in de instellingen van uw smartphone."])}
            },
            "incognito": {
              "browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikt u uw browser in een incognitovenster of privémodus? Schakel dan het incognitovenster of de privémodus uit en probeer opnieuw aan te melden."])}
            },
            "contact": {
              "helpdesk": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieden de bovenstaande opties geen oplossing, neem dan contact op met de service desk Digitale Transformatie. U vindt de contactgegevens <0>hier.</0>"])},
                "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/contactformulier"])}
              }
            }
          }
        },
        "consent": {
          "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U weigerde de toestemming om de berichten van de aangesloten overheidsdiensten elektronisch te ontvangen. Zonder deze toestemming kan u geen gebruik maken van deze dienst."])}
        },
        "redirect": {
          "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien u een foutmelding krijgt bij het aanmelden, controleer dan of u de mobiele browser die je gebruikt als standaardbrowser hebt ingesteld (vermijd Samsung Internet) en of u geen privémodus gebruikt."])},
          "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als het probleem zich blijft voordoen, adviseren wij u om de website van uw applicatie te gebruiken."])}
        }
      }
    },
    "eidas": {
      "registration": {
        "pending": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw identificatie kon niet automatisch verwerkt worden."])},
          "mail": {
            "sent": {
              "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We hebben een e-mail gestuurd naar <strong>", _interpolate(_list(0)), "</strong> en nemen contact met u op wanneer uw identificatie verwerkt is. Voor meer informatie kan u terecht op onze <0>hulp pagina</0>."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/eidas"])}
            }
          }
        }
      },
      "error": {
        "page": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden mislukt"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent niet aangemeld. Controleer de meest voorkomende oorzaken:"])},
          "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden via eIDAS is niet mogelijk in volgende situaties:"])},
          "name": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met eID of vreemdelingenkaart met een verklaarde naam:"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het is niet mogelijk om via eIDAS aan te melden indien u een verklaarde naam heeft, aangezien u uw identiteit niet heeft kunnen bewijzen aan de Belgische overheid."])}
          },
          "enterprise": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden bij een privé onderneming:"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij ondersteunen enkel het aanmelden bij de online overheid."])}
          },
          "contact": {
            "helpdesk": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieden de bovenstaande opties geen antwoord op uw problemen? Neem dan contact op met de <0>service desk Digitale Transformatie</0>."])},
              "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl/contactformulier"])}
            }
          }
        },
        "nomatch": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helaas, uw registratie kon niet automatisch verwerkt worden. De persoonlijke gegevens die u opgaf, komen niet overeen met de gegevens die bij het opgegeven Belgisch identificatienummer horen."])},
          "text": {
            "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We hebben een e-mail gestuurd naar <strong>", _interpolate(_list(0)), "</strong> en nemen contact met u op wanneer uw identificatie verwerkt is. Voor meer informatie kan u terecht op onze <0>hulp pagina</0>."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.fedict.belgium.be/nl/eidas#7282"])}
          }
        },
        "maxattempts": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helaas, uw registratie kon niet automatisch verwerkt worden omdat het maximum aantal pogingen werd bereikt."])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We hebben een e-mail gestuurd naar ", _interpolate(_list(0)), " met de details van uw identificatie. Gelieve contact op te nemen met de helpdesk van de applicatie die u wil gebruiken."])}
        },
        "duplicates": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helaas, uw registratie kon niet automatisch worden verwerkt. Er bestaat al een Belgisch identificatienummer in het nationaal register met dezelfde naam en geboortedatum."])},
          "text": {
            "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We hebben een e-mail gestuurd naar <strong>", _interpolate(_list(0)), "</strong> en nemen contact met u op wanneer uw identificatie verwerkt is. Voor meer informatie kan u terecht op onze <0>hulp pagina</0>."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.fedict.belgium.be/nl/eidas#7286"])}
          }
        },
        "notfound": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helaas, uw registratie kon niet automatisch verwerkt worden. Het opgegeven Belgisch identificatienummer bestaat niet."])},
          "text": {
            "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We hebben een e-mail gestuurd naar <strong>", _interpolate(_list(0)), "</strong> en nemen contact met u op wanneer uw identificatie verwerkt is. Voor meer informatie kan u terecht op onze <0>hulp pagina</0>."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.fedict.belgium.be/nl/eidas#7278"])}
          }
        },
        "unavailable": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helaas, er zijn problemen met onze services."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn momenteel problemen met onze services waardoor we geen opzoekingen kunnen doen in het nationaal register. Daardoor kan u momenteel niet aanmelden.<br/>We werken eraan om dit zo snel mogelijk op te lossen. Probeer later nog eens aan te melden."])}
        },
        "invalididentifier": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helaas, u kan niet aanmelden omdat uw Belgisch identificatienummer niet meer geldig is."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve contact op te nemen met de helpdesk van de toepassing die u wil gebruiken."])},
          "bosahelpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve contact op te nemen met de helpdesk van BOSA."])}
        },
        "nobiscreation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw identificatie kon niet automatisch verwerkt worden."])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We hebben een e-mail gestuurd naar <strong>", _interpolate(_list(0)), "</strong> met de details van uw identificatie. Gelieve contact op te nemen met de helpdesk van de applicatie die u wil gebruiken."])}
        }
      }
    }
  },
  "footer": {
    "nav": {
      "copy_right": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.csam.base)/nl/index.html"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAM"])}
      },
      "terms_of_use": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.public.base)/eula/sma/nl/License%20agreement%20FEDICT%20IAM.pdf"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiksvoorwaarden"])}
      },
      "privacy": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.bosa.base)/sites/default/files/content/FAS/privacyverklaring_csam_1.0_nl.pdf"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])}
      },
      "accessibility": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegankelijkheid"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/accessibility/fasui/nl/Accessibility%20statement%20NL%20FASUI.pdf"])}
      },
      "powered_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by FOD BOSA - DG Digitale Transformatie"])}
    }
  },
  "foreignerservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw digitale sleutel activeren"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef uw persoonlijke activatiecode in."])},
    "activation_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activatiecode"])},
    "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activatiecode ingeven"])},
    "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies uw digitale sleutel"])},
    "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeer uw digitale sleutel"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze code bestaat uit 20 tekens en hebt u persoonlijk ontvangen."])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw"])}
  },
  "header": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden bij de online overheid"])},
    "eidas": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden als EU-burger"])}
    },
    "sma": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn digitale sleutels"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden bij de online overheid"])}
    },
    "weakprofile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratie gebruikersnaam en wachtwoord"])}
    }
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies uw digitale sleutel om aan te melden"])},
    "need_help": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hulp nodig?"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%FAS2WEB_URL%/nl"])}
    },
    "group_header": {
      "eid_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>eID</strong> of <strong>digitale sleutels van erkende partners</strong>"])},
      "twofactor_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitale sleutels met <strong>beveiligingscode</strong> en <strong>gebruikersnaam + wachtwoord</strong>"])},
      "id_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitale sleutel met <strong>gebruikersnaam + wachtwoord</strong>"])},
      "european_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europese authenticatie"])},
      "external_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitale sleutel(s) via <strong>externe partners</strong>"])}
    },
    "digital_key": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
      "eidservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met eID kaartlezer"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat hebt u nodig? Uw eID (identiteitskaart, vreemdelingenkaart of kids-ID), uw pincode, een kaartlezer en de eID-software op uw computer."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eID kaartlezer"])}
      },
      "bmid": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via itsme"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om deze aanmeldmogelijkheid te gebruiken, dient u al geregistreerd te zijn bij itsme."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["itsme"])},
        "register": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.itsme.be/get-started"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je itsme-account aanmaken"])}
        }
      },
      "myid": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via myID.be"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om deze aanmeldmogelijkheid te gebruiken, dient u al geregistreerd te zijn bij myID.be."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myID.be"])},
        "register": {
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://myID.be/ActivateLogin?ui_locales=nl"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je myID.be account aanmaken"])}
        }
      },
      "bdiw": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden via MyGov.be"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om deze aanmeldmogelijkheid te gebruiken, dient u al geregistreerd te zijn bij MyGov.be."])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden via MyGov.be"])}
      },
      "vasco": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via mydigipass"])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mydigipass"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om deze aanmeldmogelijkheid te gebruiken, dient u al geregistreerd te zijn bij mydigipass."])}
      },
      "helena": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met Helena"])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helena"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helena is een digitale sleutel waarmee u als patiënt kan aanmelden op de eHealth toepassingen."])}
      },
      "otpservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met beveiligingscode via SMS"])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beveiligingscode via SMS"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul eerst uw gebruikersnaam en wachtwoord uit CSAM - Mijn digitale sleutels in en klik op Volgende om de sms te ontvangen."])}
      },
      "totpservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met beveiligingscode via mobiele app"])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beveiligingscode via mobiele app"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul eerst uw gebruikersnaam en wachtwoord uit CSAM - Mijn digitale sleutels in en klik op Volgende om vervolgens de eenmalige code in te geven."])}
      },
      "mailotpservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met beveiligingscode via e-mail"])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beveiligingscode via e-mail"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul eerst uw gebruikersnaam en wachtwoord uit CSAM - Mijn digitale sleutels in en klik op Volgende om vervolgens de eenmalige code in te geven."])}
      },
      "commercialservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met een commercieel certificaat"])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commercieel certificaat"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een commercieel certificaat is een geldige manier om u aan te melden en te identificeren voor een online overheidstoepassing. Een commercieel certificaat is bestemd voor persoonlijk gebruik en moet zijn opgeladen in uw browser."])}
      },
      "commercialnoidservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met een commercieel certificaat"])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commercieel certificaat"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een commercieel certificaat is een geldige manier om u aan te melden en te identificeren voor een online overheidstoepassing. Een commercieel certificaat is bestemd voor persoonlijk gebruik en moet zijn opgeladen in uw browser."])}
      },
      "commercial": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met een commercieel certificaat"])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commercieel certificaat"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een commercieel certificaat is een geldige manier om u aan te melden en te identificeren voor een online overheidstoepassing. Een commercieel certificaat is bestemd voor persoonlijk gebruik en moet zijn opgeladen in uw browser."])}
      },
      "comcertnoid": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met een commercieel certificaat"])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commercieel certificaat"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een commercieel certificaat is een geldige manier om u aan te melden en te identificeren voor een online overheidstoepassing. Een commercieel certificaat is bestemd voor persoonlijk gebruik en moet zijn opgeladen in uw browser."])}
      },
      "citizentokenservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met beveiligingscode via token"])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beveiligingscode via token"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om u aan te melden met uw token, vul eerst uw gebruikersnaam en wachtwoord in uit CSAM - Mijn digitale sleutels."])}
      },
      "passwordservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met gebruikersnaam en wachtwoord"])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gebruikersnaam en wachtwoord"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit zijn de gebruikersnaam en het wachtwoord uit Mijn digitale sleutels."])}
      },
      "lowprofileservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met gebruikersnaam en wachtwoord"])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gebruikersnaam en wachtwoord"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit zijn de gebruikersnaam en het wachtwoord uit Mijn digitale sleutels."])}
      },
      "storkservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met Cross Border Authentication"])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Border Authentication"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met \"Cross Border Authentication\" meld u uzelf als buitenlander aan vanuit andere Europese landen of vanuit België, voor Belgische overheidstoepassingen die deze aanmeldmogelijkheid ondersteunen."])}
      },
      "eidasservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met een elektronische identificatiemiddel erkend op Europees niveau"])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronische identificatiemiddel erkend op Europees niveau"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer u een nationaal elektronisch identificatiemiddel hebt dat door Europa is erkend, kan u dankzij de eIDAS verordening aanmelden."])}
      },
      "partnerservice": {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met een draadloze eID kaartlezer"])},
        "icon_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["draadloze eID kaartlezer"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om deze aanmeldmogelijkheid te gebruiken, dient u al geregistreerd te zijn bij MYDIGIPASS én dient u over een eID en een draadloze kaartlezer te beschikken."])}
      }
    },
    "sidebar": {
      "custom": {
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U wenst toegang tot: "])}
      },
      "important_to_know": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belangrijk om te weten!"])},
      "once_you_are_connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zodra u aangemeld bent met een digitale sleutel, hebt u via CSAM automatisch toegang tot andere onlinediensten van de overheid die met dezelfde sleutel beveiligd zijn. Dit geldt zolang uw browservenster actief is."])},
      "video": {
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://idp.iamfas.belgium.be/fasui/images/videos/Aanmelden-alloptions_NL.png"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/7aeuaSwWAhE?autoplay=1"])}
      }
    }
  },
  "info": {
    "application": {
      "version": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versie"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicatieversie"])}
      },
      "build": {
        "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build tag"])},
        "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build timestamp"])}
      }
    }
  },
  "lowprofileservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met gebruikersnaam en wachtwoord"])},
    "login": {
      "step": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul uw"])},
        "strong_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gebruikersnaam"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
        "strong_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wachtwoord"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul uw <strong>gebruikersnaam</strong> en <strong>wachtwoord</strong> in"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op <strong>Volgende</strong>"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])}
      },
      "reset_password": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam en/of wachtwoord vergeten?"])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u de digitale sleutel \"Gebruikersnaam en wachtwoord\" nog niet geactiveerd?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om aan te melden met beveiligingscode via gebruikersnaam en wachtwoord, moet u deze digitale sleutel eerst activeren. Klik op 'Aanmelden met eID-kaartlezer' en volg de stappen."])}
    }
  },
  "mailotpservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met beveiligingscode via e-mail"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul de beveiligingscode in die u ontvangen heeft via e-mail:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op <strong>Volgende</strong>"])}
      },
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul uw eenmalig wachtwoord in"])},
      "warning": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op!"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Het kan enkele momenten duren vooraleer u de e-mail ontvangt. Controleer ook uw spam folder. De code blijft maar 10 minuten geldig. Vul hem dus tijdig in."])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u de digitale sleutel \"Beveiligingscode via e-mail\" nog niet geactiveerd?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om aan te melden met beveiligingscode via e-mail, moet u deze digitale sleutel eerst activeren. Klik op \"Aanmelden met eID-kaartlezer\" en volg de stappen."])}
    },
    "footer": {
      "video": {
        "1": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation-Security_code_mail_NL.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/JrGnjn-DRM0?autoplay=1"])}
        },
        "2": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login-security_code_mail_NL.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/XyywKCau_6g?autoplay=1"])}
        }
      }
    }
  },
  "otpservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met beveiligingscode via SMS"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul de beveiligingscode in die u ontvangen heeft via SMS:"])}
      },
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul uw eenmalig wachtwoord in"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op <strong>Volgende</strong>"])},
      "warning": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op!"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" De code blijft maar 10 minuten geldig. Vul hem dus tijdig in."])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u de digitale sleutel \"Beveiligingscode via sms\" nog niet geactiveerd?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om aan te melden met beveiligingscode via sms, moet u deze digitale sleutel eerst activeren. Klik op 'Aanmelden met eID-kaartlezer' en volg de stappen."])}
    },
    "footer": {
      "video": {
        "1": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden-aanmelden_met_sms_NL.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/nav7-y6LHqU?autoplay=1"])}
        },
        "2": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeren-aanmelden_met_sms_NL.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/KZN51aydqfg?autoplay=1"])}
        }
      }
    }
  },
  "passwordservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met gebruikersnaam en wachtwoord"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul uw <strong>gebruikersnaam</strong> en <strong>wachtwoord</strong> in"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op <strong>Volgende</strong>"])}
      },
      "reset_password": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam en/of wachtwoord vergeten?"])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u de digitale sleutel \"Gebruikersnaam en wachtwoord\" nog niet geactiveerd?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om aan te melden met beveiligingscode via gebruikersnaam en wachtwoord, moet u deze digitale sleutel eerst activeren. Klik op 'Aanmelden met eID-kaartlezer' en volg de stappen."])}
    }
  },
  "postauthentication": {
    "setcapacity": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies hoe je je wilt aanmelden:"])},
      "button": {
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])}
      },
      "options": {
        "citizen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in eigen naam"])},
        "enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in naam van een onderneming"])}
      },
      "explanation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarom deze keuze?"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kan toegang krijgen tot de online overheid als lid van 1 van deze doelgroepen:"])},
        "text2": {
          "part1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in eigen naam (burger)"])},
          "part2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in naam van een onderneming (verantwoordelijke in een onderneming)"])}
        },
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elke doelgroep biedt u als gebruiker andere mogelijkheden en rechten."])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op!"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het is echter niet mogelijk om in dezelfde internetbrowser tegelijk aan te melden voor twee verschillende doelgroepen. In het geval u van doelgroep zou wisselen, zal u gevraagd worden op alle toepassingen waar u bent aangemeld in uw huidige doelgroep te sluiten. Indien u dit niet doet, kan u geen toegang krijgen tot een toepassing in een andere doelgroep."])}
      }
    },
    "emailverification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve uw e-mailadres te bevestigen"])},
      "link": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom! Om uw veiligheid te garanderen zijn digitale sleutels beperkt in de tijd geldig. Enkele maanden voor deze sleutels komen te vervallen, wordt u hiervan op de hoogte gebracht via e-mail. Om u tijdig en correct te kunnen informeren, vragen we u om uw e-mailadres in te vullen. Meer info vindt u <0>hier</0>."])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://sma-help.bosa.belgium.be/nl/andere#7294"])}
      },
      "confirm": {
        "form": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve uw e-mailadres te bevestigen."])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
          "email_repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres bevestigen"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versturen"])}
        }
      },
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt uw e-mailadres met succes ingevuld!"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op 'Volgende' om door te gaan met aanmelden."])}
      }
    },
    "setlifecycle": {
      "link": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["someUrl"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["localhost:3000/"])}
      },
      "usernamepassword": {
        "warning": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gebruikersnaam en wachtwoord wijzigen"])},
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" Welkom! We hebben gemerkt dat uw <strong>gebruikersnaam en wachtwoord</strong> binnenkort gaat vervallen, op <strong><0>", _interpolate(_named("expiryDate")), "</0></strong>. Om te voorkomen dat u zich niet meer kan aanmelden via gebruikersnaam en wachtwoord of digitale sleutels die daar gebruik van maken, vragen we u om het onderstaande formulier in te vullen en te versturen. Als u nu geen tijd heeft, kan u dit ook op een later moment doen. Klik dan op 'Nee bedankt, ik doe dit later' onderaan dit formulier."])}
        },
        "renewal": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve uw gebruikersnaam en/of wachtwoord te wijzigen."])}
        },
        "expiring": {
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw gebruikersnaam en wachtwoord zal vervallen op <strong><0>", _interpolate(_named("expiryDate")), "</0></strong> en moet daarom gewijzigd worden."])}
        }
      },
      "token": {
        "warning": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw token vernieuwen"])},
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welkom! We hebben gemerkt dat uw <strong>beveiligingscode via token</strong> binnenkort gaat vervallen, op <strong><0>", _interpolate(_named("expiryDate")), "</0></strong>. Om te voorkomen dat u zich niet meer kan aanmelden met deze digitale sleutel, vragen we u om het onderstaande formulier in te vullen en te versturen. Wij zorgen er dan voor dat uw beveiligingscode via token tijdig vernieuwd wordt. Als u nu geen tijd heeft, kan u dit ook op een later moment doen. Klik dan op \"Nee bedankt, ik doe dit later\" onderaan het formulier."])}
        },
        "renewal": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer u dit formulier invult, maken we voor u automatisch een nieuwe beveiligingscode via token aan die we naar uw e-mailadres zullen sturen."])}
        },
        "expiring": {
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw beveiligingscode via token zal vervallen op <strong><0>", _interpolate(_named("expiryDate")), "</0></strong> en moet daarom vernieuwd worden."])}
        }
      },
      "inputfields": {
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam "])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord "])},
        "repeatpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord bevestigen "])},
        "email": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer en bevestig uw e-mailadres. Als u een ander e-mailadres wenst te gebruiken, kan u dat hier ook wijzigen."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres "])},
          "confirmlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres bevestigen "])}
        }
      },
      "tooltip": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee bedankt, ik doe dit later"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versturen"])}
    },
    "validation": {
      "username": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul een gebruikersnaam in."])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gebruikersnaam moet tussen 6 en 64 karakters lang zijn, minimum 1 letter bevatten en mag alleen letters, nummers en/of deze karakters bevatten: '-_.'"])},
        "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze gebruikersnaam bestaat al. Kies een andere gebruikersnaam."])}
      },
      "password": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul een wachtwoord in."])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wachtwoord moet tussen 8 en 100 karakters lang zijn, minimum 1 letter bevatten en een cijfer of één van deze karakters: !, $, #, %, -, *, _ '."])},
        "notequal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De ingevulde wachtwoorden komen niet overeen."])},
        "unchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U vulde uw huidige wachtwoord in. Gelieve een ander wachtwoord in te vullen."])}
      },
      "repeatpassword": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig uw wachtwoord."])}
      },
      "email": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul een e-mailadres in."])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een geldig e-mailadres op."])},
        "notunique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit e-mailadres is al in gebruik. Gelieve een ander e-mailadres in te vullen."])}
      },
      "confirmemail": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig uw e-mailadres."])},
        "notequal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De ingevulde e-mailadressen komen niet overeen."])}
      }
    }
  },
  "pwdresetotp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam en wachtwoord opnieuw instellen"])},
    "reset_password": {
      "step1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies uw sleutel"])}
      },
      "step2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificeren"])},
        "otp": {
          "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul de unieke code in die u zonet via sms hebt ontvangen:"])},
          "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op 'Volgende'"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unieke sms-code"])}
        }
      },
      "step3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw instellen"])}
      }
    }
  },
  "remote-consent": {
    "scopes": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["overheidsdocumenten"])},
      "egovnrn": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rijksregisternummer"])},
        "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["egovNRN"])}
      },
      "profile": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profiel"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["achternaam"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voornaam"])},
        "fedId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fedID"])},
        "preferredLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["taalvoorkeur"])},
        "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail adres"])}
      },
      "details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[NL]Details about your public-service documents"])},
        "metaData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[NL]Meta-data"])},
        "documentsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[NL]Title of your document<br/>Document type<br/>Details of the sender<br/>Period of validity for the document<br/>"])},
        "content": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhoud"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[NL]The service provider has no knowledge of the content of your documents. The content of the document is shown only if you are logged in and are sitting in front of your computer."])}
        }
      }
    },
    "button": {
      "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akkoord"])},
      "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weigeren"])}
    }
  },
  "totppasswordresetservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam en wachtwoord opnieuw instellen"])},
    "reset_password": {
      "step1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies uw sleutel"])}
      },
      "step2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificeren"])},
        "chooseApp": {
          "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de mobiele app die u wenst te gebruiken:"])},
          "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op '<strong>Volgende'</strong>"])}
        },
        "totp": {
          "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open de mobiele app die u geïnstalleerd hebt. Er wordt automatische een eenmalige beveiligingscode gegenereerd."])},
          "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Vul uw eenmalige beveiligingscode in.</strong>"])},
          "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op <strong>'Volgende'</strong>."])}
        }
      },
      "step3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw instellen"])}
      }
    }
  },
  "totpservice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met beveiligingscode via mobiele app"])},
    "login": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open de mobiele app die u geïnstalleerd hebt. Er wordt automatisch een beveiligingscode gegenereerd."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef de eenmalige beveiligingscode in:"])}
      },
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op <strong>Volgende</strong>"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul uw eenmalige beveiligingscode in"])}
    },
    "choice": {
      "step": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de mobile applicatie die u wenst te gebruiken:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op <strong>Volgende</strong>"])}
      }
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u de digitale sleutel \"Beveiligingscode via mobiele app\" nog niet geactiveerd?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om aan te melden met beveiligingscode via mobiele app, moet u deze digitale sleutel eerst activeren. Klik op \"Aanmelden met eID-kaartlezer\" en volg de stappen."])}
    },
    "footer": {
      "video": {
        "1": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden-aanmelden_met_app_NL.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/nmzj7I27jTk?autoplay=1"])}
        },
        "2": {
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeren-aanmelden_met_app_NL.png"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/kBwOkvwOVxw?autoplay=1"])}
        }
      }
    }
  },
  "weakprofile": {
    "form": {
      "emailAddress": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])}
      },
      "firstname": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])}
      },
      "lastname": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])}
      },
      "username": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam"])},
        "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gebruikersnaam moet tussen 6 en 64 karakters lang zijn, minimum 1 letter bevatten en mag alleen letters, nummers en/of deze karakters bevatten"])}
      },
      "password": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
        "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Uw wachtwoord moet tussen ", _interpolate(_list(1)), " en ", _interpolate(_list(0)), " karakters lang zijn, minimum één kleine letter, één grote letter, één cijfer en één van deze karakters bevatten"])}
      }
    },
    "email": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De activatielink wordt naar dit e-mailadres verstuurd."])}
    },
    "registration": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])},
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gebruikersnaam en wachtwoord zijn succesvol geregistreerd!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hebben een e-mail verstuurd naar <strong>var:email</strong> met een activatielink. Zodra u op deze link klikt worden uw gebruikersnaam en wachtwoord geactiveerd."])}
      }
    },
    "info": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al een gebruikersnaam en wachtwoord?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u zich in het verleden al eens aangemeld met een andere digitale sleutel zoals met beveiligingscode via mobiele app, sms of token? Dan kan u zich met dezelfde gebruikersnaam en hetzelfde wachtwoord aanmelden. Ga naar <0>CSAM-Mijn digitale sleutels</0> om dit te controleren."])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$t(common:url.sma.self_registration)?language=nl"])}
    }
  },
  "logout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent succesvol uitgelogd."])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij adviseren om uw internet browser af te sluiten."])}
  }
}