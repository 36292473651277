<template>
    <div id="footer">
        <div class="container-fluid no-gutter csam-footer-container">
            <div class="container csam-footer-inner-container">
                <div class="col-sm-7 links">
                    <ul class="unstyled-list list-inline csam-footer-links">
                        <li>© 2023 CSAM</li>
                        <li><a target="_blank" rel="noopener noreferrer"
                                :href="`https://www.csam.be/${locale}/index.html`">{{
                                    $t('footer.nav.copy_right.text') }}</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" :href="eulaLink">{{
                            $t('footer.nav.terms_of_use.text') }}</a>
                        </li>
                        <li><a target="_blank" rel="noopener noreferrer" :href="privacyLink">{{
                            $t('footer.nav.privacy.text') }}</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" :href="accessibilityLink">{{
                            $t('footer.nav.accessibility.text') }}</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" :href="cookiesLink">Cookies</a>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-5">
                    <ul class="unstyled-list list-inline csam-footer-links pull-right">
                        <li>{{ $t('footer.nav.powered_by') }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { useI18n } from 'vue-i18n';
import { ref, watch } from 'vue';
export default {

    setup() {
        const { locale } = useI18n();
        const eulaLink = ref('');
        const privacyLink = ref('');
        const accessibilityLink = ref('');
        const cookiesLink = ref('');

        const setLinks = () => {
            switch (locale.value) {
                case 'nl':
                    eulaLink.value = 'https://sma-help.bosa.belgium.be/nl/fas-gebruiksvoorwaarden';
                    privacyLink.value = 'https://sma-help.bosa.belgium.be/nl/fas-privacyverklaring';
                    accessibilityLink.value = 'https://sma-help.bosa.belgium.be/nl/fas-toegankelijkheidsverklaring';
                    cookiesLink.value = 'https://sma-help.bosa.belgium.be/nl/fas-cookiebeleid';
                    break;
                case 'fr':
                    eulaLink.value = 'https://sma-help.bosa.belgium.be/fr/fas-conditions-utilisations';
                    privacyLink.value = 'https://sma-help.bosa.belgium.be/fr/fas-confidentialite';
                    accessibilityLink.value = 'https://sma-help.bosa.belgium.be/fr/fas-accessibilite';
                    cookiesLink.value = 'https://sma-help.bosa.belgium.be/fr/fas-politique-cookies';
                    break;
                case 'de':
                    eulaLink.value = 'https://sma-help.bosa.belgium.be/de/fas-nutzungsbedingungen';
                    privacyLink.value = 'https://sma-help.bosa.belgium.be/de/fas-datenschutzerklaerung';
                    accessibilityLink.value = 'https://sma-help.bosa.belgium.be/de/fas-barrierefreiheit';
                    cookiesLink.value = 'https://sma-help.bosa.belgium.be/de/fas-cookie-richtlinie';
                    break;
                case 'en':
                    eulaLink.value = 'https://sma-help.bosa.belgium.be/en/fas-terms-use';
                    privacyLink.value = 'https://sma-help.bosa.belgium.be/en/fas-privacy';
                    accessibilityLink.value = 'https://sma-help.bosa.belgium.be/en/fas-accessibility';
                    cookiesLink.value = 'https://sma-help.bosa.belgium.be/en/fas-cookies';
                    break;

            }
        };

        setLinks();

        watch(
            () => locale.value,
            () => setLinks()
        );

        return { locale, eulaLink, privacyLink, accessibilityLink, cookiesLink };
    }

};
</script>

  
<style></style>