<template>
  <div class="container-fluid no-gutter csam-footer-content-container csam-footer-content-container--border">
    <div class="container csam-footer-content-inner-container">
      <div class="col-md-5 col-lg-6 col-sm-12">
        <h4 class="footer-banner-h4">{{ $t('common.help.need_help') }}</h4>
        <ul class="link-list list">
          <li><a :href="`https://sma-help.bosa.belgium.be/${locale}/faq/172`">{{ $t('common.faq.172.text') }}</a>
          </li>
          <li><a :href="`https://sma-help.bosa.belgium.be/${locale}/faq/119`">{{ $t('common.faq.119.text') }}</a></li>
          <li><a :href="`https://sma-help.bosa.belgium.be/${locale}/faq/101`">{{ $t('common.faq.101.text') }}</a></li>
          <li><a :href="`https://sma-help.bosa.belgium.be/${locale}/faq/170`">{{ $t('common.faq.170.text') }}</a></li>
          <li><a :href="`https://sma-help.bosa.belgium.be/${locale}/faq/174`">{{ $t('common.faq.174.text') }}</a></li>
          <li><a :href="`https://sma-help.bosa.belgium.be/${locale}/faq-list`">{{ $t('common.faq.list.text') }}</a></li>
        </ul>
      </div>
      <div class="col-md-7 col-lg-6 col-sm-12">
        <h5 class="footer-banner-h5"><span>{{ $t('common.help.unable_to_find') }} </span><span> {{
          $t('common.help.read-our') }} <a :href="`https://sma-help.bosa.belgium.be/${locale}/faq-list`">{{
    $t('common.help.link.help_page') }}</a> {{ $t('common.help.quickly') }}</span></h5>
      </div>
    </div>
  </div>
</template>
<script>

import { useI18n } from 'vue-i18n';
export default {

  setup() {
    const { locale } = useI18n();

    return { locale };

  }

}
</script>
<style>
.list {
  list-style-type: disc;
  /* Use disc type bullet points */
  padding-left: 20px;
  /* Add padding to the left to create space for the bullet points */
}

.list li {
  text-align: left;
  /* Align the text inside each list item to the left */
}

.footer-banner-h4 {
  padding-left: 20px;
  text-align: left;
}</style>