import { createRouter, createWebHistory } from 'vue-router';
import LoginBosa from '@/views/LoginBosa';

const routes = [
  {
    path: '/',
    name: 'XUI',
    component: LoginBosa
  },
  {
    path: '/:type/refused',
    name: 'Refused',
    component:  () => import('@/views/RefusedPage')
  },
  {
    path: '/login/failed/eidservice',
    name: 'partnerRefused',
    component:  () => import('@/views/EidFailed')
  },
  {
    path: '/logout',
    name: 'loggedOut',
    component:  () => import('@/views/LoggedOut')
  },
  //catch all 404
  {
    path: '/:catchAll(.*)', 
    name : 'NotFound',
    component:  () => import('@/views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
